import React, { useContext } from "react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import '../assets/CSS/Url.css'
import MyContext from "../context/MyContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";


const UrlNav=()=>{
    const {randomId, subdomain,setSubdomain,custmondomian,
      subdomain1,setSubdomain1,newdomain,memecustmonapi,
      domainupdate, setDomianupdate}=useContext(MyContext)
      const [anchorEl, setAnchorEl] = React.useState(null);

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

      const handleCopyClick = () => {
        const textToCopy = custmondomian ? custmondomian : `${subdomain}${randomId}${subdomain1}`;
        
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                // Alert user of successful copy
                setTimeout(() => {
                    setAnchorEl(null);
                }, 500);
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };
    
    
    return(
        <>
         <div class='toolbar_container__7VQRL '>
         {/* <img src="https://files.umso.co/lib_xEaDdJFAXtpgtMsP/u36o6qd6r3o9bnmq.png" height="20" width="20" alt="favicon"></img> */}
         <div class="toolbar_addressBar__DbP9z"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_55_86)"><path d="M9.5 5.5H2.5C1.94772 5.5 1.5 5.94772 1.5 6.5V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V6.5C10.5 5.94772 10.0523 5.5 9.5 5.5Z" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.5 5.5V3.5C3.5 2.83696 3.76339 2.20107 4.23223 1.73223C4.70107 1.26339 5.33696 1 6 1C6.66304 1 7.29893 1.26339 7.76777 1.73223C8.23661 2.20107 8.5 2.83696 8.5 3.5V5.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_55_86"><rect width="12" height="12" fill="white"></rect></clipPath></defs></svg>
        {domainupdate==0?<span class="toolbar_domain__Ie5uZ">{memecustmonapi == true ? custmondomian : `${subdomain}${randomId}${subdomain1}`}</span>: <span class="toolbar_domain__Ie5uZ">{`${subdomain}${randomId}${subdomain1}`}</span>}
         
         </div>
            <button class="size_wrapper__XQ9F-" type="button" id="radix-:ri:" aria-haspopup="menu" aria-expanded="false" data-state="closed" onClick={handleCopyClick}>
            <Button aria-describedby={id} variant="" onClick={handleClick}>
            <FontAwesomeIcon icon={faCopy}  />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 1 }}>Copied</Typography>
      </Popover>
              </button> 
           
         </div>
          
        </>
    )
};

export default UrlNav;