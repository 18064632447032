export const HeaderNavFontFamily = [
    { name: 'Aharoni', value: 'Aharoni, sans-serif' },
    { name: 'Akronim', value: 'Akronim, cursive' },
    { name: 'Amatic SC', value: 'Amatic SC, cursive' },
    { name: 'Anton', value: 'Anton, sans-serif' },
    { name: 'Architects Daughter', value: 'Architects Daughter, cursive' },
    { name: 'Arimo', value: 'Arimo, sans-serif' },
    { name: 'Averia Sans Libre', value: 'Averia Sans Libre, sans-serif' },
    { name: 'Bad Script', value: 'Bad Script, cursive' },
    { name: 'Bangers', value: 'Bangers, cursive' },
    { name: 'Barlow', value: 'Barlow, sans-serif' },
    { name: 'Bellefair', value: 'Bellefair, serif' },
    { name: 'Berkshire Swash', value: 'Berkshire Swash, cursive' },
    { name: 'Bungee', value: 'Bungee, cursive' },
    { name: 'Cabin', value: 'Cabin, sans-serif' },
    { name: 'Caveat', value: 'Caveat, cursive' },
    { name: 'Coda', value: 'Coda, cursive' },
    { name: 'Comfortaa', value: 'Comfortaa, cursive' },
    { name: 'Cormorant', value: 'Cormorant, serif' },
    { name: 'Dancing Script', value: 'Dancing Script, cursive' },
    { name: 'Dosis', value: 'Dosis, sans-serif' },
    { name: 'Economica', value: 'Economica, sans-serif' },
    { name: 'Exo', value: 'Exo, sans-serif' },
    { name: 'Fjalla One', value: 'Fjalla One, sans-serif' },
    { name: 'Francois One', value: 'Francois One, sans-serif' },
    { name: 'Fredoka One', value: 'Fredoka One, cursive' },
    { name: 'Fugaz One', value: 'Fugaz One, sans-serif' },
    { name: 'Gloria Hallelujah', value: 'Gloria Hallelujah, cursive' },
    { name: 'Grand Hotel', value: 'Grand Hotel, cursive' },
    { name: 'Gugi', value: 'Gugi, cursive' },
    { name: 'Handlee', value: 'Handlee, cursive' },
    { name: 'Homemade Apple', value: 'Homemade Apple, cursive' },
    { name: 'Inconsolata', value: 'Inconsolata, monospace' },
    { name: 'Indie Flower', value: 'Indie Flower, cursive' },
    { name: 'Josefin Sans', value: 'Josefin Sans, sans-serif' },
    { name: 'Jura', value: 'Jura, sans-serif' },
    { name: 'Kanit', value: 'Kanit, sans-serif' },
    { name: 'Krona One', value: 'Krona One, sans-serif' },
    { name: 'Lato', value: 'Lato, sans-serif' },
    { name: 'Lobster', value: 'Lobster, cursive' },
    { name: 'Lora', value: 'Lora, serif' },
    { name: 'Maven Pro', value: 'Maven Pro, sans-serif' },
    { name: 'Merriweather', value: 'Merriweather, serif' },
    { name: 'Montserrat', value: 'Montserrat, sans-serif' },
    { name: 'Mukta', value: 'Mukta, sans-serif' },
    { name: 'Nunito', value: 'Nunito, sans-serif' },
    { name: 'Oldenburg', value: 'Oldenburg, cursive' },
    { name: 'Open Sans', value: 'Open Sans, sans-serif' },
    { name: 'Oswald', value: 'Oswald, sans-serif' },
    { name: 'Pacifico', value: 'Pacifico, cursive' },
    { name: 'Poppins', value: 'Poppins, sans-serif' },
    { name: 'Quicksand', value: 'Quicksand, sans-serif' },
    { name: 'Raleway', value: 'Raleway, sans-serif' },
    { name: 'Roboto', value: 'Roboto, sans-serif' },
    { name: 'Russo One', value: 'Russo One, sans-serif' },
    { name: 'Sacramento', value: 'Sacramento, cursive' },
    { name: 'Shadows Into Light', value: 'Shadows Into Light, cursive' },
    { name: 'Shrikhand', value: 'Shrikhand, cursive' },
    { name: 'Source Sans Pro', value: 'Source Sans Pro, sans-serif' },
    { name: 'Space Mono', value: 'Space Mono, monospace' },
    { name: 'Special Elite', value: 'Special Elite, cursive' },
    { name: 'Spectral', value: 'Spectral, serif' },
    { name: 'Staatliches', value: 'Staatliches, cursive' },
    { name: 'Titillium Web', value: 'Titillium Web, sans-serif' },
    { name: 'Ubuntu', value: 'Ubuntu, sans-serif' },
    { name: 'Ultra', value: 'Ultra, sans-serif' },
    { name: 'Varela Round', value: 'Varela Round, sans-serif' },
    { name: 'Yanone Kaffeesatz', value: 'Yanone Kaffeesatz, sans-serif' },
    { name: 'Yellowtail', value: 'Yellowtail, cursive' },
    { name: 'Agency FB', value: 'Agency FB, sans-serif' },
    { name: 'Algerian', value: 'Algerian, cursive' },
    { name: 'Andalus', value: 'Andalus, serif' },
    { name: 'Arial Unicode MS', value: 'Arial Unicode MS, sans-serif' },
    { name: 'Baskerville', value: 'Baskerville, serif' },
    { name: 'Bell MT', value: 'Bell MT, serif' },
    { name: 'Berlin Sans FB', value: 'Berlin Sans FB, sans-serif' },
    { name: 'Bernard MT Condensed', value: 'Bernard MT Condensed, sans-serif' },
    { name: 'Bodoni MT', value: 'Bodoni MT, serif' },
    { name: 'Britannic', value: 'Britannic, sans-serif' },
    { name: 'Broadway', value: 'Broadway, sans-serif' },
    { name: 'Brush Script', value: 'Brush Script, cursive' },
    { name: 'Calisto MT', value: 'Calisto MT, serif' },
    { name: 'Cambria Math', value: 'Cambria Math, serif' },
    { name: 'Castellar', value: 'Castellar, sans-serif' },
    { name: 'Century Schoolbook', value: 'Century Schoolbook, serif' },
    { name: 'Chiller', value: 'Chiller, cursive' },
    { name: 'Copperplate', value: 'Copperplate, sans-serif' },
    { name: 'Corbel', value: 'Corbel, sans-serif' },
    { name: 'Curlz MT', value: 'Curlz MT, cursive' },
    { name: 'DejaVu Sans', value: 'DejaVu Sans, sans-serif' },
    { name: 'Ebrima', value: 'Ebrima, sans-serif' },
    { name: 'Elephant', value: 'Elephant, sans-serif' },
    { name: 'Engravers MT', value: 'Engravers MT, sans-serif' },
    { name: 'Eras Bold ITC', value: 'Eras Bold ITC, sans-serif' },
    { name: 'Eurostile', value: 'Eurostile, sans-serif' },
    { name: 'Felix Titling', value: 'Felix Titling, sans-serif' },
    { name: 'Footlight MT Light', value: 'Footlight MT Light, sans-serif' },
    { name: 'Forte', value: 'Forte, sans-serif' },
    { name: 'Freestyle Script', value: 'Freestyle Script, cursive' },
    { name: 'French Script MT', value: 'French Script MT, cursive' },
    { name: 'Gill Sans MT', value: 'Gill Sans MT, sans-serif' },
    { name: 'Goudy Old Style', value: 'Goudy Old Style, serif' },
    { name: 'Haettenschweiler', value: 'Haettenschweiler, sans-serif' },
    { name: 'Harrington', value: 'Harrington, sans-serif' },
    { name: 'High Tower Text', value: 'High Tower Text, serif' },
    { name: 'Imprint MT Shadow', value: 'Imprint MT Shadow, sans-serif' },
    { name: 'Informal Roman', value: 'Informal Roman, serif' },
    { name: 'Jokerman', value: 'Jokerman, cursive' },
    { name: 'Juice ITC', value: 'Juice ITC, cursive' },
    { name: 'Kristen ITC', value: 'Kristen ITC, cursive' },
    { name: 'Leelawadee', value: 'Leelawadee, sans-serif' },
    { name: 'Lucida Bright', value: 'Lucida Bright, serif' },
    { name: 'Magneto', value: 'Magneto, cursive' },
    { name: 'Maiandra GD', value: 'Maiandra GD, sans-serif' },
    { name: 'Matura MT Script Capitals', value: 'Matura MT Script Capitals, cursive' },
    { name: 'Mistral', value: 'Mistral, cursive' },
    { name: 'Modern No. 20', value: 'Modern No. 20, sans-serif' },
    { name: 'Mongolian Baiti', value: 'Mongolian Baiti, sans-serif' },
    { name: 'Monotype Corsiva', value: 'Monotype Corsiva, cursive' },
    { name: 'MS Gothic', value: 'MS Gothic, sans-serif' },
    { name: 'MS Outlook', value: 'MS Outlook, sans-serif' },
    { name: 'MS Reference Sans Serif', value: 'MS Reference Sans Serif, sans-serif' },
    { name: 'MS Reference Specialty', value: 'MS Reference Specialty, sans-serif' },
    { name: 'MT Extra', value: 'MT Extra, sans-serif' },
    { name: 'Niagara Engraved', value: 'Niagara Engraved, sans-serif' },
    { name: 'Niagara Solid', value: 'Niagara Solid, sans-serif' },
    { name: 'OCR A Extended', value: 'OCR A Extended, sans-serif' },
    { name: 'Old English Text MT', value: 'Old English Text MT, sans-serif' },
    { name: 'Onyx', value: 'Onyx, sans-serif' },
    { name: 'Papyrus', value: 'Papyrus, fantasy' },
    { name: 'Perpetua Titling MT', value: 'Perpetua Titling MT, sans-serif' },
    { name: 'Playbill', value: 'Playbill, sans-serif' },
    { name: 'Poor Richard', value: 'Poor Richard, sans-serif' },
    { name: 'Ravie', value: 'Ravie, sans-serif' },
    { name: 'Rockwell Condensed', value: 'Rockwell Condensed, sans-serif' },
    { name: 'Sakkal Majalla', value: 'Sakkal Majalla, sans-serif' },
    { name: 'Segoe UI Emoji', value: 'Segoe UI Emoji, sans-serif' },
    { name: 'Showcard Gothic', value: 'Showcard Gothic, sans-serif' },
    { name: 'Stencil', value: 'Stencil, sans-serif' },
    { name: 'Symbol', value: 'Symbol, sans-serif' },
    { name: 'Tempus Sans ITC', value: 'Tempus Sans ITC, sans-serif' },
    { name: 'Tw Cen MT', value: 'Tw Cen MT, sans-serif' },
    { name: 'Verdana Pro', value: 'Verdana Pro, sans-serif' },
    { name: 'Viner Hand ITC', value: 'Viner Hand ITC, cursive' },
    { name: 'Vivaldi', value: 'Vivaldi, cursive' },
    { name: 'Vladimir Script', value: 'Vladimir Script, cursive' },
    { name: 'Wide Latin', value: 'Wide Latin, sans-serif' },
    { name: 'Wingdings', value: 'Wingdings, sans-serif' },
    { name: 'Yu Gothic', value: 'Yu Gothic, sans-serif' },
    { name: 'Yu Gothic UI', value: 'Yu Gothic UI, sans-serif' },
    { name: 'Zapf Chancery', value: 'Zapf Chancery, cursive' },
    { name: 'Zapf Dingbats', value: 'Zapf Dingbats, sans-serif' },
    { name: 'Zrnic', value: 'Zrnic, cursive' },
    // Add more font families as needed




];

// Merge the two arrays of font families

export const HeaderNavtextfont = [
    'Arial',
    'Arial Black',

    'Brush Script MT',
    'Baskerville',
    'Book Antiqua',
    'Consolas',
    'Copperplate Gothic',
    'Comic Sans MS',
    'Calibri',
    'Courier New',
    'Copperplate',
    'Cambria',
    'Franklin Gothic Medium',
    'Georgia',
    'Impact',
     'Lucida Sans',
    'Lucida Console',
    'Lucida Handwriting',
    'Monaco',
    'Papyrus',
   
     'Tahoma',
    'Trebuchet MS',


    'Verdana',

    
    

]