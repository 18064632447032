import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import landingbackground from '../assets/images/backgroundpng.png'
import NewImage from '../assets/images/Newlandingbackground.png'
import Accordion from './Accordion';
import effect from '../assets/images/imgpsh_fullsize_anim (1).png'
import '../assets/CSS/landingpage.css'
import '../assets/CSS/googletranslatecss.css'
import card1 from '../assets/images/image 4.png'
import card2 from '../assets/images/image 5.png';
import card3 from '../assets/images/image 7.png';
import fluent1 from '../assets/images/fluent1.png';
import fluent2 from '../assets/images/fluent2.png'
import fluent3 from '../assets/images/fluent3.png'
import searchicon from '../assets/images/iconoir_search.png'
import twitter from '../assets/images/twitter.png'
import telegram from '../assets/images/telegram.png'
import github from '../assets/images/github.png'
import tiktok from '../assets/images/tiktok.png'
import MyContext from '../context/MyContext';
import Logo from '../assets/images/Newlogo.png'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi'
import glassimg from '../assets/images/glassimg-removebg.png'
import maticlogo from '../assets/images/matic-logo.png'
import vector from '../assets/images/Vector.svg'
import Newlogo from '../assets/images/trendifyweblogo.png'
import Web3 from 'web3'
import TTAVTAR from '../assets/images/Rectangle 10.png'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../assets/images/ProfileIcon.png'

import Product1 from '../assets/images/Rectangle 10.png'
import Product1Logo from '../assets/images/ttavatar1.png'
import Product2 from '../assets/images/Trendefytokens.png'
import Product2Logo from '../assets/images/trendifytokens1.png'
import Product3 from '../assets/images/Trends.png'
import Product3Logo from '../assets/images/trends1.png'
import Product4 from '../assets/images/Dex.png'
import Product4Logo from '../assets/images/dex1.png'
import TrendsLogo from '../assets/images/TrendsLogo.png'
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { Link, BrowserRouter, Routes, Route, useNavigate, useNavigation } from 'react-router-dom';
import PDF from '../assets/images/Meme.pdf'
import Dashboardsite from './Dashboradsite';
import TemplateHistory from './TemplateHistory';
import MemeTemplates from './MemeTemplates';
import { use } from 'i18next';
import Main from './Main';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import VideoModal from './Aiwebtutorial';
import Playbutton from '../assets/images/Play.png'
import Helmet from 'react-helmet';

// import Profile from '@fortawesome/free-solid-svg-icons/fa-solid fa-user';
const NewLandingPage = ({ onGenerateWebsite }) => {
    const { searchTerm, setShowWebsite, setSearchTerm,
        memecoin, setMemecoin, serachtearm1,
        setSearchteam, showWebsite,
        purchaseData, setPurchaseData,
        expiredate, setExpiredate,
        setPurchaseplane, purchaseplane,
        modalShow, setModalShow,
        setShowSites,selectMemeCoinTemplates,
    } = useContext(MyContext);
    const [expanded, setExpanded] = useState(false);
    const [error, seterror] = useState('')
    const { title } = useContext(MyContext)
    const { open } = useWeb3Modal();
    const { address, isConnected } = useAccount()
    const [connectedAddress, setConnectedAddress] = useState("");
    const [showloder, setShowloder] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchHistory, setSearchHistory] = useState('');
    const [showwebsitehistory, setShowWebsiteHistory] = useState(false);
    const [googletransalte,setGoogletransalte]=useState(false);
    const [vediomodalShow, setvedioModalShow] = useState(false)

    const handleClickPopup = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const Open = Boolean(anchorEl);
    const id = Open ? 'simple-popover' : undefined;



    const handleGenerateClick = () => {

        if (isConnected == false) {
            handleClick();
        } else {

            const Regex = /[a-zA-Z]/;
            setShowloder(true);
            setMemecoin(false);



            setTimeout(() => {
                if (searchTerm !== '' && ((searchTerm.length >= 10) && (searchTerm.length < 40))) {
                    // Assuming Regex is a regular expression object you want to match searchTerm against

                    if (Regex.test(searchTerm)) {
                        onGenerateWebsite(searchTerm);
                    } else {
                        setShowloder(false);
                        seterror('The AI could not understand your input. Please try again');
                    }

                }

                else {
                    setShowloder(false);

                    seterror(`${(searchTerm.length > 40) && (searchTerm.length > 10) ? "the AI could not understand your input. Please try again" : 'Please enter at least 10 characters'}`);
                }
            }, 500);

        }








    };
    console.log('rounting', memecoin)

    useEffect(() => {
        setShowloder(false);
        setSearchTerm('')
    }, [])



    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        setSearchteam(event.target.value);
    };


    const accordionItems = [
        { title: 'Can I customize templates?', content: 'Absolutely! We offer a variety of templates to meet your specific needs. We will continue to expand our selection as the project evolves.' },
        { title: 'What is AI-assisted design?', content: 'Our AI-assisted design leverages artificial intelligence to select the most suitable content and images for your website, significantly reducing the time you spend on content creation and image selection.' },
        { title: 'Is coding required?', content: 'No, there is no coding required to create your website with us.' },
        {
            title: 'How much does it cost?', content: `
        Basic - 12 USDC (one-time fee)          ,
        Gold - 120 USDC (every 6 months)         ,
        Platinum - 250 USDC (annually)`  }
    ];


    const handleClick = async () => {


        open();

        // handleGenerateClick();

    };

    useEffect(() => {
        if (isConnected) {
            setConnectedAddress(address);
            console.log("Connected Address:", address);

        }
    }, [isConnected, address]);

    console.log('loder', showloder);
    useEffect(() => {
        if (isConnected) {
            setConnectedAddress(address);
            console.log("Connected Address:", address);

        }
    }, [isConnected, address]);

    console.log("isConnected", isConnected)

    const Handelpurchasedata = (num) => {
        if (num == 0) {

            const date = new Date();
            const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            setPurchaseData(formattedDate);
            setExpiredate('To be Continued');

        }

        if (num == 6) {
            const date = new Date();
            const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            setPurchaseData(formattedDate);
            const Sixmonthsexpiredata = new Date(date)
            const ExpireDate = `${Sixmonthsexpiredata.getDate()}-${Sixmonthsexpiredata.getMonth() + 7}-${Sixmonthsexpiredata.getFullYear()}`;
            setExpiredate(ExpireDate)

        };


        if (num == 12) {
            const date = new Date();
            const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            setPurchaseData(formattedDate);
            const Oneyearsexpiredata = new Date(date);
            const ExpireDate = `${Oneyearsexpiredata.getDate()}-${Oneyearsexpiredata.getMonth() + 1}-${Oneyearsexpiredata.getFullYear() + 1}`;
            setExpiredate(ExpireDate)
        };
    }


    useEffect(() => {
        isConnected && setConnectedAddress();
        setPurchaseData('');
        setExpiredate('');
    }, []);
    const handleKeyDown = (event) => {



        if (event && event.key === 'Enter') {

            if (isConnected == false) {
                handleClick();
            };
            if (isConnected == true) {
                event.preventDefault();
                handleGenerateClick();
            };

        }



    };

    console.log('Transaction result:', isConnected)


    // const id = useId();
    const productData = [

        {
            id,
            bgImg: Product2,
            logo: Product2Logo,
            description:
                " Empowering Innovations-Your Gateway to the Future of Crypto",
            link: "https://trendifytokens.io/",
        },
        {
            id,
            bgImg: Product3,
            logo: Product3Logo,
            description:
                "Watch Ads & earn rewards or be an Advertisers on Trendads.",
            link: "https://trendads.ai/",
        },
        {
            id,
            bgImg: Product4,
            logo: Product4Logo,
            description:
                "Swap your any Coin with your favorite one now with Trenddx.",
            link: "https://trenddx.io/",
        },
    ];

    const handleStoreSearchHistory = (searchTerm) => {
        // Retrieve existing search history from sessionStorage
        let searchHistory = JSON.parse(sessionStorage.getItem('searchHistory')) || [];

        // Add new searchTerm to the beginning of the searchHistory array
        searchHistory.unshift(searchTerm);

        // Limit the search history to, for example, 10 items
        if (searchHistory.length > 10) {
            searchHistory = searchHistory.slice(0, 10);
        }

        // Save updated search history back to sessionStorage
        sessionStorage.setItem(searchHistory, JSON.stringify(searchHistory));

    };
    useEffect(() => {
        setSearchHistory(sessionStorage.getItem[searchHistory])
    },);

    const HandelPlanepurachase = async () => {
        setShowWebsiteHistory(true);
        // setShowWebsite(true);
        setShowSites(true);


        const savedValue = localStorage.getItem('wagmi.store');

        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value

        let useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            console.log('useradderess', useradderess)
        } else {
            alert('Please connect your wallet')

        }

        const url = new URL('https://trendifyweb.ai/pyapi/getpublishedsites');
        url.searchParams.append('address', useradderess);


        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });


        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        // Parse the JSON response
        const res = await response.json();
        console.log('Response', res);

        // Extract data and update the state
        const data = res?.data;
        setPurchaseplane(data);
       



    }

    const openNewTab = () => {
        window.open(`${PDF}`, '_blank', 'noopener,noreferrer');
    };
    console.log('showWebsiteHistory', purchaseplane);
    useEffect(() => {
        if (modalShow == true) {
            setShowloder(false)
        }
    }, [modalShow]);


   

 // This is a child of 'parentElement'
 useEffect(()=>{
    let parent = document.getElementById("parentElement");
let newChild = document.createElement("div");
let referenceNode = document.getElementById("google_translate_element");
  
    if (parent && referenceNode && parent.contains(referenceNode)) {
        parent.insertBefore(newChild, referenceNode); // Works because 'referenceNode' is a child of 'parent'
    }
 },[selectMemeCoinTemplates])


     
    return (
        < >

            {showWebsite == true ?<Main/>: 
                    
                <div>
                <div style={{
                    backgroundImage: `url(${landingbackground})`, width: '100%',
                    position: 'relative',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    height: '100%',
                    backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
                }}>

                    <Navbar expand="lg" className="custom-navbar sticky-top">
                        <Container className='p-0'>
                            <Navbar.Brand href="#" style={{ marginRight: "120px" }}>
                                <img src={Newlogo} alt="Logo" style={{ height: '30px', width: '150px' }} />
                            </Navbar.Brand>
                            <Nav className="ms-auto">
                                <button className="d-lg-none generate-parent" style={{ fontSize: '14px', fontWeight: "500" }} onClick={() => handleClick()} >
                                    {isConnected ? `${address.substring(0, 4)}...${address.substring(38)}` : "Connect Wallet"}

                                </button>

                            </Nav>
                            <Navbar.Toggle aria-controls="navbarSupportedContent" />
                            <Navbar.Collapse id="navbarSupportedContent">
                                <Nav className="me-auto mb-2 mb-lg-0 " >
                                    <Nav.Item>
                                        <Nav.Link href="#Home" className='mx-2' active>Home</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="#Features" className='mx-2'>Features</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="#Pricing" className='mx-2'>Pricing</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className='mx-2' onClick={()=>setGoogletransalte(!googletransalte)}  >

                                               EN
                                            
                                        </Nav.Link>
                                        <div
                                                style={{
                                                    backgroundColor: '#269D7E',
                                                    padding: '0px 8px',
                                                    marginRight: '10px',
                                                    borderRadius: '8px',
                                                    display:googletransalte==false? 'none':'block'
                                                }}
                                                id="google_translate_element"
                                                
                                            ></div>
                                    </Nav.Item>
                                    <Nav.Item style={{ display: isConnected == true ? 'block' : 'none', }}>
                                        <Nav.Link className='mx-2' onClick={() => { HandelPlanepurachase() }}  ><Link to='/DashboardSite' style={{ color: 'white', textDecorationLine: 'none' }}>My-Site</Link></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item >
                                        <Nav.Link className='mx-2' onClick={openNewTab}>Docs</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </Navbar.Collapse>

                            <button className="generate-parent mx-4 d-none d-lg-block" style={{ fontSize: '14px', fontWeight: "500" }} onClick={() => handleClick()} >
                                {isConnected ? `${address.substring(0, 4)}...${address.substring(38)}` : "Connect Wallet"}
                            </button>

                        </Container>
                        <div>


                        </div>
                    </Navbar>
                     <Helmet>
                        <script type="text/javascript">
                            {`function googleTranslateElementInit() {
                        new google.translate.TranslateElement(
                            { pageLanguage: 'en' },
                            'google_translate_element'
                        );
                    }`
                    }
                        </script>
                        <script
                            type="text/javascript"
                            src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                        ></script>
                    </Helmet> 
                    <div
                        style={{
                            backgroundColor: '#269D7E',
                            padding: '0px 8px',
                            marginRight: '10px',
                            borderRadius: '8px'
                        }}
                        id="google_translate_element"
                    ></div>

               <div class='LogoImage' >
                        <img src={NewImage} alt='Image' width='100%'></img>
                       
                    </div>
                    <div className='container' id='Home'>
                        <div className='row '>
                            <div className='col-md-7 mt-4 '  >
                                <div style={{ textAlign: 'left', fontSize: '64px', lineHeight: '140%', fontWeight: '650', color: 'white' }}>
                                    Create Your<br />Website In A Flash,<br />
                                    With AI Technology
                                </div>
                                <div className='build-edit-and '>Build, edit and publish stunning website in just a few clicks - no coding required.</div>
                                <div style={{position:'relative',display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
                                <div class="fancy mt-4 d-flex">
                                    <form>
                                    <input type='text'
                                        className='inputbar'
                                        placeholder='I need a website for...'
                                        onChange={handleInputChange}
                                        required
                                        onKeyDown={handleKeyDown}
                                        style={{ width: '80%', overflow: 'hidden' }}
                                        autoComplete='on'
                                        

                                    />
                                    </form>

                                    <button class="generate-parent " onClick={() => { handleGenerateClick(); handleStoreSearchHistory(searchTerm); }} >
                                        <div class="generate me-1"> {isConnected ? `Generate` : "Generate"}</div>
                                       

                                        {showloder == false ? <img class="frame-child" src={effect} alt="" /> :
                                            <div class="spinner-border border-1" role="status" style={{ height: 17, width: 16, marginLeft: 3 }}>
                                            </div>
                                        }
                                        
                                    </button>
                    
                                     
                                </div>
                                <div style={{cursor:'pointer'}}>
                                {/* <FontAwesomeIcon icon={faPlay} style={{position:'relative',width:'40px',height:'40px',color:'#174780',top:'15px',}}  onClick={() => setvedioModalShow(true)}/> */}
                                <img src={Playbutton} style={{position:'relative',width:'70px',height:'40px',top:'10px',}}  onClick={() => setvedioModalShow(true)}></img>

                                </div>
                                </div>

                                {/* <div class="btn btn-secondary" data-container="body" data-toggle="popover" data-placement="bottom" data-content="Vivamus
                             sagittis lacus vel augue laoreet rutrum faucibus.">
                               
                                </div> */}
                                <span className=' text-danger ' style={{ marginLeft: "30px" }}>{error}</span>
                               
                            </div>
                            

                        </div>
                        


                    </div>
                    
                    <VideoModal show={vediomodalShow} onHide={() => setvedioModalShow(false)} />

                   
                   

                    <section className='_cc2950fb '>
                        <div className='container ' >
                            <div className='col-md-12 mx-auto' style={{ marginTop: '80px' }}>
                                <h5 className='harness-the-power '>Harness the power of our first-of-its-kind, fully private web3 website creator!</h5>
                            </div>
                            <div className='col-md-8 mx-auto'>
                                <p className='trendyfi-web-stands mt-3'>"Trendify Web stands out as the first fully private web3 website creator, tailored for both entrepreneurs and crypto enthusiasts. Build your project's website swiftly and effortlessly, even without any coding expertise."</p>
                            </div>
                        </div>
                    </section>


                    <section className='_41469265'>
                        <div className='container'>
                            <div className='_d95f39be'>
                                <div className='_242c4c97 '>
                                    <div><img src={card1} style={{ borderRadius: '32px' }} className='img-fluid' alt='' /></div>
                                    <div className="step-1-parent mt-4">
                                        <b className="step-1">STEP 1:</b>
                                        <div className="generate-your-website">Generate your website with AI using the prompt feature. Simply type your request and let the AI create your website for you.</div>
                                    </div>
                                </div>
                                <div className='_242c4c97 '>
                                    <div ><img src={card2} style={{ borderRadius: '32px' }} className='img-fluid' alt='' /></div>
                                    <div className="step-1-parent mt-4">
                                        <b className="step-1">STEP 2:</b>
                                        <div className="generate-your-website">Easily edit your content and select from our range of beautiful design options. No need to worry about controlling every single pixel.</div>
                                    </div>
                                </div>
                                <div className='_242c4c97 '>
                                    <div><img src={card3} style={{ borderRadius: '32px' }} className='img-fluid' alt='' /></div>
                                    <div className="step-1-parent mt-4">
                                        <b className="step-1">STEP 3:</b>
                                        <div className="generate-your-website">Get online with just one click and quickly connect with your audience. Instantly publish your site under trendifyweb.ai subdomain or on your own domain name for a fast, reliable, and scalable online presence.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    <section className='_7002e023'>
                        <div className='container' >
                            <div className='_298f81cc'>
                                <div className='_66958e39'>
                                    <div className='_500f8d70'>
                                        <div class="_5c661fe6"><span class="">100</span></div>
                                        <span class="_6f97eed2">Template Used</span>
                                    </div>
                                </div>
                                <div className='_66958e39'>
                                    <div className='_500f8d70'>
                                        <div class="_5c661fe6"><span class="">50</span>k</div>
                                        <span class="_6f97eed2">Sites Launched</span>
                                    </div>
                                </div>
                                <div className='_66958e39'>
                                    <div className='_500f8d70'>
                                        <div class="_5c661fe6"><span class="">95</span>%</div>
                                        <span class="_6f97eed2">Customer Satisfied</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section className='_cc2950fb' id='Features'>
                        <div className='container' >
                            <h1 className='core-features'>Core Features</h1>
                            <p className='discover-the-powerful'>Discover the powerful functionalities that make Trend AI the ultimate choice for building your online presence.</p>
                        </div>
                        <div className='container mt-4'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <div className='_66958e39'>
                                    <div className="streamlineinterface-edit-flip-parent">
                                        <img className="streamlineinterface-edit-flip-icon" alt="" src={fluent1} />
                                        <div className="smart-design-parent">
                                            <div className="smart-design">Smart Design</div>
                                            <div className="smart-design-our">Smart Design Our AI crafts your website with precision, using industry-specific templates and customizable features.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='_66958e39'>
                                    <div className="streamlineinterface-edit-flip-parent">
                                        <img className="streamlineinterface-edit-flip-icon" alt="" src={fluent2} />
                                        <div className="smart-design-parent">
                                            <div className="smart-design">Preference Adaptions</div>
                                            <div className="smart-design-our">Instant Edits make real-time changes with an intuitive editor - your AI partner adapts to your preference. </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='_66958e39'>
                                    <div className="streamlineinterface-edit-flip-parent">
                                        <img className="streamlineinterface-edit-flip-icon" alt="" src={fluent3} />
                                        <div className="smart-design-parent">
                                            <div className="smart-design">SEO Boosting</div>
                                            <div className="smart-design-our">SEO Boost Optimize your site’s visibility with built-in SEO tools, strategically driving traffic to your business.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='_cc2950fb '>
                        <div className='container'>
                            <div className='faq-tab' >
                                <div style={{ padding: '20px' }}>
                                    <div className="faq-parent">
                                        <b className="faq">FAQ</b>
                                    </div>
                                    <div className="have-questions-weve">Have questions? We’ve compiled a list of frequently asked questions to help you understand everything Trend AI Website builder offers.</div>
                                    {/* <div className="iconoirsearch-parent">
                                    <img className="iconoirsearch" alt="" src={searchicon} />
                                    <input className="search " placeholder='Search' />
                                </div> */}
                                    <Accordion items={accordionItems} />
                                </div>
                            </div>
                        </div>
                    </section>




                    <div id="products">
                        <div className="product-containerproduct">
                            <div className="text-center mb-4">
                                <p style={{ color: "rgb(217, 217, 217)" }}>Products</p>
                                <p className="product-heading">Explore Trend Products</p>
                            </div>
                            <div className="d-flex flex-wrap gap-4 justify-content-center">
                                {productData?.map((card) => (
                                    <div className="product-card-containerproduct">
                                        <div>
                                            <img src={card.bgImg} alt={card.bgImg} style={{ width: "100%" }} />
                                        </div>
                                        <div>
                                            <img src={card.logo} alt={card.logo} className="product-card-logo" />
                                        </div>
                                        <p className="product-card-text">{card.description}</p>
                                        <div className="d-flex justify-content-center">
                                            <a href={card.link} target="_blank" className="product-button" style={{ textDecoration: 'none' }}>
                                                Checkout Now
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>



                    <section className='_cc2950fb ' id='Pricing'>
                        <div className='container' >
                            <h1 className='core-features'>How much does it cost?</h1>
                            <p className='subscription-plan'>Subscription Plan</p>
                        </div>
                        <div className='container'>
                            <div className='row'>

                                <div className='col-lg-4 col-md-6'>
                                    <div className='cardedit p-3 d-flex flex-column justify-content-between ' >
                                        <div>
                                            <div className='platinum'>Basic</div>
                                            <div className="usdt-parent">
                                                <div className="usdt">12 USDC</div>
                                                <div className="yr">/website</div>
                                            </div>
                                            <div className='d-flex flex-column' style={{ gap: '16px 0px' }}>
                                                {/* <div style={{ textAlign: 'left' }}>
                                                <img src={maticlogo} style={{ height: '32px', width: 'auto' }} alt='' />
                                            </div> */}
                                                <br />
                                                <div className='d-flex flex-column mb-4 h-100' style={{ gap: '12px 0px' }}>
                                                    <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Create a Website at no cost.</div>
                                                    </div>
                                                    <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Download HTML Code.</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <button className='buy-now-button  ' onClick={() => { transferBasicPayment(); Handelpurchasedata(0) }} >
                                        <span className="buy-now"> Buy Now</span>
                                    </button> */}

                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='cardedit p-3 d-flex flex-column justify-content-between ' >
                                        <div>
                                            <div className='platinum'>Gold</div>
                                            <div className="usdt-parent">
                                                <div className="usdt">120 USDC</div>
                                                <div className="yr">/6mo</div>
                                            </div>
                                            <div className='d-flex flex-column' style={{ gap: '16px 0px' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    {/* <img src={maticlogo} style={{ height: '32px', width: 'auto' }} alt='' /> */}
                                                </div>
                                                <br />
                                                <div className='d-flex flex-column mb-4 h-100' style={{ gap: '12px 0px' }}>
                                                    <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Create a Website at no cost.</div>
                                                    </div>
                                                    <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Host with your custom domain.</div>
                                                    </div>
                                                    <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Host with Trendifyweb.ai subdomain.</div>
                                                    </div>
                                                    {/* <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Make unlimited changes and re-host as needed.</div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </div>
                                        {/* <button className='buy-now-button  ' onClick={() => { transferGoldPayment(); Handelpurchasedata(6) }}>
                                        <span className="buy-now"> Buy Now</span>
                                    </button> */}

                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6'>
                                    <div className='cardedit p-3 d-flex flex-column justify-content-between ' >
                                        <div>
                                            <div className='platinum'>Platinum</div>
                                            <div className="usdt-parent">
                                                <div className="usdt">215 USDC</div>
                                                <div className="yr">/yr</div>
                                            </div>
                                            <div className='d-flex flex-column' style={{ gap: '16px 0px' }}>
                                                {/* <div style={{ textAlign: 'left' }}>
                                                <img src={maticlogo} style={{ height: '32px', width: 'auto' }} alt='' />
                                            </div> */}
                                                <br />
                                                <div className='d-flex flex-column mb-4 h-100' style={{ gap: '12px 0px' }}>
                                                    <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Create a Website at no cost.</div>
                                                    </div>
                                                    <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Host with your custom domain.</div>
                                                    </div>
                                                    <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Host with Trendifyweb.ai subdomain.</div>
                                                    </div>
                                                    {/* <div className="vector-parent">
                                                        <img className="vector-icon" alt="" src={vector} />
                                                        <div className="create-a-website">Make unlimited changes and re-host as often as you like.</div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </div>
                                        {/* <button className='buy-now-button  ' onClick={() => { transferPlatinumPayment(); Handelpurchasedata(12) }}>
                                        <span className="buy-now">Buy Now</span>
                                    </button> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className='rectangleDiv' id='EN'>
                        <img className='removebg1Icon' alt="" src={TrendsLogo} />

                    </div>

                    <section className='container my-5' >
                        <div className='_202527cd col-md-8 '>
                            <header class="_b99fbc1f">
                                <h4>Today’s action shapes tomorrow’s success.</h4>

                            </header>
                            <button className="get-started-parent" >
                                <a href='#Home' target='' style={{ textDecoration: 'none', color: 'white' }}>Get Started</a>

                            </button>

                        </div>
                    </section>

                    <footer className='_c4b89fde' >
                        <div className='container'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div class="_c0e4633f ">© 2024 Trendifyweb.ai</div>
                                    <div class="_a6d0f97b mt-1" ><img src={Newlogo} alt="Logo" style={{ height: '30px', width: '150px' }} />
                                    </div>
                                    <div class="d-flex mt-1">
                                        <a href='https://x.com/TTAvatars' target='' className='me-2' >
                                            <img src={twitter} alt='' />
                                        </a>
                                        <a href='https://t.me/trendavatars' target='' >
                                            <img src={telegram} alt='' />
                                        </a>

                                    </div>
                                </div>
                                <ul className='_d1a0a8ea'>
                                    <li className='_0fc50e27 '>

                                        <ul className='_91687afc'>
                                            <li className='_e0485177'><a href='#Home' target=''>Home</a></li>

                                        </ul>
                                    </li>
                                    <li className='_0fc50e27 '>

                                        <ul className='_91687afc'>
                                            <li className='_e0485177'><a href='#Pricing' target=''>Pricing</a></li>

                                        </ul>
                                    </li>
                                    <li className='_0fc50e27 '>

                                        <ul className='_91687afc'>
                                            <li className='_e0485177'><a href='#Features' target=''>Features</a></li>

                                        </ul>
                                    </li>
                                    <li className='_0fc50e27 '>

                                        <ul className='_91687afc'>
                                            <li className='_e0485177'><a href='#Home' target=''>EN</a></li>

                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </footer>
                </div>
                 </div>                           
                }
              


        </>
    );
};

export default NewLandingPage;

