import react, { useContext, useEffect, useRef, useState } from 'react'
import Memecoin from '../Memecoin';
import Theme from '../../Theme';
import IntroDesing from '../../SaveandEdit/Intro/Indrtodesing';
import MyContext from '../../../context/MyContext';
import { useReducer } from 'react';
import ContentDesign from '../../ContentDesign';
import MainNavBar from '../../MainNavBar';
import TrustDesign from '../../SaveandEdit/Trustedby/trustedesing';
import WebPages from '../../Webpages';
import heroImage from '../../../assets/images/BackgroundMemecoin1.png'
import cartoon from '../../../assets/images/leftflying.png'
import boomerright from '../../../assets/images/boomerright.png'
import boomerleft from '../../../assets/images/carttonleft.png'
import MemeMaindesing from './Mememaindesing';
import { SketchPicker } from 'react-color';
import CloudImage from '../../../assets/images/NewMaskImage.png'
import { useAccount } from 'wagmi';
import axios from 'axios';



const MemeHomeEdit = () => {
  const {
    memecoinname, SetMemecoinName,
    memecoinsymbol, setMemecoinSymbol,
    memecoinNavbar, setMemecoinNavbar,
    memecoinAbout, setMemecoinAbout, contentdesing,
    mainnavbarbutton,
    memeCaption, setMemecaption,
    memecoinValue, setMemecoinValue,
    memecoinaboutbgImage, setMemecoinaboutbgIamge,
    memecoinAminitionImage, setMemecoinAminationImage,
    memecoinaboutrightimage, setMemecoinaboutrightimage,
    memecoinaboutleftimage, setMemecoinaboutleftimage,
    memeherobutton, setmemeHerobutton,
    memeherobgcolor, setMemeherobgcolor, setMemeherobackground, uniqueID, selectMemeCoinTemplates,
    template1caption, setTemplate1caption,
    template1Info, setTemplate1Info,
    template2caption, setTemplate2Caption,
    template2memecaption, setTemplate2memecaption,
    template2info, setTemplate2info,
    template2tokenAddress, setTemplate2tokenAddress,
    template2coingecko, setTemplate2coingecko,
    template2cmc, setTemplate2cmc,
    template2uniswap, setTemplateuniswap,
    template3telegramlink, setTemplate3telegramlink,
    template3twitterlink, setTemplate3twitterlink,
    template3uniswaplink, setTemplate3uniswaplink,
    memecloudimage, setMemecloudImage,
    template2removebuttonuniswap, setTemplate2Remvebuttonuniswap,
    template2removebuttonunicmc, setTemplate2Remvebuttonunicmc,
    template2removebuttoncoingecko, setTemplate2Remvebuttoncoingecko,
    t4memeaddress,setT4memeadress,t4caption,setT4caption,
    t4mainbutton1,setT4mainbutton1,
      t4mainbutton2,setT4mainbutton2,setMemetwitterlink,
      memetwitterlink,memetelegramlink,
      setMemetelegramlink,
      memeinstagramlink,setMemeinstagramlink,
      buy,setBuy,
      telegramtext,setTelegramtext,
      twittertext,setTwittertext,
      telegraminage,setTelegraminage,
      twitterimage,setTwitterimage,
      instagramimage,setInstagramimage,
      uniswapimage,setUniswapImage,
      cmcimage,setCmcImage,
      coingeckoimage,setCoingeckoImage,
      template2mainbutton1,setTemplate2mainbutton1,
      template2mainbutton2,setTemplate2mainbutton2,

  } = useContext(MyContext);


  const [isHeaderDesignText, setisHeaderDesignText] = useState(false);

  // API to store meme about content data. For testing purpose using use effect

  const {address} = useAccount()

  useEffect(() => {


    const updateMemeHeroContentData = async () => {
      const response = await axios.post('https://trendifyweb.ai/pyapi/updateMemeHeroContentData', {
        params: {
          address: address,
          siteid: uniqueID,
          memeCaption: memeCaption,
          memecoinValue: memecoinValue,
          memecoinname: memecoinname,
          memecoinsymbol: memecoinsymbol,
          memeherobutton: memeherobutton,
          memecoinaboutbgImage: memecoinaboutbgImage,
          memeherobgcolor: memeherobgcolor,
          memecoinAminitionImage: memecoinAminitionImage,
          memecoinaboutleftimage: memecoinaboutleftimage,
          memecoinaboutrightimage: memecoinaboutrightimage,
          template1caption: template1caption,
          template1Info: template1Info,
          template2caption: template2caption,
          template2memecaption: template2memecaption,
          template2info: template2info,
          template2tokenAddress: template2tokenAddress,
          template2coingecko: template2coingecko,
          template2cmc: template2cmc,
          template2uniswap: template2uniswap,
          template3telegramlink: template3telegramlink,
          template3twitterlink: template3twitterlink,
          template3uniswaplink: template3uniswaplink,
          t4memeaddress: t4memeaddress,
          t4caption: t4caption,
          t4mainbutton1: t4mainbutton1,
          t4mainbutton2: t4mainbutton2,
          memetwitterlink: memetwitterlink,
          memetelegramlink: memetelegramlink,
          memeinstagramlink: memeinstagramlink,
          
        }
      });
      console.log('memeHeaderDesignAPIresponse', response)
    };
    updateMemeHeroContentData()
  }, [memecoinaboutrightimage, memecoinaboutbgImage])

  const HandelMemecoinName = (e) => {
    SetMemecoinName(e.target.value)
  };

  const HandelMemecoinSymbol = (e) => {
    setMemecoinSymbol(e.target.value)
  };
  const HandelMemecoincaption = (e) => {
    setMemecaption(e.target.value)
  };
  const HandelMemecoincaptionT1 = (e) => {
    setTemplate1caption(e.target.value)
  };
  const HandelMemecoinValue = (e) => {
    setMemecoinValue(e.target.value)
  };
  const HandelMemecoinT1Info = (e) => {
    setTemplate1Info(e.target.value)
  };

  const handleMainBackgroundImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinaboutbgImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinaboutbgIamge(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  const handleAminationImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinAminitionImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinAminationImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleCloudImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecloudimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecloudImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleLeftImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinaboutleftimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinaboutleftimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleRightImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinaboutrightimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinaboutrightimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handelchangecolor = (titlecolor) => {
    setMemeherobgcolor(titlecolor.hex)
   
  };

  const handeltitle = () => {
    setisHeaderDesignText(!isHeaderDesignText)
  }
  useEffect(() => {
    function handleClickOutside(event) {
      const target = event.target;
      const divToExclude = document.getElementById('one');

      // Check if the click occurred outside of the specific div
      if (divToExclude && !divToExclude.contains(target)) {
        setisHeaderDesignText(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isHeaderDesignText]);

  const handleBuyImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...buy];
        updatedTrustLogo[index] = event.target.result;
        setBuy(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handletelegramImage = (index) => {

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...uniswapimage];
        updatedTrustLogo[index] = event.target.result;
        setUniswapImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  const handletwitterimage= (index) => {

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...cmcimage];
        updatedTrustLogo[index] = event.target.result;
        setCmcImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleInstagramImage = (index) => {

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...coingeckoimage];
        updatedTrustLogo[index] = event.target.result;
        setCoingeckoImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  return (
    <div>

      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?
              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Hero" />
                {contentdesing == false ?
                  <div >
                    {selectMemeCoinTemplates == 1 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "32px" }} value={memeCaption} onChange={(e) => HandelMemecoincaption(e)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Value</span>
                                        <textarea placeholder="Value" style={{ height: "32px" }} value={memecoinValue} onChange={(e) => HandelMemecoinValue(e)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Meme Coin Name</span>
                                        <textarea placeholder="Name" style={{ height: "32px" }} value={memecoinname} onChange={(e) => HandelMemecoinName(e)}>
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Meme Coin Symbol</span>
                                        <textarea placeholder="Symbol" style={{ height: "32px" }} value={memecoinsymbol} onChange={(e) => HandelMemecoinSymbol(e)} >
                                        </textarea>
                                      </div>

                                      <div class="form_field__d31bq" >
                                        <span>Buy</span>
                                        <textarea placeholder="Link" style={{ height: "32px" }} value={template2cmc} onChange={(e) => setTemplate2cmc(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Join US</span>
                                        <textarea placeholder="Text" style={{ height: "32px" }} value={memeherobutton} onChange={(e) => setmemeHerobutton(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <div class='mt-3'>
                                        <label>Background Image</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br/>

                                        <span >Background Color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(1) }}>
                                          <label>Background Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />
                                        {/* <div className="media_preview__okAyH">
                                          <label>Cloud Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecloudimage.length > 0 ?
                                              <div>
                                                {memecloudimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleCloudImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleCloudImage(0)} style={{ height: 30, width: 40 }} src={memecloudimage.length > 0 ? memecloudimage : CloudImage} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br/> */}
                                        <div className="media_preview__okAyH">
                                          <label>Animation Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinAminitionImage.length > 0 ?
                                              <div>
                                                {memecoinAminitionImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleAminationImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleAminationImage(0)} style={{ height: 30, width: 40 }} src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label>Left Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutleftimage.length > 0 ?
                                              <div>
                                                {memecoinaboutleftimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleLeftImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleLeftImage(0)} style={{ height: 30, width: 40 }} src={memecoinaboutleftimage.length > 0 ? memecoinaboutleftimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label>Right Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutrightimage.length > 0 ?
                                              <div>
                                                {memecoinaboutrightimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleRightImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleRightImage(0)} style={{ height: 30, width: 40 }} src={memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}

                    {selectMemeCoinTemplates == 2 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "32px" }} value={template1caption} onChange={(e) => HandelMemecoincaptionT1(e)} >
                                        </textarea>
                                      </div>

                                      
                                      <div class="form_field__d31bq" >
                                        <span>Meme Coin Symbol</span>
                                        <textarea placeholder="Symbol" style={{ height: "32px" }} value={memecoinsymbol} onChange={(e) => HandelMemecoinSymbol(e)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Info</span>
                                        <textarea placeholder="Text" style={{ height: "52px" }} value={template1Info} onChange={(e) => HandelMemecoinT1Info(e)} >
                                        </textarea>
                                      </div>
                                      
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Telegram</span>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memetelegramlink} onChange={(e) => setMemetelegramlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <textarea placeholder="Text" style={{ height: "fit-content" }} value={telegramtext} onChange={(e) => setTelegramtext(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Twitter</span>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memetwitterlink} onChange={(e) => setMemetwitterlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <textarea placeholder="Text" style={{ height: "fit-content" }} value={twittertext} onChange={(e) => setTwittertext(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br/>
                                         
                                      <div class='mt-3'>
                                        <label> Background Image</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br/>

                                        <span >Background Color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(1) }}>
                                          <label> Background Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />

                                        <br />

                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label> Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutrightimage.length > 0 ?
                                              <div>
                                                {memecoinaboutrightimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleRightImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleRightImage(0)} style={{ height: 30, width: 40 }} src={memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}

                    {selectMemeCoinTemplates == 3 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "62px" }} value={template2caption} onChange={(e) => setTemplate2Caption(e.target.value)} >
                                        </textarea>
                                      </div>

                                      {/* <div class="form_field__d31bq" >
                                      <span>Meme Coin Name</span>
                                      <textarea placeholder="Name" style={{ height: "32px" }} value={memecoinname} onChange={(e) => HandelMemecoinName(e)}>
                                      </textarea>
                                    </div> */}
                                      <div class="form_field__d31bq" >
                                        <span>Meme Coin Symbol</span>
                                        <textarea placeholder="Symbol" style={{ height: "32px" }} value={memecoinsymbol} onChange={(e) => HandelMemecoinSymbol(e)} >
                                        </textarea>
                                        
                                        
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Info</span>
                                        <textarea placeholder="Text" style={{ height: "62px" }} value={template2info} onChange={(e) => setTemplate2info(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <textarea placeholder="Text" style={{ height: "fit-content" }} value={template2memecaption} onChange={(e) => setTemplate2memecaption(e.target.value)} >
                                        </textarea>
                                        
                                      </div>
                                      <br />
                                      <div class="form_field__d31bq" >
                                        <span>Address</span>
                                        <textarea placeholder="Address" style={{ height: "52px" }} value={template2tokenAddress} onChange={(e) => setTemplate2tokenAddress(e.target.value)} >
                                        </textarea>
                                      </div>

                                      <br />
                                      <div class="form_field__d31bq" >
                                        <span>Button</span>
                                        <textarea placeholder="Text" style={{ height: "fit-content" }} value={template2mainbutton1} onChange={(e) => setTemplate2mainbutton1(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <textarea placeholder="Text" style={{ height: "fit-content" }} value={template2mainbutton2} onChange={(e) => setTemplate2mainbutton2(e.target.value)} >
                                        </textarea>
                                      </div>
                                            <br/>
                                      <div class="form_field__d31bq" >

                                        <span>Uniswap</span>
                                        <textarea placeholder="Link" style={{ height: "52px" }} value={template2uniswap} onChange={(e) => setTemplateuniswap(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                          <label>Uniswap Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {uniswapimage.length > 0 ?
                                              <div>
                                                {uniswapimage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handletelegramImage(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handletelegramImage(0) }} style={{ height: 30, width: 40 }} src={uniswapimage.length > 0 ? uniswapimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>


                                      <br />
                                      <div class="form_field__d31bq" >
                                        <span>CMC</span>
                                        <textarea placeholder="Link" style={{ height: "52px" }} value={template2cmc} onChange={(e) => setTemplate2cmc(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                          <label>CMC Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {cmcimage.length > 0 ?
                                              <div>
                                                {cmcimage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handletwitterimage(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handletwitterimage(0) }} style={{ height: 30, width: 40 }} src={cmcimage.length > 0 ? cmcimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                      </div>

                                      <br />
                                      <div class="form_field__d31bq" >
                                        <span>CoinGecko</span>
                                        <textarea placeholder="Link" style={{ height: "52px" }} value={template2coingecko} onChange={(e) => setTemplate2coingecko(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                          <label>CoinGecko Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {coingeckoimage.length > 0 ?
                                              <div>
                                                {coingeckoimage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleInstagramImage(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleInstagramImage(0) }} style={{ height: 30, width: 40 }} src={coingeckoimage.length > 0 ? coingeckoimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                      </div>



                                      <div class='mt-3'>
                                        <label>Background Image</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(1) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(1) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                          <br/>
                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(0) }}>
                                          <label> Background Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div  style={{ height: 30, width: 40,  backgroundColor: memeherobgcolor.length==0 ? '' :  memeherobgcolor,position:'relative' }}  className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />

                                        <br />


                                      </div>
                                    </div>

                                  </div>


                                </div>
                                


                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}

                    {selectMemeCoinTemplates == 4 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>

                                      <div class="form_field__d31bq" >
                                        <span>Telegram</span>
                                        <textarea placeholder="Telegram" style={{ height: "32px" }} value={template3telegramlink} onChange={(e) => setTemplate3telegramlink(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br></br>
                                      <div class="form_field__d31bq" >
                                        <span>Twitter</span>
                                        <textarea placeholder="Twitter" style={{ height: "32px" }} value={template3twitterlink} onChange={(e) => setTemplate3twitterlink(e.target.value)}  >
                                        </textarea>
                                      </div>
                                      <br />
                                      <div class="form_field__d31bq" >
                                        <span>Uniswap</span>
                                        <textarea placeholder="Uniswap" style={{ height: "32px" }} value={template3uniswaplink} onChange={(e) => setTemplate3uniswaplink(e.target.value)}  >
                                        </textarea>
                                      </div>
                                      <div class='mt-3'>
                                        <label>Images</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(1) }}>
                                          <label>Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label> Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinAminitionImage.length > 0 ?
                                              <div>
                                                {memecoinAminitionImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleAminationImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleAminationImage(0)} style={{ height: 30, width: 40 }} src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}

                        

                    {selectMemeCoinTemplates == 6?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "32px" }} value={t4caption} onChange={(e) => setT4caption(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Contract Address</span>
                                        <textarea placeholder="Value" style={{ height: "fit-content" }} value={t4memeaddress} onChange={(e) => setT4memeadress(e.target.value)} >
                                        </textarea>
                                      </div>
                                      
                                      <div class="form_field__d31bq" >
                                        <span>Meme Symbol</span>
                                        <textarea placeholder="Symbol" style={{ height: "20px" }} value={memecoinsymbol} onChange={(e) => setMemecoinSymbol(e.target.value)} >
                                        </textarea>
                                        
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>Buy</span>
                                        <textarea placeholder="Value" style={{ height: "20px" }} value={t4mainbutton1} onChange={(e) => setT4mainbutton1(e.target.value)} >
                                        </textarea>
                                        <br></br>
                                        <textarea placeholder="Link" style={{ height: "20px" }} value={template2cmc} onChange={(e) => setTemplate2cmc(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq" >
                                        <span>X</span>
                                        <textarea placeholder="Value" style={{ height: "20px" }} value={t4mainbutton2} onChange={(e) => setT4mainbutton2(e.target.value)} >
                                        </textarea>
                                        <br></br>
                                        <textarea placeholder="Link" style={{ height: "20px" }} value={memetwitterlink} onChange={(e) => setMemetwitterlink(e.target.value)} >
                                        </textarea>
                                      </div>
                                       <br/>
                                      <div class='mt-3'>
                                        <label>Background Images</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(0) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br/>

                                        <span >Background Color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(1) }}>
                                          <label> Background Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />

                                        <div className="media_preview__okAyH">
                                          <label>Meme Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinAminitionImage.length > 0 ?
                                              <div>
                                                {memecoinAminitionImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleAminationImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleAminationImage(0)} style={{ height: 30, width: 40 }} src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                       
                                        
                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}
                         

                         {selectMemeCoinTemplates == 5 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq" >
                                        <span>Caption</span>
                                        <textarea placeholder="Caption" style={{ height: "120px" }} value={memecoinAbout} onChange={(e) => setMemecoinAbout(e.target.value)} >
                                        </textarea>
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Twitter</span>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memetwitterlink} onChange={(e) => setMemetwitterlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                          <label>Twitter Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {twitterimage.length > 0 ?
                                              <div>
                                                {twitterimage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handletwitterimage(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handletwitterimage(0) }} style={{ height: 30, width: 40 }} src={twitterimage.length > 0 ? twitterimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Telegram</span>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memetelegramlink} onChange={(e) => setMemetelegramlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                          <label>Telegram Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {telegraminage.length > 0 ?
                                              <div>
                                                {telegraminage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handletelegramImage(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handletelegramImage(0) }} style={{ height: 30, width: 40 }} src={telegraminage.length > 0 ? telegraminage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <br/>
                                      <div class="form_field__d31bq" >
                                        <span>Instagram</span>
                                        <textarea placeholder="Link" style={{ height: "fit-content" }} value={memeinstagramlink} onChange={(e) => setMemeinstagramlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                          <label>Instagram Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {instagramimage.length > 0 ?
                                              <div>
                                                {instagramimage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleInstagramImage(0) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleInstagramImage(0) }} style={{ height: 30, width: 40 }} src={instagramimage.length > 0 ? instagramimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      
                                      <div class='mt-3'>
                                        <br/>
                                        <label>Background Image</label>
                                        <div className="media_preview__okAyH">
                                          <label>Background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinaboutbgImage.length > 0 ?
                                              <div>
                                                {memecoinaboutbgImage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(1) }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleMainBackgroundImage(0); setMemeherobackground(1) }} style={{ height: 30, width: 40 }} src={memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                        
                                           <br/>
                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemeherobackground(0) }}>
                                          <label> Background Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memeherobgcolor.length == 0 ? '' : memeherobgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memeherobgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                        <br />
                                       
                                        <div className="media_preview__okAyH">
                                          <label>Meme Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinAminitionImage.length > 0 ?
                                              <div>
                                                {memecoinAminitionImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleAminationImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleAminationImage(0)} style={{ height: 30, width: 40 }} src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br/>
                                        {/* <div className="media_preview__okAyH">
                                          <label>Buy Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {buy.length > 0 ?
                                              <div>
                                                {buy.map((image, index) => (
                                                  <img
                                                    onClick={() => handleBuyImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleBuyImage(0)} style={{ height: 30, width: 40 }} src={buy.length > 0 ? buy : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br /> */}
                                        
                                      </div>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}



                  </div>
                  : <MemeMaindesing />}

              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme /></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <Memecoin />
          </div>
        </div>
      </div>




    </div>
  )
};

export default MemeHomeEdit