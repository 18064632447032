


import React, { useContext, useEffect, useRef, useState } from 'react'
import Main from '../Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCircleQuestion, faEllipsisV, faLightbulb, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import WebPages from '../Webpages';
import MyContext from '../../context/MyContext';
import MainNavBar from '../MainNavBar';
import HeroDesign from './HeroDesign';
import ContentDesign from '../ContentDesign';
import { SketchPicker } from 'react-color';
import Logo from '../../assets/images/FaviconNew.png'
import Theme from '../Theme';
import MemeNavbarEdit from '../MemeCoin/Save&edit/MemeNavbarEdit';
import axios from 'axios';
import { useAccount } from 'wagmi'

const Heroedit = () => {
  const { navbtn1, setNavbtn1,
    navbtn2, setNavbtn2,
    headerdivtextcolor, setHeaderDivTextColor,
    headerdivtext, setHeaderDivText,
    headerdivbgcolor, setHeaderdivbgcolor,
    navbartitle, setNavbartitle,
    headerdiv, setHeaderdiv,
    mainnavbarbutton, contentdesing,
    Section1, menu,
    swtmenu1, title,
    setTitle, intro,
    setIntro, welcometilte,
    setwelcometitle, navbarmenu,
    setnavbarmenu, Navbarstring,
    navremovebtn1, setNavremovebtn1,
    navremovebtn2, setNavremovebtn2,
    navbuttonincraese, setNavbuttonincrease,
    navbarremove, setNavbarremove,
    setAddnavmenu, addnavmenu,
    navAddbutton, setNavaddbutton,
    addbuttonapprovel, setAddbuttonapprovel,
    newlogo, setNewlogo,memecoin,
    headernotice, setheadernotice,
    colorheaderDiv, setcolorheaderdiv ,section1, uniqueID, selectedLi} = useContext(MyContext)

  const [heroedit, setheroedit] = useState(true);
  const [colortext, setcolortext] = useState(false);

  const contentEditableRef = useRef(null);
  const [caretPosition, setCaretPosition] = useState(0);
  const [containerCount, setContainerCount] = useState(1);
  const [navbuttoncount, setNavbuttoncount] = useState(1);

  // API to store header content data. for testing purpose using use effect
  const { address } = useAccount()


  useEffect(() => {
    
    const updateHeaderContentData = async () => {
      try {
        const response = await axios.post('https://trendifyweb.ai/pyapi/updateHeaderContentData', {
          params: {
            address: address,
            siteid: uniqueID,
            headerdiv: headerdiv,
            headerdivtext: headerdivtext,
            headerdivtextcolor: headerdivtextcolor,
            headerdivbgcolor: headerdivbgcolor,
            newlogo: newlogo,
            navbartitle: navbartitle,
            navbarmenu: navbarmenu,
            navbtn1: navbtn1,
            navbtn2: navbtn2,
            navremovebtn1: navremovebtn1,
            navremovebtn2: navremovebtn2,
            navbarremove: navbarremove,
            navAddbutton: navAddbutton,
            addbuttonapprovel: addbuttonapprovel,
          }
        })
        console.log('headerapiresponse', response)
      } catch (error) {
        console.error('Error:', error);
      }
    }

    updateHeaderContentData()
  }, [headerdivbgcolor, selectedLi])

  const handelheadernotice = () => {
    setheadernotice(!headernotice);

  };

  const handelheaderdiv = () => {
    setHeaderdiv(!headerdiv);

  };
  console.log('headernotice', headernotice)

  const handelbgcolor = () => {
    setcolorheaderdiv(!colorheaderDiv)
  }
  const handeltextcolor = () => {
    setcolortext(!colortext);
  }
  console.log('heroedit', heroedit)
  console.log('color24', colorheaderDiv)


  const handelnavbaritem = (e, index) => {
    const updatedMenu = [...navbarmenu];
    updatedMenu[index] = e.target.value;
    setnavbarmenu(updatedMenu);
  };
  console.log(menu);
  console.log('Section1', Section1)


  const handelchangecolor = (titlecolor) => {
    setHeaderdivbgcolor(titlecolor.hex)
    console.log('color24', [titlecolor.hex])

  };

  const handeldivtextcolor = (Textcolor) => {
    setHeaderDivTextColor(Textcolor.hex)
  }

  const handelheadedivtext = (e) => {
    const content = e.target.innerText;
    setHeaderDivText(content);
  }
  const handelnavtitletext = (e) => {
    setNavbartitle(e.target.value)
  }

  console.log('headerdivtext', headerdivtext);
  // console.log('intro', intro, typeof intro);

  const Handelnavbuttun1 = (e) => {
    setNavbtn1(e.target.value)
  };

  const Handelnabuttun2 = (e) => {
    setNavbtn2(e.target.value)
  };
  const handelnavremovebtn1 = () => {
    setNavremovebtn1(true);

  }
  const handelnavremovebtn2 = () => {
    setNavremovebtn2(true);
  };

  const Handelnavbuutoncount = () => {
    if (navbuttoncount < 2) {
      setNavbuttoncount(navbuttoncount + 1)
    } else {
      setNavbuttoncount(0)
    }

  }

  const handelnavaddbtn = () => {
    switch (navbuttoncount) {
      case 1:
        setNavremovebtn1(false);
        setNavbtn1('');
        break;
      case 2:
        setNavremovebtn2(false);
        setNavbtn2('');
        break;
      default:
        console.log('Error')
    }

  }

  const handleNavMenuRemove = (indexToRemove) => {
    setnavbarmenu(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });

  };






  const handleAddNavmenu = (navbarmenu) => {
    setnavbarmenu(prevMenu => [...prevMenu, navbarmenu]);
  };
  const handleAddNavButton = (newItem) => {
    setNavaddbutton(prevMenu => [...prevMenu, newItem]);
    setAddbuttonapprovel(true)
  };
  const handelnavAddbuttonitem = (e, index) => {
    const updatedMenu = [...navAddbutton];
    updatedMenu[index] = e.target.value;
    setNavaddbutton(updatedMenu);
  };
  const handleNavAddButtonRemov = (indexToRemove) => {
    setNavaddbutton(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });

  };

  console.log(navremovebtn1, navremovebtn2, navAddbutton.length, 'Remove')

  const handleNewlogo = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...newlogo];
        updatedTrustLogo[index] = event.target.result;
        setNewlogo(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };


  console.log('newlogo', newlogo, newlogo.length)
  useEffect(() => {
    function handleClickOutside(event) {
      const target = event.target;
      const divToExclude = document.getElementById("sketchPickerDiv");

      // Check if the click occurred outside of the specific div
      if (divToExclude && !divToExclude.contains(target)) {
        setcolortext(false);
        setcolorheaderdiv(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colortext, colorheaderDiv]);

  console.log(colortext, colortext, colorheaderDiv);
  console.log('Section1',colortext,colorheaderDiv,)

  return (
    // 
    <div>
     {memecoin=='true'?<MemeNavbarEdit/>:
      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?

              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Header" />
                {contentdesing == false ?
                  <div className='editing_content__RXzxk'>
                    <div className='form_form__Sku4X'>
                      <div className='expandable_expandable__3BGe7 expandable_padContent__JcqHj expandable_nested__UjqMd'>
                        <div className='expandable_title__Pcsbd'>
                          Show Header Notice

                          <div class="form-check form-switch">
                            <input onClick={() => { handelheadernotice(); handelheaderdiv() }} checked={headernotice ? true : false} class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" ></input>
                            <label class="form-check-label" for="flexSwitchCheckChecked"></label>
                          </div>
                        </div>

                      </div>
                      {headernotice == true ?
                        <div className='expandable_content__7Ht4T'>
                          <div className='form_form__Sku4X'>
                            <div class="form_field__d31bq"><div class="">

                              <div onInput={handelheadedivtext} contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0">

                                <br class="ProseMirror-trailingBreak" />
                              </div>


                            </div>
                            </div>
                            <div class="form_field__d31bq form_inline__6vby7" onClick={() => handeltextcolor()}>
                              <label class="form_label__27psn" onClick={() => handeltextcolor()}>Text Color</label>
                              <div class="colors_swatch__gosbA">
                                <div class="colors_swatchColor__WaTUr" style={{ backgroundColor: `${headerdivtextcolor}` }}>
                                  {colortext == true ? <div id="sketchPickerDiv" style={{ position: 'fixed', left: 0, zIndex: 1000 }}> <SketchPicker color={headerdivtextcolor} onChangeComplete={handeldivtextcolor} /></div> : null}
                                </div>
                              </div>
                            </div>
                            <div class="form_field__d31bq form_inline__6vby7"  onClick={() => handelbgcolor()}>
                              <label class="form_label__27psn" onClick={() => handelbgcolor()} >Background Color</label>
                              <div class="colors_swatch__gosbA">
                                <div class="colors_swatchColor__WaTUr" style={{ backgroundColor: `${headerdivbgcolor}` }}>

                                  {colorheaderDiv == true ? <div id="sketchPickerDiv" style={{ position: 'fixed', left: 0, zIndex: 1000 }}> <SketchPicker color={headerdivbgcolor} onChangeComplete={handelchangecolor} /></div> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> : null}
                      <div class="form_divider__fd+a0"></div>
                      <div className='form_sortable__FLtVI'>
                        <div className='form_listItem__puOqW'>
                          <div className='form_headerRemove__mJ7no'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                              <div className='form_field__d31bq'>
                                <div className='media_preview__okAyH'>
                                  <label>Logo Image</label>
                                  <div class="media_value__+4KKG" >
                                    {newlogo.length > 0 ?
                                      <div>
                                        {newlogo.map((image, index) => (
                                          // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                          <img
                                            onClick={() => handleNewlogo(0)}
                                            key={index}
                                            style={{ height: 30, width: 40 }}
                                            src={image}
                                            alt={`Image ${index}`}
                                            className="media_image__kN9DM"
                                          />
                                        ))}</div> :
                                      <img onClick={() => handleNewlogo(0)} src={Logo} alt="Library File" class="media_image__kN9DM" />
                                    } </div>
                                </div>
                              </div>
                              <div class="form_field__d31bq"><textarea placeholder="Logo Text" style={{ height: 32 }} value={navbartitle} onChange={(e) => handelnavtitletext(e)}></textarea></div>
                              <div class="form_field__d31bq"><input type="url"  /></div>
                              <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label class="form_label__27psn">Links</label>
                    <div className='form_sortable__FLtVI'>
                      {Array.isArray(navbarmenu) && navbarmenu.map((item, index) => (
                        <div>
                          {navbarremove == false ?
                            <div className='form_listItem__puOqW' key={index} >
                              <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                              <div className='form_headerRemove__mJ7no' onClick={(e) => handleNavMenuRemove(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                              </div>

                              <div className="form_form__Sku4X">
                                <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                  <div className='form_field__d31bq'>
                                    <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} onChange={(e) => handelnavbaritem(e, index)} value={navbarmenu[index]}>{item}</textarea>
                                  </div>
                                  <div className='form_field__d31bq'>
                                    <input type="url" className='w-100'  />
                                  </div>
                                  <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                </div>
                                <div className='form_emptyList__KNV3N'>
                                  <div>Your list is empty</div>
                                  <button class="button1 button--small">Add Item</button>
                                </div>
                              </div>
                            </div> : <div className='form_emptyList__KNV3N'>
                              <div>Your list is empty</div>
                              <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>

                            </div>}
                        </div>))}


                      <div style={{ position: 'relative', left: 280, marginTop: 30 }}>
                        <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>
                      </div>

                    </div>






                    <label class="form_label__27psn">Buttons</label>




                    {navremovebtn1 == false ?
                      <div className='form_sortable__FLtVI' >
                        <div className='form_listItem__puOqW'>
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                          <div className='form_headerRemove__mJ7no' onClick={() => handelnavremovebtn1()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                              <div className='form_field__d31bq'>
                                <textarea style={{ height: 32, padding: 5 }} onChange={(e) => Handelnavbuttun1(e)} value={navbtn1}>{navbtn1}</textarea>
                              </div>
                              <div className='form_field__d31bq'>
                                <input type="url"  />
                              </div>
                              <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            </div>

                          </div>


                        </div>
                      </div> : null}


                    {navremovebtn2 == false ?
                      <div className='form_sortable__FLtVI' >
                        <div className='form_listItem__puOqW'>
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                          <div className='form_headerRemove__mJ7no' onClick={() => handelnavremovebtn2()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                              <div className='form_field__d31bq'>
                                <textarea style={{ height: 32, padding: 5, }} onChange={(e) => Handelnabuttun2(e)} >{navbtn2}</textarea>
                              </div>
                              <div className='form_field__d31bq'>
                                <input type="url"  />
                              </div>
                              <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            </div>

                          </div>


                        </div>
                      </div> : null}

                    {navAddbutton.map((button, index) => (
                      <div className='form_sortable__FLtVI' key={index} style={{ display: addbuttonapprovel == false ? 'none' : '' }}>
                        <div className='form_listItem__puOqW'>
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                          <div className='form_headerRemove__mJ7no' onClick={() => handleNavAddButtonRemov()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                              <div className='form_field__d31bq'>
                                <textarea style={{ height: 32, padding: 5 }} onChange={(e) => handelnavAddbuttonitem(e, index)} value={navAddbutton[index]}>{button}</textarea>
                              </div>
                              <div className='form_field__d31bq'>
                                <input type="url"  />
                              </div>
                              <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            </div>

                          </div>


                        </div>
                      </div>
                    ))}



                    {((navAddbutton.length == 1) && ((navremovebtn1 == false) && (navremovebtn2 == false))) ?


                      <div style={{ position: 'relative', left: 260 }}>

                        <button class="button1 button--small" onClick={() => handleAddNavButton()}>Add Item </button>
                      </div>



                      : <div >
                        {((navAddbutton.length == 1) && (navremovebtn1 == true) && (navremovebtn2 == true)) ?
                          <button class="button1 button--small" style={{ position: 'relative', left: 260 }} onClick={() => handleAddNavButton()}>Add Item </button> :
                          <div>
                            {((navAddbutton.length == 0) && (navremovebtn1 == true) && (navremovebtn2 == true)) ?

                              // <button class="button1 button--small" onClick={() => handleAddNavButton()}>Add Item 4</button>
                              <div className='form_emptyList__KNV3N'>
                                <div>Your list is empty</div>
                                <button class="button1 button--small" onClick={() => handleAddNavButton()}>Add Item</button>
                              </div>
                              : <button class="button1 button--small" style={{ position: 'relative', left: 260 }} onClick={() => handleAddNavButton()}>Add Item</button>}
                          </div>}
                      </div>}


                  </div> : <HeroDesign />}

              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme/></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <WebPages />
          </div>
        </div>
      </div>
}

    </div>
  )
};

export default Heroedit;