// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import MyProvider from './context/MyProvider';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//        <MyProvider>
//        <App />
//     </MyProvider>  

//   </React.StrictMode> 
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();



import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WagmiProvider } from 'wagmi';
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import {
  mainnet,
  arbitrum,
  optimism,
  polygon,
  bsc,
  avalanche,
  celo,
  base,
  bscTestnet,
  baseSepolia,
  fantom,
} from "wagmi/chains";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import MyProvider from './context/MyProvider';
import { PublishProvider } from './context/publishSiteContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';


// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '58a22d2bc1c793fc31c117ad9ceba8d9'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [
  mainnet,
  arbitrum,
  optimism,
  polygon,
  bsc,
  avalanche,
  celo,
  base,
  bscTestnet,
  baseSepolia,
  fantom,
];
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true // Optional - false as default
});






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <MyProvider>
          <PublishProvider>
            <HelmetProvider>
              <App />
            </HelmetProvider>

          </PublishProvider>
        </MyProvider>
      </QueryClientProvider>
    </WagmiProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

