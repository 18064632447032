import React, { useContext, useEffect, useState } from 'react'
import MyContext from '../context/MyContext';
import { HeaderNavtextfont } from './TextFont/Font';

const DesignText = (props) => {
    const [activeTab, setActiveTab] = useState('Body');

    const { introtextfont, setIntrotextfont,
        section1, section2,
        section3, section4,
        section5, section6,
        section7, buttontext,
        introinfotextfont, setintroinfotextfont,
        trusttitletextfont, setTrusttitletextfont,
        trusttextfont, setTrusttextfont,
        serviceBodytextfont, setserviceBodytextfont,
        servicetitletextfont, setServicestitletextfont,
        statsbodytextfont, setStatsbodytextfont,
        statstitletextfont, setStatstitletextfont,
        faqtitletextfont, setFaqtitletextfont,
        footerbodytextfont, setFooterbodytextfont, setHeadertextfont,
        faqbodytextfont, setFaqbodytextfont,
        mainnavbarbutton, button1font, setButton1font,
        setMemecoinanvfont,memecoin,setMemecoinaboutfont,
        memecoinsection,setMemecoinvisionfont,
        setMemecointokenfont,setMemecoinstepfont,
        setMemefootertextfont,memenavfontsize,setMemenavfontsize,
      setMemeMainfontsize,setMemeaboutfontsize,
      setMemetokenfontSize,setMemestepfontSize,
      setMemefooterfontSize,
    } = useContext(MyContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [clearbutton, setclearbutton] = useState(false);
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const filteredFonts = HeaderNavtextfont.filter((font) =>
        font.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [textfontfamily, setTextfontfamily] = useState('');

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffsetX(e.clientX - e.target.getBoundingClientRect().left);
        setOffsetY(e.clientY - e.target.getBoundingClientRect().top);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            e.preventDefault();
            const popup = document.querySelector('.popup');
            popup.style.left = e.clientX - offsetX + 'px';
            popup.style.top = e.clientY - offsetY + 'px';
        }
    };




    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    console.log('Section', section1, section2, section3, section4, section5, section6, section7, activeTab, 'text', buttontext, button1font,);



    const changesection2textfont = (font) => {
        setIntrotextfont(font)
    };
    const changesection2introinfofont = (font) => {
        setintroinfotextfont(font)
    };
    const changesection3textfont = (font) => {
        setTrusttextfont(font)
    };
    const changesection3tittlefont = (font) => {
        setTrusttitletextfont(font)
    };
    const changesection4title = (font) => {
        setServicestitletextfont(font)
    };
    const changesection4body = (font) => {
        setserviceBodytextfont(font)
    };
    const changesection5title = (font) => {
        setStatstitletextfont(font)
    };
    const changesection5body = (font) => {
        setStatsbodytextfont(font)
    };
    const changesection6title = (font) => {
        setFaqtitletextfont(font)
    };
    const changesection6body = (font) => {
        setFaqbodytextfont(font)
    };
    const changesection7body = (font) => {
        setFooterbodytextfont(font)
    };

    const HandelChangeMemecoinNavfont=(font)=>{
        setMemecoinanvfont(font)
        // setMemecoinanvfont(textfontfamily);

    };

    const HandelMemeaboutfont=(font)=>{
        setMemecoinaboutfont(font);
    };

    const HandelMemecoinvisionfont=(font)=>{
        setMemecoinvisionfont(font);
    };

    const Handelmemetokenfont=(font)=>{
        setMemecointokenfont(font);
    };

    const Handelmemestepfont=(font)=>{
        setMemecoinstepfont(font);
    }
    const HandelMemefootertextfont=(font)=>{
             setMemefootertextfont(font);
    }


    const HandelContentPrimarytextfont = (font) => {
        setIntrotextfont(font)
        setTrusttextfont(font)
        setServicestitletextfont(font)
        setStatstitletextfont(font)
        setFaqtitletextfont(font)
        setHeadertextfont(font);
        setMemecoinanvfont(font);
        setMemecoinaboutfont(font);
        setMemecoinvisionfont(font);
        setMemecointokenfont(font);
        setMemecoinstepfont(font);
        setMemefootertextfont(font);
    }


    
    const HandelContentSecondarytextfont = (font) => {
        setintroinfotextfont(font)
        setserviceBodytextfont(font)
        setStatsbodytextfont(font)
        setFaqbodytextfont(font)
        setTrusttitletextfont(font)
        setFaqbodytextfont(font)
        setFooterbodytextfont(font)
        setMemecoinanvfont(font);
        setMemecoinaboutfont(font);
        setMemecoinvisionfont(font);
        setMemecointokenfont(font);
        setMemecoinstepfont(font);
        setMemefootertextfont(font);


    };
    const HandelIntrobuttontext = (font) => {
        setButton1font(font);
    };

    const ChangeTextFontfamily = (font) => {
        if (section2 == true) {
            if (activeTab == 'Title') {
                changesection2textfont(font);
            }
            if (activeTab == 'Body') {
                changesection2introinfofont(font);
            }
        };
        if (section3 == true) {
            if (activeTab == 'Title') {
                changesection3textfont(font);
            }
            if (activeTab == 'Body') {
                changesection3tittlefont(font);
            }
        };
        if (section4 == true) {
            if (activeTab == 'Title') {
                changesection4title(font);
            }
            if (activeTab == 'Body') {
                changesection4body(font);
            }
        }
        if (section5 == true) {
            if (activeTab == 'Title') {
                changesection5title(font);
            }
            if (activeTab == 'Body') {
                changesection5body(font);
            }
        };
        if (section6 == true) {
            if (activeTab == 'Title') {
                changesection6title(font);
            }
            if (activeTab == 'Body') {
                changesection6body(font);
            }
        };
        if (section7 == true) {
            if (activeTab == 'Title') {

            }
            if (activeTab == 'Body') {
                changesection7body(font);
            };
        };
           if(memecoin=='true'){
             if( memecoinsection==1){
             HandelChangeMemecoinNavfont(font);
             
               
             };
             if(memecoinsection==2){
                 HandelMemeaboutfont(font);
             };
             if(memecoinsection==3){
                HandelMemecoinvisionfont(font);
             };
             if(memecoinsection==4){
                Handelmemetokenfont(font);
             };
             if(memecoinsection==5){
                Handelmemestepfont(font);
             };
             if(memecoinsection==6){
                HandelMemefootertextfont(font);
             }
           };
           
        if ((mainnavbarbutton == true) && (buttontext == null)) {
            if (activeTab == 'Title') {
                HandelContentPrimarytextfont(font);
            };
            if (activeTab == 'Body') {
                HandelContentSecondarytextfont(font)
            }
        };
        if (buttontext == 1) {

            HandelIntrobuttontext(font);


        };
        setclearbutton(true);
    };

    const HandeltextFontSize=(e)=>{
            // alert(e.target.value)
        if(memecoin=='true'){
            if( memecoinsection==1){
           setMemenavfontsize(e.target.value)
            
              
            };
            if(memecoinsection==2){
                setMemeMainfontsize(e.target.value);
            };
            if(memecoinsection==3){
               setMemeaboutfontsize(e.target.value);
            };
            if(memecoinsection==4){
               setMemetokenfontSize(e.target.value);
            };
            if(memecoinsection==5){
               setMemestepfontSize(e.target.value);
            };
            if(memecoinsection==6){
               setMemefooterfontSize(e.target.value);
            }
          };

    }
    const HandelclearAllfont = () => {

        if (section2 == true) {
            if (activeTab == 'Title') {
                setIntrotextfont('');
            }
            if (activeTab == 'Body') {
                setintroinfotextfont('');
            }
        };
        if (section3 == true) {
            if (activeTab == 'Title') {
                setTrusttextfont('');
            }
            if (activeTab == 'Body') {
                setTrusttitletextfont('');
            }
        };
        if (section4 == true) {
            if (activeTab == 'Title') {
                setServicestitletextfont('')
            }
            if (activeTab == 'Body') {
                setserviceBodytextfont('');
            }
        };
        if (section5 == true) {
            if (activeTab == 'Title') {
                setStatstitletextfont('')
            }
            if (activeTab == 'Body') {
                setStatsbodytextfont('');
            }
        };
        if (section6 == true) {
            if (activeTab == 'Title') {
                setFaqtitletextfont('')
            }
            if (activeTab == 'Body') {
                setFaqbodytextfont('');
            }
        };
        if (section7 == true) {
            if (activeTab == 'Title') {
                setFooterbodytextfont('')
            }
            if (activeTab == 'Body') {
                setFooterbodytextfont('');
            }
        };

    }
    useEffect(() => {
        setclearbutton(false)
    }, []);
    const HandelChangetextfont = (e) => {
        setTextfontfamily(e.target.value)
        setintroinfotextfont(e.target.value)
        setserviceBodytextfont(e.target.value)
        setStatsbodytextfont(e.target.value)
        setFaqbodytextfont(e.target.value)
        setTrusttitletextfont(e.target.value)
        setFaqbodytextfont(e.target.value)
        setFooterbodytextfont(e.target.value)
    }
    console.log('clear', memenavfontsize, textfontfamily)
    return (
        <>
            <div className="popup" style={{ position: 'fixed', left: '40%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, background: '#fff', borderRadius: '7px', boxShadow: '0 4px 30px #0000001a, 0 4px 10px #00000012', cursor: isDragging ? 'grabbing' : 'context-menu', }} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>

                <div className='overlayHeader' onMouseDown={handleMouseDown} style={{ cursor: 'drag' }}>
                    <span class="">Text-Font</span>
                    {clearbutton && mainnavbarbutton == false && (<button class="overlay_clear__EdOFC" onClick={HandelclearAllfont} style={{ display: memecoin == 'true' ? 'none' : 'block' }}>Clear All</button>)}
                    <button onClick={props.closePopup} class="button button--small button--clear overlay_overlayClose__GxQ0k"><svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg></button>
                </div>
                <div className='font_colors' style={{ display: memecoin == 'true' ? 'none' : 'block' }}>
                    <div className='font_color__sub'>
                        <label>Primary</label>
                        <div class="swatch_swatch__7xFNG"><div class="swatch_swatchColor__ynsJn"></div></div>
                    </div>
                    <div className='font_color__sub'>
                        <label>Secondary</label>
                        <div class="swatch_swatch__7xFNG"><div class="swatch_swatchColor__ynsJn"></div></div>
                    </div>
                </div>
                <div className="fonts_typeSelect" style={{ display: memecoin == 'true' ? 'none' : 'block' }}>
                    <ul className="styles_select">
                        <li className="styles_marker" style={{ width: '132px', left: activeTab === 'Title' ? '0' : '135px' }}></li>
                        <li className={`styles_option__f+yN9 ${activeTab === 'Title' ? 'styles_active__8RnsA' : ''}`} onClick={() => handleTabClick('Title')}>
                            <button data-tooltip="Title">Title</button>
                        </li>
                        <li className={`styles_option__f+yN9 ${activeTab === 'Body' ? 'styles_active__8RnsA' : ''}`} onClick={() => handleTabClick('Body')}>
                            <button data-tooltip="Body">Body</button>
                        </li>
                    </ul>
                </div>
                <div className='d-flex flex-column'>
                    <input value={searchQuery} onChange={handleSearchChange} type="text" class="listPicker_search__tqIWr" placeholder="Type to search..." />
                    <div className='listPicker_list__k2aIP' style={{ width: '300px', height: '200px', overflow: 'auto', willChange: 'transform', direction: 'ltr' }}>
                        <div style={{ width: '100%', height: '200px' }}>
                            <div className='listPicker_row__ISL1-' style={{ position: 'absolute', left: '0', top: '0', height: '32px' }}>
                                <span><span>System Font</span></span>
                            </div>
                            <div class="listPicker_row__ISL1- tab-content" style={{ display: activeTab === 'Title' ? 'block' : 'none', position: 'absolute', left: '0', top: '32px', height: '32px' }}>
                                {filteredFonts.map((font, index) => (
                                    <ul style={{ position: 'relative', left: -30 }}>
                                        <li key={index} style={{ listStyle: 'none' }} onClick={() => ChangeTextFontfamily(font)}>
                                            {font}
                                        </li>
                                    </ul>
                                ))}


                                {/* <span>
                                    <span>DM Sans</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16" viewBox="0 0 24 24" width="16" fill="#e58f00"><g><path d="M0,0h24v24H0V0z" fill="none"></path><path d="M0,0h24v24H0V0z" fill="none"></path></g><g><path d="M12,17.27l4.15,2.51c0.76,0.46,1.69-0.22,1.49-1.08l-1.1-4.72l3.67-3.18c0.67-0.58,0.31-1.68-0.57-1.75l-4.83-0.41 l-1.89-4.46c-0.34-0.81-1.5-0.81-1.84,0L9.19,8.63L4.36,9.04c-0.88,0.07-1.24,1.17-0.57,1.75l3.67,3.18l-1.1,4.72 c-0.2,0.86,0.73,1.54,1.49,1.08L12,17.27z"></path></g></svg>
                                    </span> */}
                            </div>

                            <div class="listPicker_row__ISL1- tab-content" style={{ display: activeTab === 'Body' ? 'block' : 'none', position: 'absolute', left: '0', top: '32px', height: '32px' }}>
                                {filteredFonts.map((font, index) => (
                                    <ul style={{ position: 'relative', left: -30 }}>
                                        <li key={index} style={{ listStyle: 'none' }} onClick={() => ChangeTextFontfamily(font)}>
                                            {font}
                                        </li>
                                    </ul>
                                ))}


                                {/* <span>
                                    <span>SM Sans</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16" viewBox="0 0 24 24" width="16" fill="#e58f00"><g><path d="M0,0h24v24H0V0z" fill="none"></path><path d="M0,0h24v24H0V0z" fill="none"></path></g><g><path d="M12,17.27l4.15,2.51c0.76,0.46,1.69-0.22,1.49-1.08l-1.1-4.72l3.67-3.18c0.67-0.58,0.31-1.68-0.57-1.75l-4.83-0.41 l-1.89-4.46c-0.34-0.81-1.5-0.81-1.84,0L9.19,8.63L4.36,9.04c-0.88,0.07-1.24,1.17-0.57,1.75l3.67,3.18l-1.1,4.72 c-0.2,0.86,0.73,1.54,1.49,1.08L12,17.27z"></path></g></svg>
                                    </span> */}

                            </div>
                        </div>
                    </div>
                </div>
                <div className='font_settings' style={{ display: memecoin == 'true' ? 'none' : 'block' }}>
                    <li><label>Size</label><input type="number"  onChange={(e)=>HandeltextFontSize(e)}  /></li>
                    <li><label>Weight</label>
                        <select disabled >

                        </select></li>
                    <li><label>Size</label>
                        <select value={textfontfamily} onChange={(e) => HandelChangetextfont(e)} >
                            <option value='normal' >Normal</option>
                            <option value="Papyrus">Italic</option>
                        </select></li>
                </div>
            </div>


        </>
    )
}

export default DesignText