




import React, { useContext, useEffect, useState } from "react";
import Main from '../../Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import WebPages from '../../Webpages';
import MyContext from "../../../context/MyContext";
import MainNavBar from "../../MainNavBar";
import FooterDesing from "./Footerdesing";
import ContentDesign from "../../ContentDesign";
import Theme from "../../Theme";
import { useAccount } from "wagmi";
import axios from "axios";


const FooterEdit = () => {
  const { contentdesing, mainnavbarbutton,
    footerfeature, setfooterfeature,
    footercompany, setfootercompany,
    footerlegal, setfooterlegal,
    footeryear, setFooteryear,
    removeSocialIcon1, setRemoveSocialIcon1,
    removeSocialIcon2, setRemoveSocialIcon2,
    removeSocialIcon3, setRemoveSocialIcon3,
    removeSocialIcon4, setRemoveSocialIcon4,
    newfootersocialIcon, setNewfootersocialIcon,
    footerlegalhead, setFooterleagalhead,
    footerCompanyhead, setFooterCompanyhead,
    footerfeaturehead, setFooterfeaturehead,
    footerLinkgroups, setfooterLinkgroups,
    newfooterhead, setNewfooterhead,
    newfooteritem, setNewfooteritem, 
  uniqueID} = useContext(MyContext)
  const [footeredit, setfooteredit] = useState(true);
  const [addfootersocial, setAddfootersocial] = useState(1);
  const [linkcount, setlinkcount] = useState(0);


  // API to store footer content data. For testig purpose using use effect
  const { address } = useAccount()

  useEffect(() => {

    // TODO: contentdesing, mainnavbarbutton, newfootersocialIcon, footerLinkgroups, newfooterhead, newfooteritem

    const updateFooterContentData = async () => {
      const response = await axios.post('https://trendifyweb.ai/pyapi/updateFooterContentData', {
        params: {
          address: address,
          siteid: uniqueID,
          footeryear: footeryear,
          removeSocialIcon1: removeSocialIcon1,
          removeSocialIcon2: removeSocialIcon2,
          removeSocialIcon3: removeSocialIcon3,
          removeSocialIcon4: removeSocialIcon4,
          footerfeaturehead: footerfeaturehead,
          footerfeature: footerfeature,
          footerCompanyhead: footerCompanyhead,
          footercompany: footercompany,
          footerlegalhead: footerlegalhead,
          footerlegal: footerlegal,
        }
      });
      console.log(response.data);
    };
    updateFooterContentData();
  }, [contentdesing])



  const handelfooteredit = () => {
    setfooteredit(true);
  };
  const handelfooterdesing = () => {
    setfooteredit(false);
  };
  const handelnavbaritem = (e, index) => {
    const updatedMenu = [...footerfeature]; // Create a copy of the array
    updatedMenu[index] = e.target.value; // Update the value at the specified index
    setfooterfeature(updatedMenu); // Update the state with the new array
  };
  const handefootercompany = (e, index) => {
    const updatedMenu = [...footercompany]; // Create a copy of the array
    updatedMenu[index] = e.target.value; // Update the value at the specified index
    setfootercompany(updatedMenu); // Update the state with the new array
  };
  const handefooterlegal = (e, index) => {
    const updatedMenu = [...footerlegal]; // Create a copy of the array
    updatedMenu[index] = e.target.value; // Update the value at the specified index
    setfooterlegal(updatedMenu); // Update the state with the new array
  };
  const handleRemovefooterfeature = (indexToRemove) => {
    setfooterfeature(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    })
  };

  const handelRemovefooterlegal = (indexToRemove) => {
    setfooterlegal(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    })
  };
  const handelRemovefooterCompany = (indexToRemove) => {
    setfootercompany(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    })
  };

  const handelRemoveNewfooterLinkgroups = (indexToRemove) => {
    setfooterLinkgroups(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    })
  };

  const handelfooteryear = (e) => {
    setFooteryear(e.target.value)
  };

  const RemoveSocialIcon1 = () => {
    setRemoveSocialIcon1(true)
  };
  const RemoveSocialIcon2 = () => {
    setRemoveSocialIcon2(true)
  };
  const RemoveSocialIcon3 = () => {
    setRemoveSocialIcon3(true)
  };
  const RemoveSocialIcon4 = () => {
    setRemoveSocialIcon4(true)
  };



  const RemoveFooterfeature = (indexToRemove) => {
    setfooterfeature(prevMenu => {
      const updatedMenu = [prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    })
  };
  const RemoveFooterCompany = (indexToRemove) => {
    setfootercompany(prevMenu => {
      const updatedMenu = [prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    })
  };
  const RemoveFooterLegal = (indexToRemove) => {
    setfooterlegal(prevMenu => {
      const updatedMenu = [prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    })
  };


  console.log('addfootersocial', addfootersocial)
  const handleAddFooterFeature = (newItem) => {
    setfooterfeature(prevMenu => [...prevMenu, newItem]);
  };

  const handleAddFooterCompany = (newItem) => {
    setfootercompany(prevMenu => [...prevMenu, newItem]);
  };
  const handleAddFooterLegal = (newItem) => {
    setfooterlegal(prevMenu => [...prevMenu, newItem]);
  };
  const HandelNewfootersocialIcon = () => {
    setNewfootersocialIcon(prevMenu => [...prevMenu, newfootersocialIcon])
  };
  const HandelNewfootersocialIconRemove = (indexToRemove) => {
    setNewfootersocialIcon(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });

  };
  const handelfooterfeaturehead = (e) => {
    setFooterfeaturehead(e.target.value)
  };
  const handelfootercompanyhead = (e) => {
    setFooterCompanyhead(e.target.value)
  };
  const handelfooterlegalhead = (e) => {
    setFooterleagalhead(e.target.value)
  }

  console.log('footerhead', footerfeaturehead, footerCompanyhead, footerlegalhead);
  const HnadelAddLinkGroups = () => {
    if (linkcount < 4) {
      setlinkcount(linkcount + 1)
    } else if (footerfeature.length !== 0) {
      setlinkcount(0)
    }
    else {
      setlinkcount(0);
    }
    if (linkcount == 1) {
      handleAddFooterFeature();
      setFooterfeaturehead();
    };
    if (linkcount == 2) {
      handleAddFooterCompany();
      setFooterCompanyhead();
    };
    if (linkcount == 3) {
      handleAddFooterLegal();
      setFooterleagalhead();
    }

  };
 
  console.log('count', linkcount, footerfeature.length, footercompany.length, footerlegal.length,newfooterhead.length,newfooteritem.length)
  return (
    <div>



      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?
              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Footer" />

                {contentdesing == false ?
                  <div className="editing_content__RXzxk">
                    <div className="form_form__Sku4X">
                      <div class="form_field__d31bq">
                        <textarea style={{ height: 32 }} onChange={(e) => handelfooteryear(e)}>{footeryear}</textarea>
                      </div>
                      <div className='form_sortable__FLtVI'>
                        <div className='form_listItem__puOqW'>
                          <div className='form_headerRemove__mJ7no'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                              <div class="media_preview__okAyH">
                                <label>Footer Logo</label>
                                <div class="media_value__+4KKG">
                                </div>
                              </div>
                              <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <label class="form_label__27psn" >Social Icons</label>
                      {removeSocialIcon1 == false ?
                        <div className='form_sortable__FLtVI'>
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => RemoveSocialIcon1()}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">
                              <div className='d-flex ' style={{ width: "92%" }}>

                                <div class="form_field__d31bq form_size--1x1__H0EdC"><div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                  <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiB3aWR0aD0iNDQ4IiBoZWlnaHQ9IjUxMiI+PHBhdGggZD0iTTQ0OCA1Ni43djM5OC41YTI0LjcgMjQuNyAwIDAgMS0yNC43IDI0LjdIMzA5LjFWMzA2LjVoNTguMmw4LjctNjcuNmgtNjd2LTQzLjJjMC0xOS42IDUuNC0zMi45IDMzLjUtMzIuOWgzNS44di02MC41Yy02LjItLjgtMjcuNC0yLjctNTIuMi0yLjctNTEuNiAwLTg3IDMxLjUtODcgODkuNHY0OS45aC01OC40djY3LjZoNTguNFY0ODBIMjQuN0EyNC44IDI0LjggMCAwIDEgMCA0NTUuM1Y1Ni43QTI0LjggMjQuOCAwIDAgMSAyNC43IDMyaDM5OC41QTI0LjggMjQuOCAwIDAgMSA0NDggNTYuN3oiIGZpbGw9ImN1cnJlbnRDb2xvciIvPjwvc3ZnPg==" alt="Icon Preview" style={{ maxWidth: '40px', maxHeight: '22px' }} /></div></div>
                                <div className='form_field__d31bq'>
                                  <textarea style={{ height: 32, padding: 5 }}>https://www.facebook.com/</textarea>
                                </div>
                                <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              </div>
                            </div>
                          </div>
                        </div> : null}

                      {removeSocialIcon2 == false ?
                        <div className='form_sortable__FLtVI'>
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => RemoveSocialIcon2()}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">
                              <div className='d-flex ' style={{ width: "92%" }}>

                                <div class="form_field__d31bq form_size--1x1__H0EdC"><div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                  <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiB3aWR0aD0iNDQ4IiBoZWlnaHQ9IjUxMiI+PHBhdGggZD0iTTIyNC4xIDE0MWMtNjMuNiAwLTExNC45IDUxLjMtMTE0LjkgMTE0LjlzNTEuMyAxMTQuOSAxMTQuOSAxMTQuOVMzMzkgMzE5LjUgMzM5IDI1NS45IDI4Ny43IDE0MSAyMjQuMSAxNDF6bTAgMTg5LjZhNzQuOCA3NC44IDAgMSAxIC4xLTE0OS4zIDc0LjggNzQuOCAwIDAgMS0uMSAxNDkuM3ptMTQ2LjQtMTk0LjNhMjYuNyAyNi43IDAgMSAxLTUzLjYgMCAyNi44IDI2LjggMCAwIDEgNTMuNiAwem03Ni4xIDI3LjJjLTEuNy0zNS45LTkuOS02Ny43LTM2LjItOTMuOS0yNi4yLTI2LjItNTgtMzQuNC05My45LTM2LjItMzctMi4xLTE0Ny45LTIuMS0xODQuOSAwLTM1LjggMS43LTY3LjYgOS45LTkzLjkgMzYuMXMtMzQuNCA1OC0zNi4yIDkzLjljLTIuMSAzNy0yLjEgMTQ3LjkgMCAxODQuOSAxLjcgMzUuOSA5LjkgNjcuNyAzNi4yIDkzLjlzNTggMzQuNCA5My45IDM2LjJjMzcgMi4xIDE0Ny45IDIuMSAxODQuOSAwIDM1LjktMS43IDY3LjctOS45IDkzLjktMzYuMiAyNi4yLTI2LjIgMzQuNC01OCAzNi4yLTkzLjkgMi4xLTM3IDIuMS0xNDcuOCAwLTE4NC44ek0zOTguOCAzODhhNzUuNiA3NS42IDAgMCAxLTQyLjYgNDIuNmMtMjkuNSAxMS43LTk5LjUgOS0xMzIuMSA5cy0xMDIuNyAyLjYtMTMyLjEtOUE3NS42IDc1LjYgMCAwIDEgNDkuNCAzODhjLTExLjctMjkuNS05LTk5LjUtOS0xMzIuMXMtMi42LTEwMi43IDktMTMyLjFBNzUuNiA3NS42IDAgMCAxIDkyIDgxLjJjMjkuNS0xMS43IDk5LjUtOSAxMzIuMS05czEwMi43LTIuNiAxMzIuMSA5YTc1LjYgNzUuNiAwIDAgMSA0Mi42IDQyLjZjMTEuNyAyOS41IDkgOTkuNSA5IDEzMi4xczIuNyAxMDIuNy05IDEzMi4xeiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+" alt="Icon Preview" style={{ maxWidth: '40px', maxHeight: '22px' }} /></div></div>
                                <div className='form_field__d31bq'>
                                  <textarea style={{ height: 32, padding: 5 }}>https://www.instagram.com/</textarea>
                                </div>
                                <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              </div>
                            </div>
                          </div>
                        </div> : null}

                      {removeSocialIcon3 == false ?
                        <div className='form_sortable__FLtVI'>
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => RemoveSocialIcon3()}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">
                              <div className='d-flex ' style={{ width: "92%" }}>

                                <div class="form_field__d31bq form_size--1x1__H0EdC"><div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                  <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PHBhdGggZD0iTTQ1OS40IDE1MS43Yy4zIDQuNi4zIDkuMS4zIDEzLjcgMCAxMzguNy0xMDUuNiAyOTguNS0yOTguNiAyOTguNUEyOTYuNSAyOTYuNSAwIDAgMSAwIDQxN2EyMTcgMjE3IDAgMCAwIDI1LjMgMS4yYzQ5IDAgOTQuMy0xNi42IDEzMC4zLTQ0LjgtNDYuMS0xLTg0LjgtMzEuMi05OC4xLTcyLjhhMTExIDExMSAwIDAgMCA0Ny40LTIgMTA1IDEwNSAwIDAgMS04NC4xLTEwM3YtMS4yYzE0IDcuOCAzMC4yIDEyLjYgNDcuNCAxMy4zQTEwNC45IDEwNC45IDAgMCAxIDM1LjcgNjcuMmEyOTguMyAyOTguMyAwIDAgMCAyMTYuNCAxMDkuOSAxMDQuOSAxMDQuOSAwIDAgMSAxNzktOTUuOCAyMDYuNiAyMDYuNiAwIDAgMCA2Ni42LTI1LjQgMTA0LjcgMTA0LjcgMCAwIDEtNDYuMSA1Ny44YzIxLTIuMyA0MS42LTguMSA2MC40LTE2LjJhMjI1LjYgMjI1LjYgMCAwIDEtNTIuNiA1NC4yeiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+" alt="Icon Preview" style={{ maxWidth: '40px', maxHeight: '22px' }} /></div></div>
                                <div className='form_field__d31bq'>
                                  <textarea style={{ height: 32, padding: 5 }}>https://twitter.com/?lang=en</textarea>
                                </div>
                                <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              </div>
                            </div>
                          </div>
                        </div> : null}

                      {removeSocialIcon4 == false ?
                        <div className='form_sortable__FLtVI'>
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => RemoveSocialIcon4()}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">
                              <div className='d-flex ' style={{ width: "92%" }}>

                                <div class="form_field__d31bq form_size--1x1__H0EdC"><div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                  <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzYgNTEyIiB3aWR0aD0iNTc2IiBoZWlnaHQ9IjUxMiI+PHBhdGggZD0iTTU0OS43IDEyNGE2OC42IDY4LjYgMCAwIDAtNDguMy00OC41QzQ1OC44IDY0IDI4OCA2NCAyODggNjRTMTE3LjIgNjQgNzQuNiA3NS41Yy0yMy41IDYuMy00MiAyNS00OC4zIDQ4LjZDMTUgMTY3IDE1IDI1Ni40IDE1IDI1Ni40czAgODkuNCAxMS40IDEzMi4zYTY3LjYgNjcuNiAwIDAgMCA0OC4zIDQ3LjhDMTE3LjIgNDQ4IDI4OCA0NDggMjg4IDQ0OHMxNzAuOCAwIDIxMy40LTExLjVhNjcuNiA2Ny42IDAgMCAwIDQ4LjMtNDcuOEM1NjEgMzQ1LjggNTYxIDI1Ni40IDU2MSAyNTYuNHMwLTg5LjUtMTEuNC0xMzIuM3pNMjMyIDMzNy43VjE3NS4ybDE0MyA4MS4yLTE0MyA4MS4yeiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+" alt="Icon Preview" style={{ maxWidth: '40px', maxHeight: '22px' }} /></div></div>
                                <div className='form_field__d31bq'>
                                  <textarea style={{ height: 32, padding: 5 }}>https://www.youtube.com/</textarea>
                                </div>
                                <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              </div>
                            </div>
                          </div>
                        </div> : null}


                      {newfootersocialIcon.map((item, index) => (
                        <div className='form_sortable__FLtVI'>
                          <div className='form_listItem__puOqW'>
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' onClick={() => HandelNewfootersocialIconRemove()}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>
                            <div className="form_form__Sku4X">
                              <div className='d-flex ' style={{ width: "92%" }}>

                                <div class="form_field__d31bq form_size--1x1__H0EdC"><div class="picker_preview__QSKw4 picker_invert__zNRsC">
                                </div></div>
                                <div className='form_field__d31bq'>
                                  <textarea style={{ height: 32, padding: 5 }} value={'#'}></textarea>
                                </div>
                                <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}


                      {((removeSocialIcon1 == true && removeSocialIcon2 == true) && (removeSocialIcon3 == true && newfootersocialIcon.length == 0)) ?
                        <div className='form_emptyList__KNV3N'>
                          <div>Your list is empty</div>
                          <button class="button1 button--small" onClick={() => HandelNewfootersocialIcon()}>Add Link</button>
                        </div> : <button class="button1 button--small " style={{ alignSelf: 'flex-end', marginTop: '15px' }} onClick={() => HandelNewfootersocialIcon()}>Add Social Icon</button>}


                      <br />


                      <label class="form_label__27psn" style={{ display: footerfeature.length == 0 ? 'none' : '' }}>Link Groups</label>
                      <div className='form_sortable__FLtVI' style={{ display: footerfeature.length == 0 ? 'none' : '' }}>
                        <div className='form_listItem__puOqW'>
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                          <div className='form_headerRemove__mJ7no' onClick={() => RemoveFooterfeature()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='d-flex '>
                              <div className='form_field__d31bq'>
                                <textarea style={{ height: 32, padding: 5 }} onChange={(e) => handelfooterfeaturehead(e)} value={footerfeature.length==0?'':footerfeaturehead}></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <label class="form_label__27psn">Links</label>
                      {footerfeature.map((item, index) => (
                        <li key={index} style={{ listStyle: 'none' }}>
                          <div className='form_sortable__FLtVI'>
                            <div className='form_listItem__puOqW'>
                              <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                              <div className='form_headerRemove__mJ7no' onClick={() => handleRemovefooterfeature(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                              </div>
                              <div className="form_form__Sku4X">
                                <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                                  <div className='form_field__d31bq'>
                                    <textarea style={{ height: 32, padding: 5 }} value={footerfeature[index]} onChange={(e) => handelnavbaritem(e, index)}>{item}</textarea>
                                  </div>
                                  <div className='form_field__d31bq'>
                                    <input type="url" placeholder="/your-page" />
                                  </div>
                                  <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                </div>

                              </div>
                            </div>
                            <button class="button1 button--small mb-3 " style={{ alignSelf: 'flex-end', marginTop: '15px' }} onClick={() => handleAddFooterFeature()}>Add Link</button>
                          </div></li>))}

                      {/* {footerfeature.length == 0 ?
                        <div className='form_emptyList__KNV3N'>
                          <div>Your list is empty</div>
                          <button class="button1 button--small" onClick={() => handleAddFooterFeature()}>Add Item</button>
                        </div> : <button class="button1 button--small mb-3 " style={{ alignSelf: 'flex-end', marginTop: '15px' }} onClick={() => handleAddFooterFeature()}>Add Link</button>} */}
                      {/* {footercompany.length == 0 ?
                        <div className='form_emptyList__KNV3N'>
                          <div>Your list is empty</div>
                          <button class="button1 button--small" onClick={() => handleAddFooterFeature()}>Add Link Groups</button>
                        </div> : null} */}
                      <br />



                      <label class="form_label__27psn" style={{ display: footercompany.length == 0 ? 'none' : '' }}>Link Groups</label>
                      <div className='form_sortable__FLtVI' style={{ display: footercompany.length == 0 ? 'none' : '' }}>
                        <div className='form_listItem__puOqW'>
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                          <div className='form_headerRemove__mJ7no' onClick={() => RemoveFooterCompany()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='d-flex '>
                              <div className='form_field__d31bq' >
                                <textarea style={{ height: 32, padding: 5 }} onChange={(e) => handelfootercompanyhead(e)} value={footercompany.length==0?'':footerCompanyhead}></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {footercompany.map((item, index) => (

                        <li key={index} style={{ listStyle: 'none' }}>
                          <div className='form_sortable__FLtVI'>
                            <div className='form_listItem__puOqW'>
                              <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                              <div className='form_headerRemove__mJ7no' onClick={() => handelRemovefooterCompany(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                              </div>
                              <div className="form_form__Sku4X">
                                <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                                  <div className='form_field__d31bq'>
                                    <textarea style={{ height: 32, padding: 5 }} value={footercompany[index]} onChange={(e) => handefootercompany(e, index)}>{item}</textarea>
                                  </div>
                                  <div className='form_field__d31bq'>
                                    <input type="url" placeholder="/your-page" />
                                  </div>
                                  <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                </div>

                              </div>
                            </div>
                            <button class="button1 button--small mb-3 " style={{ alignSelf: 'flex-end', marginTop: '15px' }} onClick={() => handleAddFooterCompany()}>Add Link</button>
                          </div> </li>))}

                      {/* {footercompany.length == 0 ?
                        <div className='form_emptyList__KNV3N'>
                          <div>Your list is empty</div>
                          <button class="button1 button--small" onClick={() => handleAddFooterCompany()}>Add Item</button>
                        </div> : <button class="button1 button--small mb-3 " style={{ alignSelf: 'flex-end', marginTop: '15px' }} onClick={() => handleAddFooterCompany()}>Add Link</button>} */}
                      {/* {footercompany.length == 0 ?
                        <div className='form_emptyList__KNV3N'>
                          <div>Your list is empty</div>
                          <button class="button1 button--small" onClick={() => handleAddFooterCompany()}>Add Link Groups</button>
                        </div> : null} */}


                      <br />

                      <label class="form_label__27psn" style={{ display: footerlegal.length == 0 ? 'none' : '' }}>Link Groups</label>
                      <div className='form_sortable__FLtVI' style={{ display: footerlegal.length == 0 ? 'none' : '' }}>
                        <div className='form_listItem__puOqW'>
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                          <div className='form_headerRemove__mJ7no' onClick={() => RemoveFooterLegal()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='d-flex '>
                              <div className='form_field__d31bq'>
                                <textarea style={{ height: 32, padding: 5 }} onChange={(e) => handelfooterlegalhead(e)} value={footerlegal.length==0?'':footerlegalhead}></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      {footerlegal.map((item, index) => (

                        <li key={index} style={{ listStyle: 'none' }}>
                          <div className='form_sortable__FLtVI'>
                            <div className='form_listItem__puOqW'>
                              <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                              <div className='form_headerRemove__mJ7no' onClick={() => handelRemovefooterlegal(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                              </div>
                              <div className="form_form__Sku4X">
                                <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                                  <div className='form_field__d31bq'>
                                    <textarea style={{ height: 32, padding: 5 }} value={footerlegal[index]} onChange={(e) => handefooterlegal(e, index)}>{item}</textarea>
                                  </div>
                                  <div className='form_field__d31bq'>
                                    <input type="url" placeholder="/your-page" />
                                  </div>
                                  <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                </div>

                              </div>
                            </div>
                            <button class="button1 button--small mb-3 " style={{ alignSelf: 'flex-end', marginTop: '15px' }} onClick={() => handleAddFooterLegal()}>Add Link</button>

                          </div> </li>))}



                      {((footercompany.length == 0) && ((footerfeature.length == 0) && (footerlegal.length == 0))) ?
                        <div className='form_emptyList__KNV3N'>
                          <div>Your list is empty</div>
                          <button class="button1 button--small" onClick={() => HnadelAddLinkGroups()}>Add Link Groups</button>
                        </div> :
                        // <div style={{display:linkcount==4?'none':'',alignSelf: 'flex-end', marginTop: '15px',}}>
                          <button class="button1 button--small" style={{display:(linkcount==4||linkcount==0)?'none':'', alignSelf: 'flex-end', marginTop: '15px',}} onClick={() => HnadelAddLinkGroups()}>Add Link Groups</button>
                        // </div> 
                      }


                    </div>
                  </div> : <FooterDesing />}



              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto' ><Theme/></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <WebPages />
          </div>
        </div>
      </div>
    </div>
  )
};

export default FooterEdit;