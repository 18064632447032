
import React, { useContext, useEffect, useState } from 'react';
import Main from '../Main';
import { Link } from 'react-router-dom';
import WebPages from '../Webpages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faEllipsisV, faSquare } from '@fortawesome/free-solid-svg-icons';
import MainNavBar from '../MainNavBar';
import Expand from '../../assets/images/prime_expand.svg'
import MyContext from '../../context/MyContext';
import Theme from '../Theme';
import Memecoin from '../MemeCoin/Memecoin';
import MemecoinUpdate from '../MemeCoin/Memecoinupdate';
import UrlNav from '../UrlNav';
import Dashborad from '../Dashborad';

function Saveandedit() {

    const { mainnavbarbutton, setMainnavbarbutton,
        section1, setsection1,
        section2, setsection2,
        section3, setsection3,
        section4, setsection4,
        section5, setsection5,
        section6, setsection6,
        section7, setsection7, memecoin} = useContext(MyContext);
    const handelsection1 = () => {

        setsection1(true);
        setsection2(false);
        setsection3(false);
        setsection4(false);
        setsection5(false);
        setsection6(false);
        setsection7(false);
    };
    const handelsection2 = () => {

        setsection1(false);
        setsection2(true);
        setsection3(false);
        setsection4(false);
        setsection5(false);
        setsection6(false);
        setsection7(false);
    };
    const handelsection3 = () => {

        setsection1(false);
        setsection2(false);
        setsection3(true);
        setsection4(false);
        setsection5(false);
        setsection6(false);
        setsection7(false);
    };
    const handelsection4 = () => {

        setsection1(false);
        setsection2(false);
        setsection3(false);
        setsection4(true);
        setsection5(false);
        setsection6(false);
        setsection7(false);
    };
    const handelsection5 = () => {

        setsection1(false);
        setsection2(false);
        setsection3(false);
        setsection4(false);
        setsection5(true);
        setsection6(false);
        setsection7(false);
    };
    const handelsection6 = () => {

        setsection1(false);
        setsection2(false);
        setsection3(false);
        setsection4(false);
        setsection5(false);
        setsection6(true);
        setsection7(false);
    };
    const handelsection7 = () => {

        setsection1(false);
        setsection2(false);
        setsection3(false);
        setsection4(false);
        setsection5(false);
        setsection6(false);
        setsection7(true);
    };

    console.log('mainnavbarbutton', section7)
    return (

        <div>
            <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
                <div>
                    <MainNavBar />
                </div>
                <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
                    {memecoin=='true'?<MemecoinUpdate/>:
                    <div style={{ width: '400px' }} >
                        {mainnavbarbutton == false ?
                            <div className='editing_wrapper__Wijfx h-100  overflow-auto' style={{zIndex:0}}>
                                <div className='nav_nav__LpVJF sticky-top' >
                                    <div className='nav_main__HWvnb' >
                                        <div className='nav_pagePickerWr__vnrHs'>
                                            {/* <div class="nav_item__Yxa31 nav_page__g0+lb" style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                                                <div>
                                                    <svg fill="#193fa2" width="14px" height="14px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M9.288,3.525V.064l3.461,3.461Zm-1.763,0A1.763,1.763,0,0,0,9.288,5.288h3.525v6.949A1.763,1.763,0,0,1,11.05,14H3.118a1.762,1.762,0,0,1-1.763-1.77V1.77A1.768,1.768,0,0,1,3.118,0H7.525V3.525Z" /></svg>
                                                    <span className='nav_nav__LpVJF_span'>Homepage</span>
                                                </div>
                                                <svg fill="#193fa2" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M24 24H0V0h24v24z" opacity=".87" fill="none" /> <path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z" /></svg>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='meta_metaBox__TIPHD'>
                                    <span className='meta_metaBoxIcon__Ae6z2'>
                                        <svg fill="none" width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM8.24992 4.49999C8.24992 4.9142 7.91413 5.24999 7.49992 5.24999C7.08571 5.24999 6.74992 4.9142 6.74992 4.49999C6.74992 4.08577 7.08571 3.74999 7.49992 3.74999C7.91413 3.74999 8.24992 4.08577 8.24992 4.49999ZM6.00003 5.99999H6.50003H7.50003C7.77618 5.99999 8.00003 6.22384 8.00003 6.49999V9.99999H8.50003H9.00003V11H8.50003H7.50003H6.50003H6.00003V9.99999H6.50003H7.00003V6.99999H6.50003H6.00003V5.99999Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" /></svg>
                                    </span>
                                    <span>Site and Page setting</span>
                                    <span>Title, Description, Favicon, Languages, Password</span>
                                    <svg fill="none" className='arrow' width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" /></svg>
                                </div> */}
                                <Link to='/heroedit' style={{ textDecoration: 'none' }}>
                                 <div className='overview_standaloneItem__IoH4J'>
                                    <div className='item_item__rs7kB'>
                                        <div className='sectionPreview_sectionPreview__fStoX' onClick={() => handelsection1()}>
                                            <div className='sectionPreview_firstRow__weeLn '>
                                                <span className='sectionPreview_headerIcon__ontsV'></span>
                                               
                                                    <span onClick={() => handelsection1()}>Header</span>
                                                <button className='sectionPreview_focus__zwCCI'
                                                    onClick={() => handelsection1()}>
                                                    <svg fill="#4d4d4d" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M4 15c-.55 0-1 .45-1 1v3c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1zm1-9c0-.55.45-1 1-1h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1V6zm14-3h-3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1 .45 1 1v2c0 .55.45 1 1 1s1-.45 1-1V5c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c1.1 0 2-.9 2-2v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                                        </svg>
                                                </button>
                                                <div style={{ width: 10, flexShrink: 0 }}></div>
                                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} class="sectionPreview_sectionMenu__hl3Cr sectionPreview_small__Vsbac" type="button" id="radix-:ran:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg fill="none" width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" /></svg></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Link>

                                <Link to='/IntroEdit' style={{ textDecoration: 'none' }}>
                                <div className='overview_standaloneItem__IoH4J' onClick={() => handelsection2()}>
                                    <div className='item_item__rs7kB'>
                                    
                                        <div className='sectionPreview_sectionPreview__fStoX' >
                                            <div className='sectionPreview_firstRow__weeLn '>
                                                <span class="sectionPreview_defaultIcon__HR7Kh"></span>
                                                
                                                    <span  >Hero</span>
                                                <button onClick={() => handelsection2()} className='sectionPreview_focus__zwCCI'
                                                >
                                                    <svg fill="#4d4d4d" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M4 15c-.55 0-1 .45-1 1v3c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1zm1-9c0-.55.45-1 1-1h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1V6zm14-3h-3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1 .45 1 1v2c0 .55.45 1 1 1s1-.45 1-1V5c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c1.1 0 2-.9 2-2v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
                                                </button>                                        <div style={{ width: 10, flexShrink: 0 }}></div>
                                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} class="sectionPreview_sectionMenu__hl3Cr sectionPreview_small__Vsbac" type="button" id="radix-:ran:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg fill="none" width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" /></svg></button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                </Link>

                                <Link to='/Trustedit' style={{ textDecoration: 'none' }}>
                                <div className='overview_standaloneItem__IoH4J' onClick={() => handelsection3()}>
                                    <div className='item_item__rs7kB'>

                                        <div className='sectionPreview_sectionPreview__fStoX' >
                                            <div className='sectionPreview_firstRow__weeLn '>
                                                <span class="sectionPreview_defaultIcon__HR7Kh"></span>
                                                
                                                    <span onClick={() => handelsection3()}>Logos</span>
                                                <button onClick={() => handelsection3()} className='sectionPreview_focus__zwCCI'>
                                                    <svg fill="#4d4d4d" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M4 15c-.55 0-1 .45-1 1v3c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1zm1-9c0-.55.45-1 1-1h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1V6zm14-3h-3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1 .45 1 1v2c0 .55.45 1 1 1s1-.45 1-1V5c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c1.1 0 2-.9 2-2v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
                                                </button>                                        <div style={{ width: 10, flexShrink: 0 }}></div>
                                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} class="sectionPreview_sectionMenu__hl3Cr sectionPreview_small__Vsbac" type="button" id="radix-:ran:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg fill="none" width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" /></svg></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Link>

                                <Link to='/featureEdit' style={{ textDecoration: 'none' }}>
                                <div className='overview_standaloneItem__IoH4J' onClick={() => handelsection4()}>
                                    <div className='item_item__rs7kB'>
                                        <div className='sectionPreview_sectionPreview__fStoX' >
                                            <div className='sectionPreview_firstRow__weeLn '>
                                                <span class="sectionPreview_defaultIcon__HR7Kh"></span>
                                               
                                                    <span >Features</span>
                                                <button onClick={() => handelsection4()} className='sectionPreview_focus__zwCCI'>
                                                    <svg fill="#4d4d4d" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M4 15c-.55 0-1 .45-1 1v3c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1zm1-9c0-.55.45-1 1-1h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1V6zm14-3h-3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1 .45 1 1v2c0 .55.45 1 1 1s1-.45 1-1V5c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c1.1 0 2-.9 2-2v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
                                                </button>                                      <div style={{ width: 10, flexShrink: 0 }}></div>
                                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} class="sectionPreview_sectionMenu__hl3Cr sectionPreview_small__Vsbac" type="button" id="radix-:ran:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg fill="none" width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" /></svg></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Link>


                                <Link to='/LegacyEdit' style={{ textDecoration: 'none' }}>
                                <div className='overview_standaloneItem__IoH4J' onClick={() => handelsection5()}>
                                    <div className='item_item__rs7kB'>
                                        <div className='sectionPreview_sectionPreview__fStoX' >
                                            <div className='sectionPreview_firstRow__weeLn '>
                                                <span class="sectionPreview_defaultIcon__HR7Kh"></span>
                                                
                                                    <span >Stats</span>
                                                <button onClick={() => handelsection5()} className='sectionPreview_focus__zwCCI'>
                                                    <svg fill="#4d4d4d" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M4 15c-.55 0-1 .45-1 1v3c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1zm1-9c0-.55.45-1 1-1h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1V6zm14-3h-3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1 .45 1 1v2c0 .55.45 1 1 1s1-.45 1-1V5c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c1.1 0 2-.9 2-2v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
                                                </button>                                         <div style={{ width: 10, flexShrink: 0 }}></div>
                                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} class="sectionPreview_sectionMenu__hl3Cr sectionPreview_small__Vsbac" type="button" id="radix-:ran:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg fill="none" width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" /></svg></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Link>

                                <Link to='/faqEdit' style={{ textDecoration: 'none' }}>
                                <div className='overview_standaloneItem__IoH4J'  onClick={() => handelsection6()}>
                                    <div className='item_item__rs7kB'>
                                        <div className='sectionPreview_sectionPreview__fStoX'>
                                            <div className='sectionPreview_firstRow__weeLn '>
                                                <span class="sectionPreview_defaultIcon__HR7Kh"></span>
                                                
                                                    <span>FAQs</span>
                                                <button onClick={() => handelsection6()} className='sectionPreview_focus__zwCCI'>
                                                    <svg fill="#4d4d4d" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M4 15c-.55 0-1 .45-1 1v3c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1zm1-9c0-.55.45-1 1-1h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1V6zm14-3h-3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1 .45 1 1v2c0 .55.45 1 1 1s1-.45 1-1V5c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c1.1 0 2-.9 2-2v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
                                                </button>                                        <div style={{ width: 10, flexShrink: 0 }}></div>
                                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} class="sectionPreview_sectionMenu__hl3Cr sectionPreview_small__Vsbac" type="button" id="radix-:ran:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg fill="none" width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" /></svg></button>
                                            </div>
                                        </div>
                                    </div>
                                </div></Link>
                                <Link to='/FooterEdit' style={{ textDecoration: 'none', textDecorationColor: "black" }}>
                                <div className='overview_standaloneItem__IoH4J' onClick={() => handelsection7()}>
                                    <div className='item_item__rs7kB'>
                                        <div className='sectionPreview_sectionPreview__fStoX' >
                                            <div className='sectionPreview_firstRow__weeLn '>
                                                <span class="sectionPreview_defaultIcon__HR7Kh"></span>
                                                
                                                    <span >Footer</span>
                                                <button onClick={() => handelsection7()} className='sectionPreview_focus__zwCCI'>
                                                    <svg fill="#4d4d4d" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M4 15c-.55 0-1 .45-1 1v3c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1zm1-9c0-.55.45-1 1-1h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1V6zm14-3h-3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1 .45 1 1v2c0 .55.45 1 1 1s1-.45 1-1V5c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c1.1 0 2-.9 2-2v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
                                                </button>                                        <div style={{ width: 10, flexShrink: 0 }}></div>
                                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} class="sectionPreview_sectionMenu__hl3Cr sectionPreview_small__Vsbac" type="button" id="radix-:ran:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg fill="none" width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" /></svg></button>
                                            </div>
                                        </div>
                                    </div>
                                </div></Link>

                            </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme /></div>}
                    </div>}
                      
                    <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
       
        {memecoin=='true'?(<div class='container'><Memecoin/></div>):(<WebPages />)} 
                        {/* <Dashborad/> */}

                    </div>
                </div>
            </div>
        </div>

    )
};

export default Saveandedit;