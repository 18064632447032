import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../../context/MyContext";
import { SketchPicker } from "react-color";
import WebPages from "../../Webpages";
import DesignText from "../../DesignText";
import DesignSpacing from "../../DesignSpacing";
import DesignOther from "../../DesignOther";
import axios from "axios";
import { useAccount } from 'wagmi'  



const MemeStepdesing = () => {

    const [color, setcolor] = useState(true);
    const { introcolor, setintrocolor,
        introtext, setintrotext, 
     selectedHero, setSelectedHero,
     newBackgroundcolor, setNewbackgroundcolor,
     memenavtextcolor,setMemenavtextcolor,
     memecoinsteptextcolor,setMemecoinsteptextcolor,
     memecoinstepfont, uniqueID,modalShow,
    } = useContext(MyContext)
    const [newbgcolor, setNewbgcolor] = useState([]);
    
    // API to store meme step design data. For testing purpose using use effect
    const { address } = useAccount()

    useEffect(() => {
        const updateMemeStepsDesignData = async () => {
            const response = await axios.post('https://trendifyweb.ai/pyapi/updateMemeStepsDesignData', {
                params: {
                    address: address,
                    siteid: uniqueID,
                    memecoinstepfont: memecoinstepfont,
                    memecoinsteptextcolor: memecoinsteptextcolor,
                }
            });
            console.log('memeHeaderDesignAPIresponse', response)
        };
        updateMemeStepsDesignData();
    }, [memecoinsteptextcolor])
    
    

    const handeltitle = (e) => {
    
        setNewbackgroundcolor(!newBackgroundcolor)
    }

    const handelchangecolor = (introcolor) => {
        setMemecoinsteptextcolor(introcolor.hex)
        // if ((introcolor !== 'white') || (introcolor !== '#000000')) {
        //     setintrotext('white')
        // };
        // if ((introcolor == '#ffffff') || (introcolor.hex == '#ffffff')) {
        //     setintrotext('black')
        // }

    };
    console.log('initaile footercolor color', introcolor)
    const handlecolorButtonRemov = (indexToRemove) => {
        setintrocolor(prevMenu => {
            const updatedMenu = [...prevMenu];
            updatedMenu.splice(indexToRemove, 1);
            return updatedMenu;

        });

    };
    const AddnewbgColor = (Newcolor) => {
        if (newbgcolor.length < 1) {
            setNewbgcolor(preValue => [...preValue, Newcolor])
        }
    };
    useEffect(() => {
        function handleClickOutside(event) {
            const target = event.target;
            const divToExclude = document.getElementById("sketchPickerDiv");

            // Check if the click occurred outside of the specific div
            if (divToExclude && !divToExclude.contains(target)) {
                setNewbackgroundcolor(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [newBackgroundcolor]);

    // console.log('newbgcolor',newbgcolor.length)
    
    const [isIntroDesignText, setisIntroDesignText] = useState(false); // State to control popup visibility
    const [isDesignSpacing, setDesignSpacing] = useState(false);
    const [isOther, setisOther] = useState(false);

    const openOtherPopup = () => {
       setisOther(true);
    }
    const closeOtherPopup = () => {
       setisOther(false);
    }

    const openSpacingPopup = () => {
        setDesignSpacing(true);
    };

    const closeSpacingPopup = () => {
        setDesignSpacing(false);
    };
    const openPopup = () => {
        setisIntroDesignText(true);
    };

    const closePopup = () => {
        setisIntroDesignText(false);
    };

    const handleClickOutsidePopup = (event) => {
        if ((isIntroDesignText || isDesignSpacing || isOther) && !event.target.closest('.popup')) {
            closePopup();
        }
    };
    const handleClickOutsideOtherPopup = (event) => {
        if (isOther && !event.target.closest('.popup')) {
            closeOtherPopup();
        }
    }

    const handleClickOutsideSpacingPopup = (event) => {
        if (isDesignSpacing && !event.target.closest('.popup')) {
            closeSpacingPopup();
        }
    }

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsidePopup);
        document.addEventListener('mousedown', handleClickOutsideOtherPopup);
        document.addEventListener('mousedown', handleClickOutsideSpacingPopup);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsidePopup);
            document.removeEventListener('mousedown', handleClickOutsideOtherPopup);
            document.addEventListener('mousedown', handleClickOutsideSpacingPopup);

        };
    }, [isIntroDesignText, isDesignSpacing, isOther]);

    return (
        <div>
            <div className='editing_design'>
                <div className=''>
                    <div className='design_container'>
                        <div className='design_options'>
                            <button class="" onClick={openPopup}>
                                <svg width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M3 7L3 5L17 5V7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10 5L10 19M10 19H12M10 19H8" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M13 14L13 12H21V14" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 12V19M17 19H15.5M17 19H18.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                                <span>Text</span>
                            </button>
                            {isIntroDesignText && (
                                <DesignText closePopup={closePopup} />
                            )}
                            <div className='list_top'>
                                <button class="list_header" onClick={() => AddnewbgColor()}>
                                    <svg width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M2.63596 10.2927L9.70703 3.22168L18.1923 11.707L11.1212 18.778C10.3402 19.5591 9.07387 19.5591 8.29282 18.778L2.63596 13.1212C1.85492 12.3401 1.85492 11.0738 2.63596 10.2927Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.29297 1.80762L9.70718 3.22183" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M19.9991 15C19.9991 15 22.9991 17.9934 22.9994 19.8865C22.9997 21.5422 21.6552 22.8865 19.9997 22.8865C18.3442 22.8865 17.012 21.5422 17 19.8865C17.0098 17.9924 19.9991 15 19.9991 15Z" stroke="currentColor" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    <span>Text Color</span>
                                    <span class="list_itemAdd">
                                        <svg width="20" height="20" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M6 12H12M18 12H12M12 12V6M12 12V18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </span>

                                </button>
                                
                                {newbgcolor && newbgcolor.map((item, index) => (
                                    <div className='list_draggable' style={{ zIndex: 1 }} key={index}>
                                        <button className="list_dragHandle-button"></button>
                                        <div className='list_item '>
                                            <span onClick={() => handeltitle(index)} style={{cursor:'pointer'}}>color</span>
                                            {newBackgroundcolor == false ? null :
                                                <div id='sketchPickerDiv' style={{ position: 'relative', top: 160, }}>
                                                    <SketchPicker color={memecoinsteptextcolor} onChange={handelchangecolor}></SketchPicker>
                                                </div>
                                            }
                                            <button onClick={() => handlecolorButtonRemov()} className="list_itemDelete">
                                                <svg width="20" height="20" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor">
                                                    <path d="M6 12H18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}

                            </div>
                            <button class="" onClick={openSpacingPopup} style={{display:modalShow==true?'none':'block'}}>
                                <svg width="18" height="18" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M2 12H22" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7 4H4V7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M11 4H13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 4H20V7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M11 20H13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7 20H4V17" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 20H20V17" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                <span>Spacing</span>
                            </button>
                            {isDesignSpacing && (
                                <DesignSpacing closeSpacingPopup={closeSpacingPopup} />
                            )}
                            <button class=" " onClick={openOtherPopup} style={{display:modalShow==true?'none':'block'}}>
                                <svg width="18" height="18" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.6224 10.3954L18.5247 7.7448L20 6L18 4L16.2647 5.48295L13.5578 4.36974L12.9353 2H10.981L10.3491 4.40113L7.70441 5.51596L6 4L4 6L5.45337 7.78885L4.3725 10.4463L2 11V13L4.40111 13.6555L5.51575 16.2997L4 18L6 20L7.79116 18.5403L10.397 19.6123L11 22H13L13.6045 19.6132L16.2551 18.5155C16.6969 18.8313 18 20 18 20L20 18L18.5159 16.2494L19.6139 13.598L21.9999 12.9772L22 11L19.6224 10.3954Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                <span>Other</span>
                            </button>
                            {isOther && (
                                <DesignOther closeOtherPopup={closeOtherPopup} />
                        
                            )}
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )


};

export default MemeStepdesing;