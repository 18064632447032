import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MyContext from '../context/MyContext';
import { TbClipboardSmile } from 'react-icons/tb';
import axios from 'axios';
import { useAccount } from 'wagmi'

function Button() {
  const { faq, title, searchTerm, setSearchTerm, serachtearm1, setSearchteam, setRandomId, memecoinAbout, memecoin,randomId,setUniqueID,setMemecoinsection, UniqueID } = useContext(MyContext);
  const [progress, setProgress] = useState(0);
  const [progressmeme, setProgressmeme] = useState(0);

  // TODO: add newBackgroundcolor
  // states required to store entire Website data on save and edit button
  const {featureimages, introImages, buttonshowdoblur, buttonshowdowcolor, buttonshowdospread, 
    buttonshowdoleft, buttonshowdoTop, featureIcon1, featureIcon2, featureIcon3,featureIcon4, 
    navbtn1, navbtn2, headerdivtextcolor, headerdivtext, headerdivbgcolor, navbartitle, headerdiv, 
    mainnavbarbutton, contentdesing, Section1, menu, navbarmenu, navremovebtn1, navremovebtn2, navbarremove, 
    navAddbutton, inttrobtn1, introbtn2, menu1, intro, introbtn1remove, introbtn2remove, introbuttonapprovel, 
    addintrobutton, intromenubgcolor, selectedOption1, introcaption, section2, 
    trust, trsuttitle, trustLogo,
    serviceheader, servicesIntro1, serviceHeading1, serviceinfo1, serviceHeading2, serviceinfo2, serviceHeading3, serviceinfo3, serviceHeading4, serviceinfo4, 
    legacy, statsintro, statsnumber1, statsnumber2, statsnumber3, statsnumber4, statsnumber5, statsnumber6,  
    faqtitle, faqIntro, faqQ1, faqA1, faqQ2, faqA2, faqQ3, faqA3, faqQ4, faqA4, 
    footeryear, footerfeature, footercompany, footerlegal,
    memecoinlogoImage, memecoinname, memecoinsymbol, memecoinNavbar, memecoinAminitionImage, memeCaption, memecoinValue, memeherobutton, memecoinaboutleftimage, memecoinaboutrightimage, 
    memecoinavisionleftimage, memecoinavisionrightimage, memecoincontractaddress, memecoinInitialsupply, memecoinCirculatingSupply, memecoinSupplyBurned, memecoinLiquidity,
    memecointokenomicsimage, stepheading, step1, step2, step3, step4, memefooterabout, memetwitterlink, memetelegramlink, memeYoutubelink, memetiktoklink, memeinstagramlink, 
    memewarpcastlink,
    selectMemeCoinTemplates, newlogo, images, newIntroImage, welcometilte, titlecolor, navbartextcolor, footercolor, footertext, introcolor, introtext, legacycolor, legacytext, 
    featurecolor, featuretext, faqcolor, faqtext, trustcolor, trusttext, serviceremove1, serviceremove2, serviceremove3, serviceremove4, statsnumremove1, statsnumremove2, statsnumremove3, 
    removeFaq1, removeFaq2, removeFaq3, removeFaq4, removeSocialIcon1, removeSocialIcon2, removeSocialIcon3, removeSocialIcon4, addbuttonapprovel, newservicesheading, newtrustlogo, 
    newlegacy, newFaq, newservicesImage, newBackgroundcolor, headertextfont, footerfeaturehead, footerCompanyhead, footerlegalhead, trustCaption, servicecaption, statscaption,
    faqCaption, introtextfont, introinfotextfont, trusttextfont, trusttitletextfont, servicetitletextfont, serviceBodytextfont, statstitletextfont, statsbodytextfont, faqtitletextfont, 
    faqbodytextfont, footerbodytextfont, stateOne, statetwo, statethree, contenttextheadingcolor, contenttextinfocolor, contactheadingcolor, conatcmenuborder, selectedLi, checked, captioncolor, 
    statevaluecolor, statslabelcolor, statsbackgroundcolor, statsborder, statsborderradius, statsshowdowrange, statsbordercolor, selectedHero, button1font, buttoncolor, buttonborder, 
    buttonborderradius, buttonbordercolor, butttonshowdo, selectedFaq, selectedFooter, selectedLogo, Animated, selectedStats, buttonvarticalpadding, buttonhorizantlepadding, selectedFeature, 
    imagePosition, alignMedia, contractRenounced, memecoinaboutbgImage, memecoinDatabaseImage, memecoinCirculatingImage, memecoinBurnedImage, memecoinLiquidityImage, memenavbgcolor, 
    memeaboutbgcolor, memecointokonomicsbgimage, memecoinanvfont, memenavtextcolor, memecoinaboutfont, memecoinabouttextcolor, memecoinvisionfont, memecoinvisiontextcolor, memecointokenfont, 
    memecoinstepfont, memecoinsteptextcolor, iSremove1, iSremove2, iSremove3, iSremove4, iSremove5, iSremove6, memeaboutbgimage, memestepbgimage, memenavbarbgcolor, memetokenomicsbgcolor, 
    memestepbgcolor, memefooterbgcolor, memetokencaption, memetokenbuy, memeherobgcolor, template1caption, template1Info, template1tokenimage2, template1tokenimage3, template1tokenomics, 
    template1tokenomicssub1, template1tokenomicssub2, template1tokenomicssub3, template1tokenomisupply, template1tokenomicontract, template1tokenomiTax, template1footerabout, template1footerImage,
    template1stepcontract, template1stepAdd, template2footerlogo, template2footeringo, template2stepheading, template2step1, template2step2, template2step3, template2step4, template2tokenheading,
    template2tokensupply, template2tokentatesupply, template2tokentax, template2tokenlp, template2tokenMeme, template2telegramlink, template2twitterlink, template2caption, template2memecaption,
    template2info, template2tokenAddress, template2uniswap, template2cmc, template2coingecko, template3telegramlink, template3twitterlink, template3uniswaplink, template3heading, template3section1, 
    template3Section2, template3Section3, template3Section4, template3stepimage, template3stepquestion, template3stepsection1, template3step3section2heading, template3stepsection2, 
    template3stepsection2bg, template3stepsection2color, template3footerheading, template3footerinfo, template3email, template3memeaddress, t4navbutton, t4memeaddress, t4caption, 
    t4mainbutton1, t4mainbutton2, t4token, t4step1, t4step2, t4step3, t4step4, t4step5, t4footer1, t4footer2, t4footer3, t4stepimage, t5Nav1, t5nav2, t5about1, t5about2, t5Token1, t5Token3,
    t5Token4, t5Token5, t5Token6, t5step1, t5step2, t5step3, t5step4, memefootertextfont, memefootertextcolor} = useContext(MyContext);


    const { address, isConnected } = useAccount()

    let siteid = null


  useEffect(() => {
    const interval = setInterval(() => {
      // Simulate progress update
      setProgress(prevProgress => {
        if (prevProgress == 100) {

          // clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 182.9);
    if ((faq != '') || (progress == 100)) {
      clearInterval(interval);

    }

    return () => clearInterval(interval);
  }, [faq]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Simulate progress update
      setProgressmeme(prevProgressmeme => {
        if (prevProgressmeme == 100) {

          // clearInterval(interval);
          return 100;
        }
        return prevProgressmeme + 1;
      });
    }, 182.9);
    if ((memecoinAbout != '') || (progressmeme == 100)) {
      clearInterval(interval);

    }

    return () => clearInterval(interval);
  }, [memecoinAbout]);
  console.log(serachtearm1, 'serach');

  function generateRandomId() {
    const randid = Math.random().toString(36).substring(2);
    siteid = randid
    setRandomId(randid)
    console.log('ID',randid);
    setUniqueID(randid);

  }

    useEffect(()=>{
      setMemecoinsection(0)
    },[]);
    console.log('Buttun',)

    // api to store entire website data om save and edit button
    const storeWebsiteInfo = async () => {
      try{
        const response = await axios.post('https://trendifyweb.ai/pyapi/save_and_edit', {
          params: {
            address: address,
            siteid: siteid,
            memecoin: memecoin,
            navbartitle: navbartitle,
            navbarmenu: navbarmenu,
            navbtn1: navbtn1,
            navbtn2: navbtn2,
            featureimages: featureimages,
            introImages: introImages,
            trust: trust,
            trsuttitle: trsuttitle,
            trustLogo: trustLogo,
            serviceheader: serviceheader,
            servicesIntro1: servicesIntro1,
            serviceHeading1: serviceHeading1,
            serviceinfo1: serviceinfo1,
            serviceHeading2: serviceHeading2,
            serviceinfo2: serviceinfo2,
            serviceHeading3: serviceHeading3,
            serviceinfo3: serviceinfo3,
            serviceHeading4: serviceHeading4,
            serviceinfo4: serviceinfo4,
            legacy: legacy,
            statsintro: statsintro,
            statsnumber1: statsnumber1,
            statsnumber2: statsnumber2,
            statsnumber3: statsnumber3,
            statsnumber4: statsnumber4,
            statsnumber5: statsnumber5,
            statsnumber6: statsnumber6,
            faqtitle: faqtitle,
            faqIntro: faqIntro,
            faqQ1: faqQ1,
            faqA1: faqA1,
            faqQ2: faqQ2,
            faqA2: faqA2,
            faqQ3: faqQ3,
            faqA3: faqA3,
            faqQ4: faqQ4,
            faqA4: faqA4,
            footeryear: footeryear,
            footerfeature: footerfeature,
            footercompany: footercompany,
            footerlegal: footerlegal,
            buttonshowdoblur: buttonshowdoblur,
            buttonshowdowcolor: buttonshowdowcolor,
            buttonshowdospread: buttonshowdospread,
            buttonshowdoleft: buttonshowdoleft,
            buttonshowdoTop: buttonshowdoTop,
            featureIcon1: featureIcon1,
            featureIcon2: featureIcon2,
            featureIcon3: featureIcon3,
            featureIcon4: featureIcon4,
            headerdivtextcolor: headerdivtextcolor,
            headerdivtext: headerdivtext,
            headerdivbgcolor: headerdivbgcolor,
            headerdiv: headerdiv,
            mainnavbarbutton: mainnavbarbutton,
            contentdesing: contentdesing,
            // Section1: Section1,
            menu: menu,
            navremovebtn1: navremovebtn1,
            navremovebtn2: navremovebtn2,
            navbarremove: navbarremove,
            navAddbutton: navAddbutton,
            inttrobtn1: inttrobtn1,
            introbtn2: introbtn2,
            menu1: menu1,
            title: title,
            intro: intro,
            introbtn1remove: introbtn1remove,
            introbtn2remove: introbtn2remove,
            introbuttonapprovel: introbuttonapprovel,
            addintrobutton: addintrobutton,
            intromenubgcolor: intromenubgcolor,
            selectedOption1: selectedOption1,
            introcaption: introcaption,
            section2: section2,

            memecoinlogoImage: memecoinlogoImage,
            memecoinNavbar: memecoinNavbar,
            memecoinAminitionImage: memecoinAminitionImage,
            memeCaption: memeCaption,
            memecoinValue: memecoinValue,
            memecoinname: memecoinname,
            memecoinsymbol: memecoinsymbol,
            memecoinaboutleftimage: memecoinaboutleftimage,
            memecoinaboutrightimage: memecoinaboutrightimage,
            memeherobutton: memeherobutton,
            memecoinAbout: memecoinAbout,
            memecoinavisionleftimage: memecoinavisionleftimage,
            memecoinavisionrightimage: memecoinavisionrightimage,
            memecoincontractaddress: memecoincontractaddress,
            memecoinInitialsupply: memecoinInitialsupply,
            memecoinCirculatingSupply: memecoinCirculatingSupply,
            memecoinSupplyBurned: memecoinSupplyBurned,
            memecoinLiquidity: memecoinLiquidity,
            memecointokenomicsimage: memecointokenomicsimage,
            stepheading: stepheading,
            step1: step1,
            step2: step2,
            step3: step3,
            step4: step4,
            memefooterabout: memefooterabout,
            memetwitterlink: memetwitterlink,
            memetelegramlink: memetelegramlink,
            memeYoutubelink: memeYoutubelink,
            memetiktoklink: memetiktoklink,
            memeinstagramlink: memeinstagramlink,
            memewarpcastlink: memewarpcastlink,
            selectMemeCoinTemplates: selectMemeCoinTemplates,
            newlogo: newlogo,
            // images: images,
            newIntroImage: newIntroImage,
            // welcometilte: welcometilte,
            titlecolor: titlecolor,
            navbartextcolor: navbartextcolor,
            footercolor: footercolor,
            footertext: footertext,
            introcolor: introcolor,
            introtext: introtext,
            legacycolor: legacycolor,
            legacytext: legacytext,
            featurecolor: featurecolor,
            featuretext: featuretext,
            faqcolor: faqcolor,
            faqtext: faqtext,
            trustcolor: trustcolor,
            trusttext: trusttext,
            serviceremove1: serviceremove1,
            serviceremove2: serviceremove2,
            serviceremove3: serviceremove3,
            serviceremove4: serviceremove4,
            statsnumremove1: statsnumremove1,
            statsnumremove2: statsnumremove2,
            statsnumremove3: statsnumremove3,
            removeFaq1: removeFaq1,
            removeFaq2: removeFaq2,
            removeFaq3: removeFaq3,
            removeFaq4: removeFaq4,
            removeSocialIcon1: removeSocialIcon1,
            removeSocialIcon2: removeSocialIcon2,
            removeSocialIcon3: removeSocialIcon3,
            removeSocialIcon4: removeSocialIcon4,
            addbuttonapprovel: addbuttonapprovel,
            newservicesheading: newservicesheading,
            newtrustlogo: newtrustlogo,
            newlegacy: newlegacy,
            newFaq: newFaq,
            newservicesImage: newservicesImage,
            // newBackgroundcolor: newBackgroundcolor,
            headertextfont: headertextfont,
            footerfeaturehead: footerfeaturehead,
            footerCompanyhead: footerCompanyhead,
            footerlegalhead: footerlegalhead,
            trustCaption: trustCaption,
            servicecaption: servicecaption,
            statscaption: statscaption,
            faqCaption: faqCaption,
            introtextfont: introtextfont,
            introinfotextfont: introinfotextfont,
            trusttextfont: trusttextfont,
            trusttitletextfont: trusttitletextfont,
            servicetitletextfont: servicetitletextfont,
            serviceBodytextfont: serviceBodytextfont,
            statstitletextfont: statstitletextfont,
            statsbodytextfont: statsbodytextfont,
            faqtitletextfont: faqtitletextfont,
            faqbodytextfont: faqbodytextfont,
            footerbodytextfont: footerbodytextfont,
            stateOne: stateOne,
            statetwo: statetwo,
            statethree: statethree,
            contenttextheadingcolor: contenttextheadingcolor,
            contenttextinfocolor: contenttextinfocolor,
            contactheadingcolor: contactheadingcolor,
            conatcmenuborder: conatcmenuborder,
            selectedLi: selectedLi,
            checked: checked,
            captioncolor: captioncolor,
            statevaluecolor: statevaluecolor,
            statslabelcolor: statslabelcolor,
            statsbackgroundcolor: statsbackgroundcolor,
            statsborder: statsborder,
            statsborderradius: statsborderradius,
            statsshowdowrange: statsshowdowrange,
            statsbordercolor: statsbordercolor,
            selectedHero: selectedHero,
            button1font: button1font,
            buttoncolor: buttoncolor,
            buttonborder: buttonborder,
            buttonborderradius: buttonborderradius,
            buttonbordercolor: buttonbordercolor,
            butttonshowdo: butttonshowdo,
            selectedFaq: selectedFaq,
            selectedFooter: selectedFooter,
            selectedLogo: selectedLogo,
            Animated: Animated,
            selectedStats: selectedStats,
            buttonvarticalpadding: buttonvarticalpadding,
            buttonhorizantlepadding: buttonhorizantlepadding,
            selectedFeature: selectedFeature,
            imagePosition: imagePosition,
            alignMedia: alignMedia,
            contractRenounced: contractRenounced,
            memecoinaboutbgImage: memecoinaboutbgImage,
            memecoinDatabaseImage: memecoinDatabaseImage,
            memecoinCirculatingImage: memecoinCirculatingImage,
            memecoinBurnedImage: memecoinBurnedImage,
            memecoinLiquidityImage: memecoinLiquidityImage,
            memenavbgcolor: memenavbgcolor,
            memeaboutbgcolor: memeaboutbgcolor,
            memecointokonomicsbgimage: memecointokonomicsbgimage,
            memecoinanvfont: memecoinanvfont,
            memenavtextcolor: memenavtextcolor,
            memecoinaboutfont: memecoinaboutfont,
            memecoinabouttextcolor: memecoinabouttextcolor,
            memecoinvisionfont: memecoinvisionfont,
            memecoinvisiontextcolor: memecoinvisiontextcolor,
            memecointokenfont: memecointokenfont,
            memecoinstepfont: memecoinstepfont,
            memecoinsteptextcolor: memecoinsteptextcolor,
            iSremove1: iSremove1,
            iSremove2: iSremove2,
            iSremove3: iSremove3,
            iSremove4: iSremove4,
            iSremove5: iSremove5,
            iSremove6: iSremove6,
            memeaboutbgimage: memeaboutbgimage,
            memestepbgimage: memestepbgimage,
            memenavbarbgcolor: memenavbarbgcolor,
            memetokenomicsbgcolor: memetokenomicsbgcolor,
            memestepbgcolor: memestepbgcolor,
            memefooterbgcolor: memefooterbgcolor,
            memetokencaption: memetokencaption,
            memetokenbuy: memetokenbuy,
            memeherobgcolor: memeherobgcolor,
            template1caption: template1caption,
            template1Info: template1Info,
            template1tokenimage2: template1tokenimage2,
            template1tokenimage3: template1tokenimage3,
            template1tokenomics: template1tokenomics,
            template1tokenomicssub1: template1tokenomicssub1,
            template1tokenomicssub2: template1tokenomicssub2,
            template1tokenomicssub3: template1tokenomicssub3,
            template1tokenomisupply: template1tokenomisupply,
            template1tokenomicontract: template1tokenomicontract,
            template1tokenomiTax: template1tokenomiTax,
            template1footerabout: template1footerabout,
            template1footerImage: template1footerImage,
            template1stepcontract: template1stepcontract,
            template1stepAdd: template1stepAdd,
            template2footerlogo: template2footerlogo,
            template2footeringo: template2footeringo,
            template2stepheading: template2stepheading,
            template2step1: template2step1,
            template2step2: template2step2,
            template2step3: template2step3,
            template2step4: template2step4,
            template2tokenheading: template2tokenheading,
            template2tokensupply: template2tokensupply,
            template2tokentatesupply: template2tokentatesupply,
            template2tokentax: template2tokentax,
            template2tokenlp: template2tokenlp,
            template2tokenMeme: template2tokenMeme,
            template2telegramlink: template2telegramlink,
            template2twitterlink: template2twitterlink,
            template2caption: template2caption,
            template2memecaption: template2memecaption,
            template2info: template2info,
            template2tokenAddress: template2tokenAddress,
            template2uniswap: template2uniswap,
            template2cmc: template2cmc,
            template2coingecko: template2coingecko,
            template3telegramlink: template3telegramlink,
            template3twitterlink: template3twitterlink,
            template3uniswaplink: template3uniswaplink,
            template3heading: template3heading,
            template3section1: template3section1,
            template3Section2: template3Section2,
            template3Section3: template3Section3,
            template3Section4: template3Section4,
            template3stepimage: template3stepimage,
            template3stepquestion: template3stepquestion,
            template3stepsection1: template3stepsection1,
            template3step3section2heading: template3step3section2heading,
            template3stepsection2: template3stepsection2,
            template3stepsection2bg: template3stepsection2bg,
            template3stepsection2color: template3stepsection2color,
            template3footerheading: template3footerheading,
            template3footerinfo: template3footerinfo,
            template3email: template3email,
            template3memeaddress: template3memeaddress,
            t4navbutton: t4navbutton,
            t4memeaddress: t4memeaddress,
            t4caption: t4caption,
            t4mainbutton1: t4mainbutton1,
            t4mainbutton2: t4mainbutton2,
            t4token: t4token,
            t4step1: t4step1,
            t4step2: t4step2,
            t4step3: t4step3,
            t4step4: t4step4,
            t4step5: t4step5,
            t4footer1: t4footer1,
            t4footer2: t4footer2,
            t4footer3: t4footer3,
            t4stepimage: t4stepimage,
            t5Nav1: t5Nav1,
            t5nav2: t5nav2,
            t5about1: t5about1,
            t5about2: t5about2,
            t5Token1: t5Token1,
            t5Token3: t5Token3,
            t5Token4: t5Token4,
            t5Token5: t5Token5,
            t5Token6: t5Token6,
            t5step1: t5step1,
            t5step2: t5step2,
            t5step3: t5step3,
            t5step4: t5step4,
            memefootertextfont: memefootertextfont,
            memefootertextcolor: memefootertextcolor,

        }                
        })
        console.log(response.data)
    } catch (error) {
            console.log(error)
        }
}

  return (

    <div>
      <div className='d-flex align-items-center justify-content-between  container' style={{minWidth:"100%"}}>
        {memecoin == 'false' ?
          <div>

            {
              faq == '' && progress < 99 ?
                <div class="text-center" >
                  <span>{serachtearm1} {progress}% </span>

                  <div class="spinner-border border-1" role="status" style={{ height: 17, width: 16, marginLeft: 3 }}>
                  </div>
                </div> : <div style={{ display: 'none' }}></div>

            }
          </div> : <div>

            {
              memecoinAbout == '' && progress < 99 ?
                <div class="text-center" >
                  <span>{serachtearm1} {progressmeme}% </span>

                  <div class="spinner-border border-1" role="status" style={{ height: 17, width: 16, marginLeft: 3 }}>
                  </div>
                </div> : <div style={{ display: 'none' }}></div>

            }
          </div>}
        <div>
          
          {memecoin=='false'?
          <Link to='/save&edit' style={{ textDecorationLine: 'none' }}>
            {faq == '' ? <button disabled className="border-0" style={{ height: 40, textDecoration: 'none', padding: '0px 15px 0px 15px', color: 'white', background: 'rgba(94, 34, 190,0.3) ', borderRadius: 10, fontSize: 16 }}>Save & Edit  <FontAwesomeIcon icon={faArrowRight} />  </button>
              : <button className="border-0" style={{ height: 40, textDecoration: 'none', padding: '0px 15px 0px 15px', color: 'white', background: 'rgb(94, 34, 190) ', borderRadius: 10, fontSize: 16 }} onClick={()=>{generateRandomId();setMemecoinsection(7); storeWebsiteInfo()}}>Save & Edit  <FontAwesomeIcon icon={faArrowRight} />  </button>}

          </Link>:<Link to='/save&edit' style={{ textDecorationLine: 'none' }}>
            {memecoinAbout == '' ? <button disabled className="border-0" style={{ height: 40, textDecoration: 'none', padding: '0px 15px 0px 15px', color: 'white', background: 'rgba(94, 34, 190,0.3) ', borderRadius: 10, fontSize: 16 }}>Save & Edit  <FontAwesomeIcon icon={faArrowRight} />  </button>
              : <button className="border-0" style={{ height: 40, textDecoration: 'none', padding: '0px 15px 0px 15px', color: 'white', background: 'rgb(94, 34, 190) ', borderRadius: 10, fontSize: 16 ,}} onClick={()=>{generateRandomId();setMemecoinsection(7); storeWebsiteInfo()}}>Save & Edit  <FontAwesomeIcon icon={faArrowRight} />  </button>}

          </Link>}
        </div>
      </div>
    </div>
  )
};

export default Button