import react, { useContext, useEffect, useRef, useState } from 'react'
import MyContext from '../../../context/MyContext';
import MainNavBar from '../../MainNavBar';
import ContentDesign from '../../ContentDesign';
import Memecoin from '../Memecoin';
import HeroDesign from '../../SaveandEdit/HeroDesign';
import Theme from '../../Theme';
import Boomer from '../../../assets/images/logo2.png';
import Backgroundimage from '../../../assets/images/menubackground.png'
import MemeNavdesing from './MemecoinNavdesing';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import { useAccount } from 'wagmi';



function MemeNavbarEdit() {
  const { navbtn1, setNavbtn1,
    navbtn2, setNavbtn2,
    headerdivtextcolor, setHeaderDivTextColor,
    headerdivtext, setHeaderDivText,
    headerdivbgcolor, setHeaderdivbgcolor,
    navbartitle, setNavbartitle,
    headerdiv, setHeaderdiv,
    mainnavbarbutton, contentdesing,
    Section1, menu, newIntroImage, setNewIntroImage,
    swtmenu1, title,
    setTitle, intro,
    setIntro, welcometilte,
    setwelcometitle, navbarmenu,
    setnavbarmenu, Navbarstring,
    navremovebtn1, setNavremovebtn1,
    navremovebtn2, setNavremovebtn2,
    navbuttonincraese, setNavbuttonincrease,
    navbarremove, setNavbarremove,
    setAddnavmenu, addnavmenu,
    navAddbutton, setNavaddbutton,
    addbuttonapprovel, setAddbuttonapprovel,
    newlogo, setNewlogo,
    headernotice, setheadernotice,
    colorheaderDiv, setcolorheaderdiv, section1,
    memecoinNavbar, setMemecoinNavbar,
    memecoinlogoImage, setMemecoinlogoImage,
    memenavbgcolor, setMemenavbgcolor,
    memenavbarbgcolor, setMemenavbarbgcolor,

    memebackground, setMemebackground, uniqueID,
    selectMemeCoinTemplates,
    memecoinname, SetMemecoinName,
    template2telegramlink, setTemplate2telegramlink,
    template2twitterlink, setTemplate2twitterlink,
    t4navbutton, setT4Navbutton,

    setTemplate2cmc, template2cmc,
    t5Nav1, setT5Nav1,
    t5nav2, sett5Nav2,
    buybuttonbgcolor, setbuybuttonbgcolor,
    buybuttnbgImage, setbuybuttnbgImage,
    memebuybuttonbackground,setMemebuybuttonbackground,
    twitterimage,setTwitterimage,telegraminage,
    setTelegraminage,
    
  } = useContext(MyContext)

  const [heroedit, setheroedit] = useState(true);
  const [colortext, setcolortext] = useState(false);

  const contentEditableRef = useRef(null);
  const [caretPosition, setCaretPosition] = useState(0);
  const [containerCount, setContainerCount] = useState(1);
  const [navbuttoncount, setNavbuttoncount] = useState(1);
  const [isHeaderDesignText, setisHeaderDesignText] = useState(false);
  const [buybuutoncolor, setbuybuutoncolor] = useState(false);

  // API to store meme header content data. For testing purpose using use effect

  const { address } = useAccount();

  useEffect(() => {


    const updateMemeHeaderContentData = async () => {
      const response = await axios.post('https://trendifyweb.ai/pyapi/updateMemeHeaderContentData', {
        params: {
          address: address,
          siteid: uniqueID,
          memecoinNavbar: memecoinNavbar,
          memecoinlogoImage: memecoinlogoImage,
          memenavbgcolor: memenavbgcolor,
          memenavbarbgcolor: memenavbarbgcolor,
          memecoinname: memecoinname,
          template2telegramlink: template2telegramlink,
          template2twitterlink: template2twitterlink,
          t4navbutton: t4navbutton,
          t5Nav1: t5Nav1,
          t5nav2: t5nav2,
          template2cmc: template2cmc
        }
      });

      console.log("memeContentresponse", response.data)
    };
    updateMemeHeaderContentData();

  }, [memenavbarbgcolor, memenavbgcolor]);



  const handelheadernotice = () => {
    setheadernotice(!headernotice);

  };

  const handelheaderdiv = () => {
    setHeaderdiv(!headerdiv);

  };
  console.log('headernotice', headernotice)

  const handelbgcolor = () => {
    setcolorheaderdiv(!colorheaderDiv)
  }
  const handeltextcolor = () => {
    setcolortext(!colortext);
  }
  console.log('heroedit', heroedit)
  console.log('color24', colorheaderDiv)


  const handelnavbaritem = (e, index) => {
    const updatedMenu = [...memecoinNavbar];
    updatedMenu[index] = e.target.value;
    setMemecoinNavbar(updatedMenu);
  };
  console.log(menu);
  console.log('Section1', Section1)








  console.log('headerdivtext', headerdivtext);











  const handleNavMenuRemove = (indexToRemove) => {
    setMemecoinNavbar(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });

  };






  const handleAddNavmenu = (navbarmenu) => {
    setMemecoinNavbar(prevMenu => [...prevMenu, navbarmenu]);

  };






  useEffect(() => {
    function handleClickOutside(event) {
      const target = event.target;
      const divToExclude = document.getElementById("sketchPickerDiv");

      if (divToExclude && !divToExclude.contains(target)) {
        setcolortext(false);
        setcolorheaderdiv(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colortext, colorheaderDiv]);

  console.log(colortext, colortext, colorheaderDiv);
  console.log('Section1', section1,)
  const handleSvgClick = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinlogoImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinlogoImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleNavbgcolor = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memenavbgcolor];
        updatedTrustLogo[index] = event.target.result;
        setMemenavbgcolor(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  const handleBuybuttonImage = (index) => {

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...buybuttnbgImage];
        updatedTrustLogo[index] = event.target.result;
        setbuybuttnbgImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  console.log('Memelogo', memecoinlogoImage, Boomer)

  const handelchangecolor = (titlecolor) => {
    setMemenavbarbgcolor(titlecolor.hex)
    // if ((titlecolor !== 'white') || (titlecolor.hex !== '#000000')) {
    //     setnavbartextcolor('white')
    // }
    // if ((titlecolor == '#ffffff') || (titlecolor.hex == '#ffffff')) {
    //     setnavbartextcolor('Black')
    // }
  };
  const HandelChangebuybuttoncolor=(titlecolor)=>{
        setbuybuttonbgcolor(titlecolor.hex)
  }

  const handeltitle = () => {
    setisHeaderDesignText(!isHeaderDesignText)
  }
  const HandelBuybuttoncolor=()=>{
    setbuybuutoncolor(!buybuutoncolor)
  }
  useEffect(() => {
    function handleClickOutside(event) {
      const target = event.target;
      const divToExclude = document.getElementById('one');

      // Check if the click occurred outside of the specific div
      if (divToExclude && !divToExclude.contains(target)) {
        setisHeaderDesignText(false);
        setbuybuutoncolor(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isHeaderDesignText]);
   

  const handleTwitterImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...twitterimage];
        updatedTrustLogo[index] = event.target.result;
        setTwitterimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  const handleTelegramImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...telegraminage];
        updatedTrustLogo[index] = event.target.result;
        setTelegraminage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }
  

  return (
    <>
      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?

              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Header" />
                {contentdesing == false ?
                  <div>
                    {selectMemeCoinTemplates == 1 ?
                      <div className='editing_content__RXzxk'>

                        <div className='form_sortable__FLtVI'>
                          {Array.isArray(memecoinNavbar) && memecoinNavbar.map((item, index) => (
                            <div>
                              {navbarremove == false ?
                                <div className='form_listItem__puOqW' key={index} style={{ marginTop: '10px' }} >
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                  <div className='form_headerRemove__mJ7no' onClick={(e) => handleNavMenuRemove(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                  </div>

                                  <div className="form_form__Sku4X">
                                    <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                      <div className='form_field__d31bq'>
                                        <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} onChange={(e) => handelnavbaritem(e, index)} value={memecoinNavbar[index]}>{item}</textarea>
                                      </div>
                                      {/* <div className='form_field__d31bq'>
                                    <input type="url" className='w-100' placeholder="/your-page" />
                                  </div> */}
                                      <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                    </div>
                                    {/* <div className='form_emptyList__KNV3N'>
                                  <div>Your list is empty</div>
                                  <button class="button1 button--small">Add Item</button>
                                </div> */}
                                  </div>
                                </div>
                                : <div className='form_emptyList__KNV3N'>
                                  <div>Your list is empty</div>
                                  <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>

                                </div>}
                            </div>))}


                          <div style={{ position: 'relative', left: 280, marginTop: 30 }}>
                            <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>
                          </div>

                        </div>

                        <div className="media_preview__okAyH">
                          <label>Picture</label>
                          <div className="media_value__\+4KKG ">
                            {memecoinlogoImage.length > 0 ?
                              <div>
                                {memecoinlogoImage.map((image, index) => (
                                  <img
                                    onClick={() => handleSvgClick(0)}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => handleSvgClick(0)} style={{ height: 30, width: 40 }} src={memecoinlogoImage ? memecoinlogoImage : Boomer} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />
                        <span>Background Image</span>
                        <div className="media_preview__okAyH" >
                          <label>Background Image</label>
                          <div className="media_value__\+4KKG ">
                            {memenavbgcolor.length > 0 ?
                              <div>
                                {memenavbgcolor.map((image, index) => (
                                  <img
                                    onClick={() => { handleNavbgcolor(0); setMemebackground(0); }}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => { handleNavbgcolor(0); setMemebackground(0); }} style={{ height: 30, width: 40 }} src={memenavbgcolor.length > 0 ? memenavbgcolor : `url(${Backgroundimage})`} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />

                        <span >Background Color</span>

                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemebackground(1); }}>
                          <label> Background Color</label>
                          <div className="media_value__\+4KKG ">

                            <div style={{ height: 30, width: 40, backgroundColor: memenavbarbgcolor.length == 0 ? '' : memenavbarbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                          </div>


                        </div>
                        {isHeaderDesignText == false ? null :
                          <div id='one' style={{ position: 'relative', }}>
                            <SketchPicker color={memenavbarbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                        }
                      </div> : null}

                    {selectMemeCoinTemplates == 2 ?
                      <div className='editing_content__RXzxk'>

                        <div className='form_sortable__FLtVI'>
                          {Array.isArray(memecoinNavbar) && memecoinNavbar.map((item, index) => (
                            <div>
                              {navbarremove == false ?
                                <div className='form_listItem__puOqW' key={index} style={{ marginTop: '10px' }} >
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                  <div className='form_headerRemove__mJ7no' onClick={(e) => handleNavMenuRemove(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                  </div>

                                  <div className="form_form__Sku4X">
                                    <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                      <div className='form_field__d31bq'>
                                        <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} onChange={(e) => handelnavbaritem(e, index)} value={memecoinNavbar[index]}>{item}</textarea>
                                      </div>
                                      {/* <div className='form_field__d31bq'>
                                    <input type="url" className='w-100' placeholder="/your-page" />
                                  </div> */}
                                      <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                    </div>
                                    {/* <div className='form_emptyList__KNV3N'>
                                  <div>Your list is empty</div>
                                  <button class="button1 button--small">Add Item</button>
                                </div> */}
                                  </div>
                                </div> : <div className='form_emptyList__KNV3N'>
                                  <div>Your list is empty</div>
                                  <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>

                                </div>}
                            </div>))}


                          <div style={{ position: 'relative', left: 280, marginTop: 30 }}>
                            <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>
                          </div>

                        </div>
                        <div class="form_field__d31bq" >
                                        <span>Meme Coin Name</span>
                                        <textarea placeholder="Name" style={{ height: "32px" }} value={memecoinname} onChange={(e) => SetMemecoinName(e.target.value)}>
                                        </textarea>
                                      </div>

                        {/* <div className="media_preview__okAyH">
                            <label>Picture</label>
                            <div className="media_value__\+4KKG ">
                              {memecoinlogoImage.length>0?
                               <div>
                               {memecoinlogoImage.map((image, index) => (
                                <img
                                onClick={()=>handleSvgClick(0)}
                                  key={index}
                                  style={{ height: 30, width: 40 }}
                                  src={image}
                                  alt={`Image ${index}`}
                                  className="media_image__kN9DM"
                                />
                              ))}</div>
                                  :
                              <img onClick={()=>handleSvgClick(0)} style={{height:30,width:40}}src={memecoinlogoImage?memecoinlogoImage:Boomer} alt="" className="media_image__kN9DM" />
                              }
                              </div>

                              
                          </div> */}
                        <br />
                        <span>Background Image</span>
                        <div className="media_preview__okAyH" >
                          <label>Background Image</label>
                          <div className="media_value__\+4KKG ">
                            {memenavbgcolor.length > 0 ?
                              <div>
                                {memenavbgcolor.map((image, index) => (
                                  <img
                                    onClick={() => { handleNavbgcolor(0); setMemebackground(0); }}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => { handleNavbgcolor(0); setMemebackground(0); }} style={{ height: 30, width: 40 }} src={memenavbgcolor.length > 0 ? memenavbgcolor : `url(${Backgroundimage})`} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />

                        <span >Background Color</span>

                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemebackground(1); }}>
                          <label> Background Color</label>
                          <div className="media_value__\+4KKG ">

                            <div style={{ height: 30, width: 40, backgroundColor: memenavbarbgcolor.length == 0 ? '' : memenavbarbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                          </div>


                        </div>
                        {isHeaderDesignText == false ? null :
                          <div id='one' style={{ position: 'relative', }}>
                            <SketchPicker color={memenavbarbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                        }
                      </div> : null}

                    {selectMemeCoinTemplates == 3 ?
                      <div className='editing_content__RXzxk'>

                        <div className='form_sortable__FLtVI'>
                          {Array.isArray(memecoinNavbar) && memecoinNavbar.map((item, index) => (
                            <div>
                              {navbarremove == false ?
                                <div className='form_listItem__puOqW' key={index} style={{ marginTop: '10px' }} >
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                  

                                  <div className="form_form__Sku4X">
                                    <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                      <div className='form_field__d31bq'>
                                        <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} onChange={(e) => handelnavbaritem(e, index)} value={memecoinNavbar[index]}>{item}</textarea>
                                      </div>

                                      <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                    </div>

                                  </div>
                                </div>
                                : <div className='form_emptyList__KNV3N'>
                                  <div>Your list is empty</div>
                                  <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>

                                </div>}
                            </div>))}
                          <br />
                          <div className='form_listItem__puOqW' style={{ marginTop: '10px' }} >
                            {/* <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div> */}
                           

                            <div className="form_form__Sku4X">
                              <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                <div className='form_field__d31bq'>
                                  <label>Meme Name</label>
                                  <textarea placeholder="MemeCoin Name" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={memecoinname} onChange={(e) => SetMemecoinName(e.target.value)}></textarea>
                                </div>

                                {/* <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div> */}
                              </div>

                            </div>
                          </div>

                          <div className='form_listItem__puOqW' style={{ marginTop: '10px' }} >
                            {/* <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div> */}
                            

                            <div className="form_form__Sku4X">
                              <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                <div className='form_field__d31bq'>
                                  <label>Telegram</label>
                                  <textarea placeholder="Telegram" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={template2telegramlink} onChange={(e) => setTemplate2telegramlink(e.target.value)}></textarea>
                                  <br/>
                                  <div className="media_preview__okAyH">
                                        <label>Telegram Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {telegraminage.length > 0 ?
                                            <div>
                                              {telegraminage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleTelegramImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleTelegramImage(0) }} style={{ height: 30, width: 40 }} src={telegraminage.length > 0 ? telegraminage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                </div>

                                {/* <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div> */}
                              </div>

                            </div>
                          </div>

                          <div className='form_listItem__puOqW' style={{ marginTop: '10px' }} >
                            {/* <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div> */}
                            

                            <div className="form_form__Sku4X">
                              <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                <div className='form_field__d31bq'>
                                  <label>Twitter</label>
                                  <textarea placeholder="Twitter" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={template2twitterlink} onChange={(e) => setTemplate2twitterlink(e.target.value)}></textarea>
                                  <br/>
                                  <div className="media_preview__okAyH">
                                        <label>Twitter Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {twitterimage.length > 0 ?
                                            <div>
                                              {twitterimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleTwitterImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleTwitterImage(0) }} style={{ height: 30, width: 40 }} src={twitterimage.length > 0 ? twitterimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div> 
                                </div>

                                {/* <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div> */}
                              </div>

                            </div>
                          </div>


                          

                        </div>

                        <div className="media_preview__okAyH">
                          <label>Meme Image</label>
                          <div className="media_value__\+4KKG ">
                            {memecoinlogoImage.length > 0 ?
                              <div>
                                {memecoinlogoImage.map((image, index) => (
                                  <img
                                    onClick={() => handleSvgClick(0)}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => handleSvgClick(0)} style={{ height: 30, width: 40 }} src={memecoinlogoImage ? memecoinlogoImage : Boomer} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />
                        <label>Background Image</label>
                        <div className="media_preview__okAyH" >
                          <label>Background Image</label>
                          <div className="media_value__\+4KKG ">
                            {memenavbgcolor.length > 0 ?
                              <div>
                                {memenavbgcolor.map((image, index) => (
                                  <img
                                    onClick={() => { handleNavbgcolor(0); setMemebackground(0); }}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => { handleNavbgcolor(0); setMemebackground(0); }} style={{ height: 30, width: 40 }} src={memenavbgcolor.length > 0 ? memenavbgcolor : `url(${Backgroundimage})`} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />

                        <span >Background Color</span>

                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemebackground(1); }}>
                          <label>Background Color</label>
                          <div className="media_value__\+4KKG ">

                            <div style={{ height: 30, width: 40, backgroundColor: memenavbarbgcolor.length == 0 ? '' : memenavbarbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                          </div>


                        </div>
                        {isHeaderDesignText == false ? null :
                          <div id='one' style={{ position: 'relative', }}>
                            <SketchPicker color={memenavbarbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                        }
                      </div> : null}

                    {selectMemeCoinTemplates == 4 ?
                      <div className='editing_content__RXzxk'>

                        <div className='form_sortable__FLtVI'>
                          {Array.isArray(memecoinNavbar) && memecoinNavbar.slice(0, 2).map((item, index) => (
                            <div>
                              {navbarremove == false ?
                                <div className='form_listItem__puOqW' key={index} style={{ marginTop: '10px' }} >
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                  <div className='form_headerRemove__mJ7no' onClick={(e) => handleNavMenuRemove(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                  </div>

                                  <div className="form_form__Sku4X">
                                    <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                      <div className='form_field__d31bq'>
                                        <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} onChange={(e) => handelnavbaritem(e, index)} value={memecoinNavbar[index]}>{item}</textarea>
                                      </div>

                                      <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                    </div>

                                  </div>
                                </div> : <div className='form_emptyList__KNV3N'>
                                  <div>Your list is empty</div>
                                  <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>

                                </div>}
                            </div>))}


                          <div style={{ position: 'relative', left: 280, marginTop: 30 }}>
                            <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>
                          </div>

                        </div>





                      </div> : null}



                    {selectMemeCoinTemplates == 6 ?
                      <div className='editing_content__RXzxk'>

                        <div className='form_sortable__FLtVI'>
                          {Array.isArray(memecoinNavbar) && memecoinNavbar.map((item, index) => (
                            <div>
                              {navbarremove == false ?
                                <div className='form_listItem__puOqW' key={index} style={{ marginTop: '10px' }} >
                                  <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                  <div className='form_headerRemove__mJ7no' onClick={(e) => handleNavMenuRemove(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                  </div>

                                  <div className="form_form__Sku4X">
                                    <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                      <div className='form_field__d31bq'>
                                        <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} maxLength={12} onChange={(e) => handelnavbaritem(e, index)} value={memecoinNavbar[index]}>{item}</textarea>
                                      </div>

                                      <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                    </div>

                                  </div>
                                </div> : <div className='form_emptyList__KNV3N'>
                                  <div>Your list is empty</div>
                                  <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>

                                </div>}
                            </div>))}


                          <div style={{ position: 'relative', left: 280, marginTop: 30 }}>
                            <button class="button1 button--small" onClick={() => handleAddNavmenu()} >Add Item</button>
                          </div>

                          <div className='form_listItem__puOqW' style={{ marginTop: '10px' }} >
                            <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                            <div className='form_headerRemove__mJ7no' >
                              <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                            </div>

                            <div className="form_form__Sku4X">
                              <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                <div className='form_field__d31bq'>
                                  <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={t4navbutton} onChange={(e) => setT4Navbutton(e.target.value)}></textarea>
                                </div>

                                <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              </div>

                            </div>
                          </div>

                        </div>

                        <div className="media_preview__okAyH">
                          <label>Logo</label>
                          <div className="media_value__\+4KKG ">
                            {memecoinlogoImage.length > 0 ?
                              <div>
                                {memecoinlogoImage.map((image, index) => (
                                  <img
                                    onClick={() => handleSvgClick(0)}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => handleSvgClick(0)} style={{ height: 30, width: 40 }} src={memecoinlogoImage ? memecoinlogoImage : Boomer} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />
                        <span>Background Image</span>
                        <div className="media_preview__okAyH" >
                          <label>Background Image</label>
                          <div className="media_value__\+4KKG ">
                            {memenavbgcolor.length > 0 ?
                              <div>
                                {memenavbgcolor.map((image, index) => (
                                  <img
                                    onClick={() => { handleNavbgcolor(0); setMemebackground(0); }}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => { handleNavbgcolor(0); setMemebackground(0); }} style={{ height: 30, width: 40 }} src={memenavbgcolor.length > 0 ? memenavbgcolor : `url(${Backgroundimage})`} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />

                        <span >Background Color</span>

                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemebackground(1); }}>
                          <label>Background Color</label>
                          <div className="media_value__\+4KKG ">

                            <div style={{ height: 30, width: 40, backgroundColor: memenavbarbgcolor.length == 0 ? '' : memenavbarbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                          </div>


                        </div>
                        {isHeaderDesignText == false ? null :
                          <div id='one' style={{ position: 'relative', }}>
                            <SketchPicker color={memenavbarbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                        }
                      </div> : null}

                    {selectMemeCoinTemplates == 5 ?
                      <div className='editing_content__RXzxk'>

                        <div className='form_sortable__FLtVI'>





                        </div>
                        <label>Powered by </label>
                        <div className='form_listItem__puOqW' style={{ marginTop: '10px' }} >
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>


                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>

                              <div className='form_field__d31bq'>

                                <input placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} onChange={(e) => setT5Nav1(e.target.value)} value={t5Nav1}></input>
                              </div>

                              <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            </div>

                          </div>
                        </div>


                        <br />
                        <label>Buy Button</label>
                        <div className='form_listItem__puOqW' style={{ marginTop: '10px' }} >
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>


                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                              <div className='form_field__d31bq'>

                                <input placeholder="Text" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} onChange={(e) => sett5Nav2(e.target.value)} value={t5nav2}></input>
                              </div>

                              <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            </div>

                          </div>
                        </div>
                        <br />
                        <div className="media_preview__okAyH" >
                          <label>Buy Button Background image</label>
                          <div className="media_value__\+4KKG ">
                            {buybuttnbgImage.length > 0 ?
                              <div>
                                {buybuttnbgImage.map((image, index) => (
                                  <img
                                    onClick={() => { handleBuybuttonImage(0);setMemebuybuttonbackground(1); }}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => { handleBuybuttonImage(0);setMemebuybuttonbackground(1); }}style={{ height: 30, width: 40 }} src={buybuttnbgImage.length > 0 ? buybuttnbgImage : ``} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />
                        <span >Buy Button Background color</span>

                        <div className="media_preview__okAyH" onClick={() => { HandelBuybuttoncolor(); setMemebuybuttonbackground(0); }}>
                          <label>Color</label>
                          <div className="media_value__\+4KKG ">

                            <div style={{ height: 30, width: 40, backgroundColor: buybuttonbgcolor.length == 0 ? '' : buybuttonbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                          </div>


                        </div>
                        {buybuutoncolor == false ? null :
                          <div id='one' style={{ position: 'relative', }}>
                            <SketchPicker color={buybuttonbgcolor} onChange={HandelChangebuybuttoncolor}></SketchPicker></div>
                        }
                        <br />
                        <div className='form_listItem__puOqW' style={{ marginTop: '10px' }} >
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>


                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                              <div className='form_field__d31bq'>

                                <input placeholder="Link" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} onChange={(e) => setTemplate2cmc(e.target.value)} value={template2cmc}></input>
                              </div>

                              <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            </div>

                          </div>
                        </div>
                        <br />
                        <label>Meme Name</label>
                        <div className='form_listItem__puOqW' style={{ marginTop: '10px' }} >
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>


                          <div className="form_form__Sku4X">
                            <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                              <div className='form_field__d31bq'>

                                <input placeholder="Meme Name" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} onChange={(e) => SetMemecoinName(e.target.value)} value={memecoinname}></input>
                              </div>

                              <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                            </div>

                          </div>
                        </div>
                        <br />

                        <div className="media_preview__okAyH">
                          <label>Image</label>
                          <div className="media_value__\+4KKG ">
                            {memecoinlogoImage.length > 0 ?
                              <div>
                                {memecoinlogoImage.map((image, index) => (
                                  <img
                                    onClick={() => handleSvgClick(0)}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => handleSvgClick(0)} style={{ height: 30, width: 40 }} src={memecoinlogoImage ? memecoinlogoImage : Boomer} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />
                        <span>Background Image</span>
                        <div className="media_preview__okAyH" >
                          <label>Background Image</label>
                          <div className="media_value__\+4KKG ">
                            {memenavbgcolor.length > 0 ?
                              <div>
                                {memenavbgcolor.map((image, index) => (
                                  <img
                                    onClick={() => { handleNavbgcolor(0); setMemebackground(1); }}
                                    key={index}
                                    style={{ height: 30, width: 40 }}
                                    src={image}
                                    alt={`Image ${index}`}
                                    className="media_image__kN9DM"
                                  />
                                ))}</div>
                              :
                              <img onClick={() => { handleNavbgcolor(0); setMemebackground(1); }}style={{ height: 30, width: 40 }} src={memenavbgcolor.length > 0 ? memenavbgcolor : `url(${Backgroundimage})`} alt="" className="media_image__kN9DM" />
                            }
                          </div>


                        </div>
                        <br />

                        <span >Background Color</span>

                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemebackground(0); }}>
                          <label> Background Color</label>
                          <div className="media_value__\+4KKG ">

                            <div style={{ height: 30, width: 40, backgroundColor: memenavbarbgcolor.length == 0 ? '' : memenavbarbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                          </div>


                        </div>
                        {isHeaderDesignText == false ? null :
                          <div id='one' style={{ position: 'relative', }}>
                            <SketchPicker color={memenavbarbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                        }
                      </div> : null}

                  </div>

                  : <MemeNavdesing />}

              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme /></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <Memecoin />
          </div>
        </div>
      </div>
    </>
  )
};

export default MemeNavbarEdit