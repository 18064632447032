// import React, { useContext, useState, useRef, useEffect } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';
// import WebPages from '../../Webpages';
// import MyContext from "../../../context/MyContext";
// import MainNavBar from "../../MainNavBar";
// import IntroDesing from "./Indrtodesing";
// import ContentDesign from "../../ContentDesign";
// import CaptionTitle from "../../CaptionTitle";


// const IntroEdit = () => {
//   const {Section2, menu, setmenu, title, setTitle, intro, setIntro, welcometilte, setwelcometitle, navbarmenu, setnavbarmenu, Navbarstring ,contentdesing,setContentdesing,mainnavbarbutton} = useContext(MyContext)
//   const [introedit, setintroedit] = useState(true)
//   const contentEditableRef = useRef(null);
//   const [caretPosition, setCaretPosition] = useState(0);
//   const  contentEditableRef1=useRef(null);
//   const [caretPosition1, setCaretPosition1] = useState(0);







//   const handleIntroInput = () => {
//     const content = contentEditableRef.current.textContent;
//     setIntro(content);
//     setCaretPosition(getCaretPosition());
//   };

//   const handleTitleInput = () => {
//     const content = contentEditableRef1.current.textContent;
//     setTitle(content);
//     setCaretPosition1(getCaretPosition1());
//   };




//   console.log(',,,,,,,,,,,,,,,,,,,,,,,,,', introedit)
//   const Title = [`${welcometilte} ${title}`]



//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (event.key === 'Backspace') {
//         setCaretPosition(getCaretPosition());
//       }
//     };

//     document.addEventListener('keydown', handleKeyDown);

//     return () => {
//       document.removeEventListener('keydown', handleKeyDown);
//     };
//   }, []);


//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (event.key === 'Backspace') {
//         setCaretPosition1(getCaretPosition1());
//       }
//     };

//     document.addEventListener('keydown', handleKeyDown);

//     return () => {
//       document.removeEventListener('keydown', handleKeyDown);
//     };
//   }, []);


//   const getCaretPosition = () => {
//     const selection = window.getSelection();
//     if (selection && selection.rangeCount > 0) {
//       return selection.getRangeAt(0).startOffset;
//     }
//     return 0;
//   };
//   const getCaretPosition1 = () => {
//     const selection = window.getSelection();
//     if (selection && selection.rangeCount > 0) {
//       return selection.getRangeAt(0).startOffset;
//     }
//     return 0;
//   };

//   const setCaretToEnd = () => {
//     const range = document.createRange();
//     const sel = window.getSelection();
//     const lastChild = contentEditableRef.current.lastChild;
//     if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
//       const textLength = lastChild.textContent.length;
//       const offset = Math.min(caretPosition, textLength);
//       range.setStart(lastChild, offset);
//       range.collapse(true);
//       sel.removeAllRanges();
//       sel.addRange(range);
//     }
//   };
//   const setCaretToEnd1 = () => {
//     const range = document.createRange();
//     const sel = window.getSelection();
//     const lastChild = contentEditableRef1.current.lastChild;
//     if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
//       const textLength = lastChild.textContent.length;
//       const offset = Math.min(caretPosition1, textLength);
//       range.setStart(lastChild, offset);
//       range.collapse(true);
//       sel.removeAllRanges();
//       sel.addRange(range);
//     }
//   };
//   useEffect(() => {
//     setCaretToEnd();
//   }, [intro]);
//   useEffect(() => {
//     setCaretToEnd1();
//   }, [title]);




//   return (
//     <div>
//       <MainNavBar />
//       <div style={{ display: 'flex', }}>
//          {mainnavbarbutton==false?
//             <div style={{height:550}}>
//         <div className='editing_wrapper__Wijfx'>
//           <ContentDesign headerText="Hero" />

//           <div className="editing_content__RXzxk">

//               <div className="form_form__Sku4X" >

//                 {contentdesing==false? <div className="form_form__Sku4X">
//                   <label class="form_label__27psn">Header</label>
//                   <div className="form_group__kDXNb">
//                     <div className="form_form__Sku4X">
//                       <div class="form_more__6E9XQ" type="button" id="radix-:rer:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
//                       <div class="form_row__2+BJ3">
//                         <div class="form_field__d31bq form_size--2x2__J8UQE"><div class="picker_preview__QSKw4"><svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 24 24" width="22" fill="#bbb"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z"></path></svg></div></div>
//                         <div className="form_group__kDXNb">
//                           <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
//                             <div class="form_field__d31bq" >
//                               <textarea placeholder="Caption" style={{ height: "32px" }}></textarea>
//                             </div>
//                             <div class="form_field__d31bq">
//                               <div class="">
//                                 <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef1} onInput={handleTitleInput}> {title}</div>
//                               </div>
//                             </div>
//                             <div class="form_field__d31bq">
//                               <div class="">
//                                 <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef} onInput={handleIntroInput}>{intro}</div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>:<IntroDesing/>}



//               </div> 


//           </div>
//         </div>
//         </div>:<div className='editing_wrapper__Wijfx'>Theme</div>}


//         <div style={{ width:"66%", boxShadow: '0 0 0 1px #eaeaea, 0 0 5px rgba(0, 0, 0, .05)', position: 'relative', borderRadius: '10px', background: '#fff', margin: '10px 15px 15px' }}>
//           <WebPages />
//         </div>
//       </div>
//     </div>
//   )
// };

// export default IntroEdit




import React, { useContext, useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import WebPages from '../../Webpages';
import MyContext from "../../../context/MyContext";
import MainNavBar from "../../MainNavBar";
import IntroDesing from "./Indrtodesing";
import ContentDesign from "../../ContentDesign";
import CaptionTitle from "../../CaptionTitle";
import { IntroAddButton } from "./IntroAddButton";
import Theme from "../../Theme";
import MemeHomeEdit from "../../MemeCoin/Save&edit/MemeMainEdit";
import MemeNavbarEdit from "../../MemeCoin/Save&edit/MemeNavbarEdit";
import axios from "axios";
import { useAccount } from 'wagmi'


const IntroEdit = () => {
  const { inttrobtn1, setIntrobtn1,
    introbtn2, setIntrobtn2,
    Section2, menu,
    setmenu, menu1,
    setmenu1, title,
    setTitle, intro,
    setIntro, welcometilte,
    setwelcometitle, navbarmenu,
    setnavbarmenu, Navbarstring,
    contentdesing, setContentdesing,
    addDivIntroButton,images, setImages,
    mainnavbarbutton,introImages,
    introbtn1remove, setIntrobtnremove1,
    introbtn2remove, setIntrobtnremove2, HandelIntro,
    introbuttonapprovel, setIntrobuttonapprovel,
    addintrobutton, setAddintrobutton,
      intromenubgcolor,setIntromenubgcolor,
      intromenutextcolor,setIntromenutextcolor, 
       selectedOption1, setSelectedOption1,
      newIntroImage,setNewIntroImage, memecoin,
      introcaption,setIntrocaption,section2, uniqueID} = useContext(MyContext)

  const [introedit, setintroedit] = useState(true)
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen1, setIsOpen1] = useState(false);
  const contentEditableRef = useRef(null);
  const [caretPosition, setCaretPosition] = useState(0);
  const contentEditableRef1 = useRef(null);
  const [caretPosition1, setCaretPosition1] = useState(0);
  const [introbuttoncount, setItnrobuttoncount] = useState(0);
  const [colorcount,setColorcount]=useState(0)

  // API to store hero content data. for testing purpose using use effect

  const {address} = useAccount()

  useEffect(() => {
    const updateHeroContentdata = async () => {

      const response = axios.post('https://trendifyweb.ai/pyapi/updateHeroContentData', {
        params: {
          address: address,
          siteid: uniqueID,
          menu: menu,
          menu1: menu1,
          intromenubgcolor: intromenubgcolor,
          selectedOption1: selectedOption1,
          introcaption: introcaption,
          title: title,
          intro: intro,
          inttrobtn1: inttrobtn1,
          introbtn2: introbtn2, 
          introImages: introImages,
          newIntroImage: newIntroImage
        }
      })

      console.log('heroapiresponse', response)
    }

    updateHeroContentdata()
}, [newIntroImage])    


  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleOptionClick1 = (option) => {
    setSelectedOption1(option);
    setIsOpen1(false);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handelclickOptionColor=(Color)=>{
    setIntromenubgcolor(Color);
      
  };
  const handelTextOptionColor=(textcolor)=>{
     setIntromenutextcolor(textcolor)
  }


console.log('selectedOption',selectedOption,typeof selectedOption,intromenubgcolor,colorcount)





  const handleIntroInput = () => {
    const content = contentEditableRef.current.textContent;
    setTitle(content);
    setCaretPosition(getCaretPosition());
  };

  const handleIntroTitle = () => {
    const content = contentEditableRef1.current.textContent;
    setIntro(content);
    setCaretPosition1(getCaretPosition1());
  };







  useEffect(() => {
    setCaretToEnd();
  }, [title]);

  useEffect(() => {
    setCaretToEnd1();
  }, [intro]);




  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition(getCaretPosition());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition1(getCaretPosition1());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getCaretPosition = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount >= 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition1 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };

  const setCaretToEnd = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const setCaretToEnd1 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef1.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition1, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };


  const handelmenu = (e, index) => {
    setmenu(e.target.value)
  };

  const handelmenu1 = (e) => {
    setmenu1(e.target.value)
  };

  const handelintrobuttun1 = (e) => {
    setIntrobtn1(e.target.value)
  };

  const handelintrobuttun2 = (e) => {
    setIntrobtn2(e.target.value)
  };
  const handelintroremovebutton1 = () => {
    setIntrobtnremove1(true)
  };

  const handelintroremovebutton2 = () => {
    setIntrobtnremove2(true)
  };

  const hadelIntrobuttouncount = () => {
    if (introbuttoncount < 2) {
      setItnrobuttoncount(introbuttoncount + 1)
    } else {
      setItnrobuttoncount(0)
    }
  }

  const handeladdintrobutton = () => {

    switch (introbuttoncount) {
      case 1:
        setIntrobtnremove1(false);
        setIntrobtn1('');
        break;
      case 2:
        setIntrobtnremove2(false);
        setIntrobtn2('');
        break;
      default:
        console.log('Error')


    }

  };

  const handleAddintrobutton = (newItem) => {
    setAddintrobutton(prevMenu => [...prevMenu, newItem]);
    setIntrobuttonapprovel(true)
  };
  const handelIntrobuttoncontent = (e, index) => {
    const updatedMenu = [...addintrobutton];
    updatedMenu[index] = e.target.value;
    setAddintrobutton(updatedMenu);
  };

  const handleIntroButtonRemove = (indexToRemove) => {
    setAddintrobutton(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });
  }
  console.log(addintrobutton.length, introbtn1remove, introbtn2remove, 'Remove')

  const [randomIndex, setRandomIndex] = useState();


    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * 5);
        setRandomIndex(randomIndex);
    }, [images]);
    // const randomIndex = Math.floor(Math.random() * 5);
    const Imageupdate = images;
    console.log('Imageupdate', newIntroImage,newIntroImage.length)
    const handelupdate = () => {
        return Imageupdate
    }

    useEffect(() => {
        handelupdate()
    }, []);

    const handleSvgClick = (index) => {
      // Open file picker to select an image
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          const updatedTrustLogo = [...newIntroImage];
          updatedTrustLogo[index] = event.target.result;
          setNewIntroImage(updatedTrustLogo);
          console.log('updatedTrustLogo',updatedTrustLogo)
        };

        reader.readAsDataURL(file);
      };
      input.click();
    };
    console.log( 'selectedOption1', selectedOption1, typeof selectedOption1)

    const handelIntrocaption=(Caption)=>{
        setIntrocaption(Caption.target.value);
    };
    console.log('introcaption',introcaption)
    console.log('Section2',section2)
  return (

    <div>
      {memecoin == 'true' ? <MemeNavbarEdit/> : 
      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?

              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Hero" />
                {contentdesing == false ?
                  <div className="editing_content__RXzxk">
                    <div>
                      <div className="form_form__Sku4X">
                        <label class="form_label__27psn">Badge</label>
                        <div className="form_group__kDXNb">
                          <div className="form_form__Sku4X">
                            <div className="d-flex">
                              <div className="form_field__d31bq" >
                                <label class="form_label__27psn">Text</label>
                                <textarea placeholder="Text" style={{ minHeight: '32px', padding: "5px 10px", resize: 'none', overflowY: 'hidden' }} onChange={(e) => handelmenu(e,)}>{menu}</textarea>
                              </div>
                              <div class="form_field__d31bq" >
                                <label class="form_label__27psn">CTA Label</label>
                                <textarea placeholder="Label" style={{ minHeight: '32px', padding: "5px 10px", resize: 'none', overflowY: 'hidden' }} onChange={(e) => handelmenu1(e)}>{menu1}
                                </textarea>
                              </div>
                            </div>
                            <div className="form_field__d31bq" >
                              <label class="form_label__27psn">Url</label>
                              <textarea style={{ minHeight: '32px', padding: "5px 10px", resize: 'none', overflowY: 'hidden' }}></textarea>
                            </div>
                            <div className="d-flex">

                              <div className="form_field__d31bq">
                                <label className="form_label__27psn">Style</label>
                                <span className="form_value__oIwpW">
                                  <div className="select css-1mu46ou-container" onClick={toggleDropdown}>
                                    <div className="css-1ttbk4t-control">
                                      <div className="css-10ec4sc">
                                        <div className="css-1wa3eu0-placeholder">
                                          {selectedOption || 'Select...'}
                                        </div>
                                      </div>
                                      <div className="css-1wy0on6">
                                        <span className="css-1hyfx7x"></span>
                                        <div className="css-shfdzl-indicatorContainer">
                                          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
                                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                                {isOpen && (
                                  <div className="dropdown-options">

                                    <div onClick={() => {handleOptionClick("Blue");handelclickOptionColor("#edf2ff");handelTextOptionColor('#2138a8')}}>Blue</div>
                                    <div onClick={() => {handleOptionClick("Green");handelclickOptionColor("#ebfbee");handelTextOptionColor('#13411c')}}>Green</div>
                                    <div onClick={() => {handleOptionClick("Purple");handelclickOptionColor("#f8f0fc");handelTextOptionColor('#712382')}}>Purple</div>
                                    <div onClick={() => {handleOptionClick("white");handelclickOptionColor('white');handelTextOptionColor('black')}}>Default</div>

                                  </div>
                                )}
                              </div>

                              <div className="form_field__d31bq">
                                <label className="form_label__27psn">Style</label>
                                <span className="form_value__oIwpW">
                                  <div className="select css-1mu46ou-container" onClick={toggleDropdown1}>
                                    <div className="css-1ttbk4t-control">
                                      <div className="css-10ec4sc">
                                        <div className="css-1wa3eu0-placeholder">
                                          {selectedOption1 || 'Select...'} {/* Show selected option or default text */}
                                        </div>
                                      </div>
                                      <div className="css-1wy0on6">
                                        <span className="css-1hyfx7x"></span>
                                        <div className="css-shfdzl-indicatorContainer">
                                          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
                                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                                {isOpen1 && (
                                  <div className="dropdown-options">
                                    <div className="option" onClick={() => handleOptionClick1("A")} >A</div>
                                    <div className="option" onClick={() => handleOptionClick1("B")}>B</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_form__Sku4X" >

                        <div className="form_form__Sku4X">
                          <label class="form_label__27psn">Header</label>
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_more__6E9XQ" type="button" id="radix-:rer:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                              <div class="form_row__2+BJ3">
                                <div class="form_field__d31bq form_size--2x2__J8UQE"><div class="picker_preview__QSKw4"><svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 24 24" width="22" fill="#bbb"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z"></path></svg></div></div>
                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >

                                      <textarea placeholder="Caption" style={{ height: "32px" }} value={introcaption} onChange={(e)=>handelIntrocaption(e)}></textarea>
                                    </div>


                                    <div class="form_field__d31bq">
                                      <div class="">
                                        <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef} onInput={handleIntroInput}>{title}</div>
                                      </div>
                                    </div>
                                    <div class="form_field__d31bq">
                                      <div class="">
                                        <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef1} onInput={handleIntroTitle}>{intro}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <label class="form_label__27psn">Items</label>




                        {introbtn1remove == false ?
                          <div className='form_sortable__FLtVI'>
                            <div className='form_listItem__puOqW'>
                              <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                              <div className='form_headerRemove__mJ7no' onClick={() => handelintroremovebutton1()}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                              </div>
                              <div className="form_form__Sku4X">
                                <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                                  <div className='form_field__d31bq'>
                                    <textarea style={{ height: 32, padding: 5 }} onChange={(e) => handelintrobuttun1(e)} value={inttrobtn1}></textarea>
                                  </div>
                                  <div className='form_field__d31bq'>
                                    <input type="url" />
                                  </div>
                                  <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                </div>

                              </div>
                            </div>
                          </div> : null}

                        {introbtn2remove == false ?
                          <div className='form_sortable__FLtVI'>
                            <div className='form_listItem__puOqW'>
                              <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                              <div className='form_headerRemove__mJ7no' onClick={() => handelintroremovebutton2()}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                              </div>
                              <div className="form_form__Sku4X">
                                <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                                  <div className='form_field__d31bq'>
                                    <textarea style={{ height: 32, padding: 5 }} onChange={(e) => handelintrobuttun2(e)} value={introbtn2}></textarea>
                                  </div>
                                  <div className='form_field__d31bq'>
                                    <input type="url"  />
                                  </div>
                                  <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                </div>

                              </div>
                            </div>
                          </div> : null}


                        {addintrobutton.map((button, index) => (
                          <div className='form_sortable__FLtVI' key={index} style={{ display: introbuttonapprovel == false ? 'none' : '' }}>
                            <div className='form_listItem__puOqW'>
                              <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                              <div className='form_headerRemove__mJ7no' onClick={() => handleIntroButtonRemove()}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                              </div>
                              <div className="form_form__Sku4X">
                                <div className='form_row__2\+BJ3 form_hasMore__JC1Yq ' style={{ width: "92%" }}>
                                  <div className='form_field__d31bq'>
                                    <textarea style={{ height: 32, padding: 5 }} onChange={(e) => handelIntrobuttoncontent(e, index)} value={addintrobutton[index]}>{button}</textarea>
                                  </div>
                                  <div className='form_field__d31bq'>
                                    <input  />
                                  </div>
                                  <div class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                </div>

                              </div>
                            </div>
                          </div>
                        ))}
                        {/* {(introbtn1remove == false) && (introbtn2remove == false) ?
                            <button class="button1 button--small mb-3 " style={{ alignSelf: 'flex-end', marginTop: '15px' }} onClick={() => handleAddintrobutton()}>Add Item</button>
                            : null} */}
                        {((introbtn2remove == true) && (introbtn1remove == true) && (addintrobutton.length == 0)) ?
                          <div className='form_emptyList__KNV3N'>
                            <div>Your list is empty</div>
                            <button class="button1 button--small" onClick={() => { handeladdintrobutton(); hadelIntrobuttouncount(); }}>Add Item</button>
                          </div> :

                          <button class="button1 button--small mb-3 " style={{ alignSelf: 'flex-end', marginTop: '15px' }} onClick={() => handleAddintrobutton()}>Add Item</button>

                        }


                        <div className="form_field__d31bq ">
                          <div className="media_preview__okAyH">
                            <label>Picture</label>
                            <div className="media_value__\+4KKG ">
                              {newIntroImage.length>0?
                               <div>
                               {newIntroImage.map((image, index) => (
                                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                <img
                                onClick={()=>handleSvgClick(0)}
                                  key={index}
                                  style={{ height: 30, width: 40 }}
                                  src={image}
                                  alt={`Image ${index}`}
                                  className="media_image__kN9DM"
                                />
                              ))}</div>
                            // <img onClick={(e)=>handleSvgClick(e)} style={{height:30,width:40}} src={newIntroImage[0]} alt="" className="media_image__kN9DM" />
                                  :
                              <img onClick={()=>handleSvgClick(0)} style={{height:30,width:40}}src={introImages} alt="" className="media_image__kN9DM" />
                              }
                              </div>
                          </div>
                        </div>


                      </div>


                    </div>

                  </div> : <IntroDesing />}
              </div>
              : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme/></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <WebPages />
          </div>
        </div>
      </div>
    }
      
    </div>
  )
};

export default IntroEdit


