import React, { useEffect, useState } from "react";

import style from '../assets/CSS/Dashboard.module.css'
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Logo from '../assets/images/FaviconNew.png'
import { useContext } from "react";
import MyContext from "../context/MyContext";

import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCopy } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";







const Dashboardsite = () => {

    const { purchaseplane, setPurchaseplane, subdomain, subdomain1, randomId, uniqueID, setShowWebsite, setShowSites, showSites, showWebsite } = useContext(MyContext)


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [website, setwebsite] = useState(false);
    const [websitehistory, setwebsitehistory] = useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleCopyClick = (randomid) => {
        const textToCopy = randomid;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                // alert('Text copied to clipboard');
                setTimeout(() => {
                    setAnchorEl(null);
                }, 500)
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });

    };

    // const RandomId=purchaseplane.map((item)=>{
    //     return item?.randomid
    // });
    //  console.log('ID',RandomId)

    const HandelPlanepurachasehistory = async () => {
        // setShowWebsiteHistory(true);
        // setShowWebsite(true);
        setShowSites(true);


        const savedValue = localStorage.getItem('wagmi.store');

        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value

        let useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            console.log('useradderess', useradderess)
        } else {
            alert('Please connect your wallet')

        }

        const url = new URL('https://trendifyweb.ai/pyapi/getpublishedsites');
        url.searchParams.append('address', useradderess);


        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });


        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        // Parse the JSON response
        const res = await response.json();
        console.log('Response', res);

        // Extract data and update the state
        const data = res?.data;
        setwebsitehistory(data);
        
        



    };
    const HandelPlanepurachase = async () => {

        const savedValue = localStorage.getItem('wagmi.store');
        // console.log('downloadaddressValue', address)
        // console.log('savedaddressValue  wg', typeof savedValue)
        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value

        let useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            console.log('useradderess', useradderess)
            // alert(`storeaddvalue is greater than 0 ${useradderess}`)
        } else {
            alert('Please connect your wallet')

        }
        const Response = await fetch('https://trendifyweb.ai/pyapi/getpublishedsites', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: useradderess
            })
        })
        const res = await Response.json()
        console.log('Responce', res)
        const Data = res?.data
        setPurchaseplane(Data)
        alert(Data)


    };

    useEffect(()=>{
        HandelPlanepurachasehistory();
    },[])

    const HandelDeleteSite = async (RandomId, ID) => {
        console.log('RandomId', RandomId,ID)
        const savedValue = localStorage.getItem('wagmi.store');

        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value

        let useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            alert('Are you sure you want to delete this site?');
            const Deletefile = await fetch('https://trendifyweb.ai/pyapi/delete-site', {

                method: 'POST',
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    filename: `${RandomId}.html`,
                    address: useradderess,
                    randomid: ID,
                    url: RandomId,



                })
            })
            const Data = await Deletefile.json();
            console.log('Data', Data)
            if (Data.success) {
                
                alert('Website Deleted Successfully');
                HandelPlanepurachasehistory();


            } else {
                alert(`Failed: ${Data.message}`);

            }
        } else {
            alert('Please connect your wallet')
        }


    };


    

   

    
    console.log('website',purchaseplane);

     
        
          
  
    

    console.log('showSites', showSites);
    return (
        <div style={{ backgroundColor: 'transparent' ,overflow: 'hidden'}} >
                     
            <div style={{ flexDIrection: 'column', background: "transparent" }}>

                <div className={style.subheader}>

                    <div className={style.subheader.wr}>
                        <div className={style.subheader__meta}>
                            <h2 style={{ marginLeft: "10px" }}>

                                <img src={Logo} height="25px" width="25px" class="me-1" alt='logo' />

                            </h2>
                            <div class={style.subheaderActions}>
                                {/* <input className={style.subheaderSearch} placeholder="Type to search..." value=""></input> */}
                                <a className={`${style.button} ${style['button--primary']}`} onClick={() => { setShowWebsite(false); setShowSites(false); window.location.pathname = '/' }}>Generate Site<svg width="16" height="16" viewBox="0 0 20 20" fill="red" xmlns="http://www.w3.org/2000/svg" style={{ paddingLeft: '2px' }}>
                                    <path d="M14.1274 1.01957V1.01957C14.4612 3.69008 16.5647 5.79356 19.2352 6.12736V6.12736V6.12736C16.5647 6.46118 14.4612 8.56466 14.1274 11.2352V11.2352V11.2352C13.7936 8.56471 11.6901 6.46117 9.01968 6.12733V6.12733V6.12733C11.6901 5.79355 13.7936 3.69001 14.1274 1.01957V1.01957Z" fill="var(--bg)"></path>
                                    <path d="M4.5689 6.01362V6.01362C4.80125 7.87244 6.2654 9.33656 8.12422 9.5689V9.5689V9.5689C6.2654 9.80126 4.80125 11.2654 4.56889 13.1242V13.1242V13.1242C4.33655 11.2654 2.87245 9.80126 1.01368 9.56889H1.01368V9.56889C2.87245 9.33656 4.33655 7.87239 4.5689 6.01362V6.01362Z" fill="var(--bg)"></path>
                                    <path d="M9.67949 13.0102V13.0102C9.85394 14.4058 10.9532 15.5051 12.3488 15.6795V15.6795V15.6795C10.9532 15.854 9.85394 16.9533 9.67949 18.3489V18.3489V18.3489C9.50504 16.9533 8.4058 15.854 7.01023 15.6795V15.6795V15.6795C8.4058 15.5051 9.50505 14.4058 9.67949 13.0102V13.0102Z" fill="var(--bg)"></path>
                                </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div style={{ display: 'flex', flexWrap: 'wrap' }} className="col-11">
                    {websitehistory?.map((item, index) => (
                        <div key={index} className={style.wr} style={{ width: '33%', padding: '10px' }}>
                            <div className={style.home_container__3PaTk}>
                                <div className={style.home_item__dpHbs}>
                                    <Card variant="outlined" sx={{ minWidth: '100%' }}>
                                        <Box sx={{ p: 2 }} height='270px' minWidth='370px'>
                                            <Stack>
                                                <Typography><p><span style={{ fontWeight: 800 }}>ID: </span>{item?.randomid}</p></Typography>
                                                <Typography>
                                                    <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span style={{ fontWeight: 800 }}>Url:</span>{item?.url}
                                                        <span>
                                                            <button onClick={() => handleCopyClick(item?.url)}>
                                                                <Button aria-describedby={id} variant="" onClick={handleClick}>
                                                                    <FontAwesomeIcon icon={faCopy} />
                                                                </Button>
                                                                <Popover
                                                                    id={id}
                                                                    open={open}
                                                                    anchorEl={anchorEl}
                                                                    onClose={handleClose}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                >
                                                                    <Typography sx={{ p: 1 }}>Copied</Typography>
                                                                </Popover>
                                                            </button>
                                                        </span>
                                                    </p>
                                                </Typography>
                                                <Typography><p><span style={{ fontWeight: 800 }}>Plans: </span>{item?.plans}</p></Typography>
                                                <Typography><p><span style={{ fontWeight: 800 }}>Purchase-Date: </span>{item?.purchase}</p></Typography>
                                                <Typography><p><span style={{ fontWeight: 800 }}>Expiry-Date: </span>{item?.expiry}</p></Typography>
                                                <Typography><button type="button" class="btn btn-outline-danger rounded" onClick={() => {HandelDeleteSite(item?.url, item?.randomid);}}>Delete</button></Typography>
                                            </Stack>
                                        </Box>

                                    </Card>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>




            </div>





            <footer class="footer" style={{ position:websitehistory.length<=3? "absolute":"relative", bottom: "-0%", width: '100%' }}>
                <div class="wr">
                    <div class="footerContent">

                        <div>
                            <div class="_c0e4633f ">© 2024 Trendifyweb.ai</div>
                            <div class="_a6d0f97b mt-1" >
                            </div>

                        </div>
                        <div class="footerList">
                            <ul><li>Home</li>
                            </ul>
                            <ul><li>Pricing</li>

                            </ul>
                            <ul>
                                <li>Features</li>
                            </ul>
                            <ul><li>EN</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};

export default Dashboardsite;