import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image1 from '../assets/images/MemeCoinTemplateMain.png'
import Image2 from '../assets/images/MemeCOinTemplate1.png'
import Image3 from '../assets/images/MemeCoinTemplate2.png'
import Image4 from '../assets/images/MemeCoinTemplate3.png'
import Image5 from '../assets/images/MemeCoinTemplate4.png'
import Image6 from '../assets/images/MemeCoinTemplate5.png'
import MyContext from '../context/MyContext';
import Background from '../assets/images/Memeselectbg.png'
import '../assets/CSS/landingpage.css'


const MemeTemplates=(props)=>{
    const {setSelectMemeCoinTemplates,setShowWebsite}=useContext(MyContext);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const ShowMemeWebsite=()=>{
        setTimeout(()=>{
            setShowWebsite(true)
        },2000)
    }

    return(
        <>
        <div
      // {...props}
      
      //  dialogClassName="modal-90w"
      //   aria-labelledby="example-custom-modal-styling-title"
      //   style={{backgroundColor:'#174744'}}
      style={{
         backgroundImage: `url(${Background})`,
        width: '100%',
        position: 'relative',
        maxWidth: '100%',
        overflow: 'hidden',
        height: '100%',
        objectFit: 'cover',
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    }}
    >
     
      <Modal.Body style={{justifyContent:'space-between',margin:'10px',display:'flex',alignItems:"center",flexDirection:'column',flexDirection:'column',gap:'10px'}}>
        <span   style={{
           color: '#f3e6e6',
           fontSize: '48px',
           fontWeight: '600px',
           fontFamily: "Inter",
           textShadow:' 0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}
           
        >Choose a template that fits your style!</span>
        <div className='SelectTemplate'>
       <img src={Image1} alt='image1' width={'350px'} height={'300px'}  style={{margin:'10px',cursor:'pointer',borderRadius:"8px",border: '3px solid rgb(154 207 207)',}} onClick={()=>{setSelectMemeCoinTemplates(1);ShowMemeWebsite()}}></img>
       <img src={Image5} alt='image1'width={'350px'} height={'300px'}  style={{margin:'10px',cursor:'pointer', borderRadius:"8px",border: '3px solid rgb(154 207 207)',}} onClick={()=>{setSelectMemeCoinTemplates(5);ShowMemeWebsite()}}></img>
       <img src={Image2} alt='image1' width={'350px'} height={'300px'}   style={{margin:'10px',cursor:'pointer', borderRadius:"8px",border: '3px solid rgb(154 207 207)',}} onClick={()=>{setSelectMemeCoinTemplates(2);ShowMemeWebsite()}} ></img>

       
       </div>

       <div  className='SelectTemplate'>
       <img src={Image6} alt='image1'width={'350px'} height={'300px'}  style={{margin:'10px',cursor:'pointer', borderRadius:"8px",border: '3px solid rgb(154 207 207)',}} onClick={()=>{setSelectMemeCoinTemplates(6);ShowMemeWebsite()}}></img>

       <img src={Image3} alt='image1' width={'350px'} height={'300px'}  style={{margin:'10px',cursor:'pointer', borderRadius:"8px",border: '3px solid rgb(154 207 207)',}}  onClick={()=>{setSelectMemeCoinTemplates(3);ShowMemeWebsite()}}></img>

       <img src={Image4} alt='image1' width={'350px'} height={'300px'}   style={{margin:'10px',cursor:'pointer', borderRadius:"8px",border: '3px solid rgb(154 207 207)',}} onClick={()=>setShow(true)} ></img>
       </div>

       
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
      <Modal show={show} onHide={handleClose} style={{top:'35%',bottom:'25%',boxShadow:"none", backgroundColor:'transparent'}}>
        
        <Modal.Body style={{
           color:'#f3e6e6',
           fontSize:"48px",
           fontWeight:"600px",
           fontFamily:"Inter",
           textShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}>Coming Soon..</Modal.Body>
        
      </Modal>
    </div>
   
        </>
    )
};

export default MemeTemplates
