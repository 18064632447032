import React, { useContext, useEffect, useRef, useState } from 'react'
import '../assets/CSS/Mainnabar.css'
import Downloadsourcecode from './Download'
import Logo from '../assets/images/FaviconNew.png'
import MyContext from '../context/MyContext'
import DownloadMemecoinTemplate from './DownloadMemecoin'
import PublishSite from './publishSite'
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPencil } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'


const MainNavBar = () => {
    const [backcolor, setbackcolor] = useState('black')
    const [activeTab, setActiveTab] = useState('content');
    const { mainnavbarbutton, setMainnavbarbutton, memecoin, setPurchaseplane,
        websiteName, setWebsiteName,
        uniqueID
    } = useContext(MyContext);
    const [googletransalte,setGoogletransalte]=useState(false)

    const { open } = useWeb3Modal();
    const { address, isConnected } = useAccount();
    const menuRef = useRef(null);



    const handelContent = () => {
        setMainnavbarbutton(false)
    };
    const handelDesing = () => {
        setMainnavbarbutton(true)
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
        handelDesing();
        handelContent();

    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleConnectWallet = async () => {
        open();
    };

    // useEffect(() => {
    //     if (isConnected) {
    //         setConnectedAddress(address);
    //         console.log("Connected Address:", address);

    //     }
    // }, [isConnected, address]);

    const open1 = Boolean(anchorEl);
    const id = open1 ? 'simple-popper' : undefined;

    const HandelPlanepurachase = async () => {

        const savedValue = localStorage.getItem('wagmi.store');
        // console.log('downloadaddressValue', address)
        // console.log('savedaddressValue  wg', typeof savedValue)
        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value

        let useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            console.log('useradderess', useradderess)
            // alert(`storeaddvalue is greater than 0 ${useradderess}`)
        } else {
            alert('Please connect your wallet')

        }
        const Response = await fetch('https://trendifyweb.ai/pyapi/getpublishedsites', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: useradderess
            })
        })
        const res = await Response.json()
        console.log('Responce', res)
        const Data = res?.data
        setPurchaseplane(Data)


    }
    const [anchorElSite, setAnchorElSite] = React.useState(null);

    const handleClickSite = (event) => {
        setAnchorElSite(anchorEl ? null : event.currentTarget);
    };

    const handleCloseSite = () => {
        setAnchorElSite(null);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleCloseSite();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const openSite = Boolean(anchorElSite);
    const idSite = openSite ? 'simple-popper' : undefined;

    const SaveWebsiteName = (e) => {

        setWebsiteName(e.target.value)
    }

    // API to get data from database
    const handleDashboardClick = async () => {

        const response = await axios.get('https://trendifyweb.ai/pyapi/getWebsiteData', {
            params: {
                address: address,
                siteid: uniqueID
            }
        })
        console.log('getWebsiteDataResponse', response)

    }

    return (
        < >

            <div className='header_header__-wvTX px-2 p-1 sticky-top' >
                <img src={Logo} height="25px" width="25px" class="me-1" alt='logo' />
                <div class="header_section__sW6qw ms-2 ">
                    <button class="header_menu__aNf4u" type="button" id="radix-:r0:" aria-haspopup="menu" aria-expanded="false" data-state="closed">
                        <svg width="12px" height="12px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M12.99.77H1.01a1.015,1.015,0,0,0-1.01,1V2.18A1.011,1.011,0,0,0,1.01,3.189H12.99A1.011,1.011,0,0,0,14,2.18V1.772A1.015,1.015,0,0,0,12.99.77Z" fill="currentColor" />
                            <path d="M12.99,5.7H1.01a1.015,1.015,0,0,0-1.01,1v.407a1.011,1.011,0,0,0,1.01,1.01H12.99A1.011,1.011,0,0,0,14,7.113V6.705A1.014,1.014,0,0,0,12.99,5.7Z" fill="currentColor" />
                            <path d="M12.987,10.637H1.01a1.015,1.015,0,0,0-1.01,1v.406a1.011,1.011,0,0,0,1.01,1.01H12.987A1.011,1.011,0,0,0,14,12.046v-.407A1.014,1.014,0,0,0,12.987,10.637Z" fill="currentColor" /></svg>
                        <span className='ms-2'> <button aria-describedby={id} type="button" >
                            Menu
                        </button></span></button>



                    <button class="header_active__SotvQ" style={{
                        backgroundColor: mainnavbarbutton == false ? 'black' : '',
                        color: mainnavbarbutton == false ? 'white' : 'black',
                    }}><span onClick={() => handelContent()} >Content</span></button>
                    <button style={{
                        backgroundColor: mainnavbarbutton == true ? 'black' : '',
                        color: mainnavbarbutton == true ? 'white' : 'black',
                    }} ><span onClick={() => handelDesing()}>Theme</span></button>


                </div>
                {/* <div style={{ overflow: 'hidden', width: 100, position: 'relative', left: '5px' }} >
                    <button aria-describedby={idSite} type="button" onClick={handleClickSite} style={{ overflow: 'hidden', width: 100, position: 'relative', left: '5px', }}>
                        {websiteName}
                    </button>
                    <Popper ref={menuRef} id={idSite} open={openSite} anchorEl={anchorElSite} sx={{ zIndex: 3000, top: 10, left: 20, position: 'relative' }}>
                        <Box sx={{ p: 1, bgcolor: 'background.paper', boxShadow: 1, top: 10, }}>
                            <p>Rename Website</p>
                            <TextField type='text' value={websiteName} onChange={SaveWebsiteName}></TextField>
                            <br />
                            <Button onClick={handleCloseSite} sx={{ color: 'black', backgroundColor: 'gray', top: 5, height: 30 }}>Save
                            </Button>

                        </Box>
                    </Popper>

                </div>
                <FontAwesomeIcon onClick={handleClickSite} icon={faPencil} style={{ marginLeft: '0.5rem' }} /> */}


                <div class="header_actions__CVfcP">
                    {/* <div className='d-flex justify-content-end'>

                        <button className='buttoncolor' style={{ padding: "8px 16px" }}  onClick={()=>setGoogletransalte(!googletransalte)}>EN</button>
                        <div
                            style={{
                                backgroundColor: '#269D7E',
                                padding: '0px 8px',
                                marginRight: '10px',
                                borderRadius: '8px',
                                display: googletransalte == false ? 'none' : 'block'
                            }}
                            id="google_translate_element"

                        ></div>
                        <Helmet>
                            <script type="text/javascript">
                                {`function googleTranslateElementInit() {
                        new google.translate.TranslateElement(
                            { pageLanguage: 'en' },
                            'google_translate_element'
                        );
                    }`
                                }
                            </script>
                            <script
                                type="text/javascript"
                                src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                            ></script>
                        </Helmet>
                    </div> */}

                    <div className='d-flex justify-content-end'>
                        <Link to='/subscriptions'> <button className='buttoncolor' style={{ padding: "8px 16px" }} >Upgrade</button>
                        </Link>
                    </div>

                    <div className='d-flex justify-content-end'>
                        <Link to='/Dashboard'> <button className='buttoncolor' style={{ padding: "8px 16px" }}  onClick={() => handleDashboardClick()}>Dashboard</button>
                        </Link>
                    </div>
                    {memecoin == 'true' ? <Downloadsourcecode /> :
                        <Downloadsourcecode />
                    }
                    <PublishSite />
                    <div className='d-flex justify-content-end'>
                        <button className='buttoncolor' style={{ padding: "8px 16px" }} onClick={() => handleConnectWallet()}>
                            {isConnected ? `${address.substring(0, 4)}...${address.substring(38)}` : "Connect"}
                        </button>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MainNavBar