


import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCircleQuestion, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import WebPages from '../../Webpages';
import MyContext from "../../../context/MyContext";
import MainNavBar from "../../MainNavBar";
import FaqDesing from "./Faqdesing";
import FAQs from "../../FAQs";
import ContentDesign from "../../ContentDesign";
import CaptionTitle from "../../CaptionTitle";
import Theme from "../../Theme";
import axios from "axios";
import { useAccount } from "wagmi";


const FaqEdit = () => {
  const { contentdesing, mainnavbarbutton, faq, setFaq ,faqA1,setfaqA1,  faqA2,setfaqA2,
    faqA3,setfaqA3,
    faqA4,setfaqA4,
    faqQ1,setFaqQ1,
    faqQ2,setFaqQ2,
    faqQ3,setFaqQ3,
    faqQ4,setFaqQ4,
    removeFaq1,setRemovefaq1,
    removeFaq2,setRemovefaq2,
    removeFaq3,setRemovefaq3,
    removeFaq4,setRemovefaq4,
    faqtitle,setFaqtitle,
      faqCaption,setFaqCaption,
      newFaq,setNewFaq, 
      faqIntro,setFaqIntro, uniqueID} = useContext(MyContext);
  const [faqedit, setfaqedit] = useState(true);

  // API to store faq content data. For testing purpose using use effect

  const { address } = useAccount()

  useEffect(() => {

    const updateFaqContentData = async () => {
      const response = await axios.post('https://trendifyweb.ai/pyapi/updateFaqContentData', {
        params: {
          address: address,
          siteid: uniqueID,
          faqCaption: faqCaption,
          faqtitle: faqtitle,
          faqIntro: faqIntro,
          faqQ1: faqQ1,
          faqA1: faqA1,
          faqQ2: faqQ2,
          faqA2: faqA2,
          faqQ3: faqQ3,
          faqA3: faqA3,
          faqQ4: faqQ4,
          faqA4: faqA4,
          removeFaq1: removeFaq1,
          removeFaq2: removeFaq2,
          removeFaq3: removeFaq3,
          removeFaq4: removeFaq4,
          newFaq: newFaq
        }
      });

      console.log(response);
    }
    
    updateFaqContentData();
    }, [faqCaption])

  const  contentEditableRef1=useRef(null);
  const [caretPosition1, setCaretPosition1] = useState(0);


  const  contentEditableRef2=useRef(null);
  const [caretPosition2, setCaretPosition2] = useState(0);
  const  contentEditableRef3=useRef(null);
  const [caretPosition3, setCaretPosition3] = useState(0);
  const  contentEditableRef4=useRef(null);
  const [caretPosition4, setCaretPosition4] = useState(0);
  const  contentEditableRef5=useRef(null);
  const [caretPosition5, setCaretPosition5] = useState(0);
  const  contentEditableRef6=useRef(null);
  const [caretPosition6, setCaretPosition6] = useState(0);
  const  contentEditableRef7=useRef(null);
  const [caretPosition7, setCaretPosition7] = useState(0);
  const  contentEditableRef8=useRef(null);
  const [caretPosition8, setCaretPosition8] = useState(0);
  const  contentEditableRef9=useRef(null);
  const [caretPosition9, setCaretPosition9] = useState(0);
  const  contentEditableRef10=useRef(null);
  const [caretPosition10, setCaretPosition10] = useState(0);

  
  const handelfaq1 = (e) => {
    const content = contentEditableRef1.current.textContent;
    setFaqQ1(content);
    setCaretPosition1(getCaretPosition1());
   
  };
   
  const handelfaq2 = (e) => {
    const content = contentEditableRef2.current.textContent;
    setfaqA1(content);
    setCaretPosition2(getCaretPosition2());
   
  };
  const handelfaq3 = (e) => {
    const content = contentEditableRef3.current.textContent;
    setFaqQ2(content);
    setCaretPosition3(getCaretPosition2());
   
  };
  const handalfaq4 = (e) => {
    const content = contentEditableRef4.current.textContent;
    setfaqA2(content);
    setCaretPosition4(getCaretPosition4());
   
  };
  const handalfaq5= (e) => {
    const content = contentEditableRef5.current.textContent;
    setFaqQ3(content);
    setCaretPosition5(getCaretPosition5());
   
  };

  const handalfaq6 = (e) => {
    const content = contentEditableRef6.current.textContent;
    setfaqA3(content);
    setCaretPosition6(getCaretPosition6());
   
  };
 
  const handalfaq7 = (e) => {
    const content = contentEditableRef7.current.textContent;
    setFaqQ4(content);
    setCaretPosition7(getCaretPosition7());
   
  };

  const handalfaq8 = (e) => {
    const content = contentEditableRef8.current.textContent;
    setfaqA4(content);
    setCaretPosition8(getCaretPosition8());
   
  };
  const handalfaq9 = (e) => {
    const content = contentEditableRef9.current.textContent;
    setFaqtitle(content);
    setCaretPosition9(getCaretPosition9());
   
  };
  const handalfaq10 = (e) => {
    const content = contentEditableRef10.current.textContent;
    setFaqIntro(content);
    setCaretPosition10(getCaretPosition10());
   
  };
  useEffect(() => {
    setCaretToEnd1();
  }, [faqQ1]);

  useEffect(() => {
    setCaretToEnd2();
  }, [faqA1]);
   
  useEffect(() => {
    setCaretToEnd3();
  }, [faqQ2]);
  useEffect(() => {
    setCaretToEnd4();
  }, [faqA2]);
   
  useEffect(() => {
    setCaretToEnd5();
  }, [faqQ3]);
  useEffect(() => {
    setCaretToEnd6();
  }, [faqA3]);
  useEffect(() => {
    setCaretToEnd7();
  }, [faqQ4]);
  useEffect(() => {
    setCaretToEnd8();
  }, [faqA4]);
  
  useEffect(() => {
    setCaretToEnd9();
  }, [faqtitle]);
   
  useEffect(() => {
    setCaretToEnd10();
  }, [faqIntro]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition1(getCaretPosition1());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition2(getCaretPosition2());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
   useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition3(getCaretPosition3());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
   useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition4(getCaretPosition4());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); 
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition5(getCaretPosition5());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
   useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition6(getCaretPosition6());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
   useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition7(getCaretPosition7());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); 

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition8(getCaretPosition8());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition8(getCaretPosition9());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        setCaretPosition8(getCaretPosition10());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const getCaretPosition1 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition2 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; 
  const getCaretPosition3 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition4 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition5 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition6 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  }; const getCaretPosition7 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
   const getCaretPosition8 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition9 = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };
  const getCaretPosition10= () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0).startOffset;
    }
    return 0;
  };

  const setCaretToEnd1 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef1.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition1, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const setCaretToEnd2 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef2.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition2, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const setCaretToEnd3 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef3.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition3, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };const setCaretToEnd4 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef4.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition4, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };const setCaretToEnd5 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef5.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition5, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };const setCaretToEnd6 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef6.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition6, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };const setCaretToEnd7 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef7.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition7, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const setCaretToEnd8 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef8.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition8, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; 
  const setCaretToEnd9 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef9.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition9, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; 
  const setCaretToEnd10 = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChild = contentEditableRef10.current.lastChild;
    if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
      const textLength = lastChild.textContent.length;
      const offset = Math.min(caretPosition10, textLength);
      range.setStart(lastChild, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }; 
  const RemoveFaq1=()=>{
    setRemovefaq1(true);
   };
   const RemoveFaq2=()=>{
    setRemovefaq2(true);
   };
   const RemoveFaq3=()=>{
    setRemovefaq3(true);
   };
   const RemoveFaq4=()=>{
    setRemovefaq4(true);
   };

   const AddFaq=()=>{
    setRemovefaq1(false);
    setRemovefaq2(false);
    setRemovefaq3(false);
    setRemovefaq4(false);
    setFaqQ1('');
    setFaqQ2('');
    setFaqQ3('');
    setFaqQ4('');
    setfaqA1('');
    setfaqA2('');
    setfaqA3('');
    setfaqA4('');



   }
   const AddnewFaq=()=>{
     setNewFaq(preValue=>[...preValue,newFaq])
     setNewFaq([...newFaq,{Question:'',Answer:""}])
   };

   const handleRemoveNewfaq = (indexToRemove) => {
    setNewFaq(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });

  };

  const handelNewFaqQuestion = ( index,value) => {
    const updatedMenu = [...newFaq];
    updatedMenu[index].Question=value;
    setFaq(updatedMenu);
  };

  const handelNewFaqAnswer = ( index,value) => {
    const updatedMenu = [...newFaq];
    updatedMenu[index].Answer=value;
    setFaq(updatedMenu);
  };

  console.log('newFaq',newFaq,newFaq.length,removeFaq1,removeFaq2,removeFaq3,removeFaq4)
  const HandelFaqCaption=(Caption)=>{
    setFaqCaption(Caption.target.value)
  };
  return (
    <div>
     

      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
                <div>
                    <MainNavBar />
                </div>
                <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
                    <div style={{ width: '400px' }} >
                    {mainnavbarbutton == false ?
          <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
            <ContentDesign headerText="FAQ" />
             {contentdesing==false?
            <div >
              <div className="editing_content__RXzxk">
                <div className="form_form__Sku4X" >
                  {/* <CaptionTitle /> */}
                  <div className="form_form__Sku4X">
                <label class="form_label__27psn">Header</label>
                <div className="form_group__kDXNb">
                    <div className="form_form__Sku4X">
                        <div class="form_more__6E9XQ" type="button" id="radix-:rer:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                        <div class="form_row__2+BJ3">
                            <div class="form_field__d31bq form_size--2x2__J8UQE"><div class="picker_preview__QSKw4"><svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 24 24" width="22" fill="#bbb"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z"></path></svg></div></div>
                            <div className="form_group__kDXNb">
                                <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >
                                      <textarea placeholder="Caption" style={{ height: "32px" }} value={faqCaption} onChange={(e)=>HandelFaqCaption(e)}></textarea></div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0"  ref={contentEditableRef9} onInput={ handalfaq9}>{faqtitle}</div></div></div>
                                    <div class="form_field__d31bq"><div class=""><div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef10} onInput={ handalfaq10}>{faqIntro}</div></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                  <label class="form_label__27psn">Items</label>


                

                  {removeFaq1==false?
                  <div className='form_listItem__puOqW'>
                    <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                    <div className='form_headerRemove__mJ7no' onClick={()=>RemoveFaq1()}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                    </div>
                    <div className="form_form__Sku4X">
                      <div className='d-flex ' >
                        <div className="form_group__kDXNb ">
                          <div className="form_form__Sku4X">
                            <div class="form_field__d31bq">
                              <div class="">
                                <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef1} onInput={handelfaq1}>{faqQ1}</div>
                              </div>
                            </div>
                            <div class="form_field__d31bq"><div class="">
                              <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef2} onInput={handelfaq2}>{faqA1}</div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:null}

                  {removeFaq2==false?
                  <div className='form_listItem__puOqW'>
                    <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                    <div className='form_headerRemove__mJ7no' onClick={()=>RemoveFaq2()}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                    </div>
                    <div className="form_form__Sku4X">
                      <div className='d-flex ' >
                        <div className="form_group__kDXNb ">
                          <div className="form_form__Sku4X">
                            <div class="form_field__d31bq">
                              <div class="">
                                <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef3} onInput={handelfaq3}>{faqQ2}</div>
                              </div>
                            </div>
                            <div class="form_field__d31bq"><div class="">
                              <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef4} onInput={handalfaq4}>{faqA2}</div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:null}

                   {removeFaq3==false?
                  <div className='form_listItem__puOqW'>
                    <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                    <div className='form_headerRemove__mJ7no' onClick={()=>RemoveFaq3()}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                    </div>
                    <div className="form_form__Sku4X">
                      <div className='d-flex ' >
                        <div className="form_group__kDXNb ">
                          <div className="form_form__Sku4X">
                            <div class="form_field__d31bq">
                              <div class="">
                                <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef5} onInput={handalfaq5}>{faqQ3}</div>
                              </div>
                            </div>
                            <div class="form_field__d31bq"><div class="">
                              <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef6} onInput={handalfaq6}>{faqA3}</div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:null}

                   {removeFaq4==false?
                  <div className='form_listItem__puOqW'>
                    <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                    <div className='form_headerRemove__mJ7no' onClick={()=>RemoveFaq4()}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                    </div>
                    <div className="form_form__Sku4X">
                      <div className='d-flex ' >
                        <div className="form_group__kDXNb ">
                          <div className="form_form__Sku4X">
                            <div class="form_field__d31bq">
                              <div class="">
                                <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" ref={contentEditableRef7} onInput={handalfaq7}>{faqQ4}</div>
                              </div>
                            </div>
                            <div class="form_field__d31bq"><div class="">
                              <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0"  ref={contentEditableRef8} onInput={handalfaq8}>{faqA4}</div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>:null}

                  {newFaq.map((item,index)=>(
                          <div className='form_listItem__puOqW' key={index}>
                          <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                          <div className='form_headerRemove__mJ7no' onClick={()=> handleRemoveNewfaq(index)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                          </div>
                          <div className="form_form__Sku4X">
                            <div className='d-flex ' >
                              <div className="form_group__kDXNb ">
                                <div className="form_form__Sku4X">
                                  <div class="form_field__d31bq">
                                    <div class="">
                                      {/* <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" > */}
                                         <textarea contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" style={{resize:'none',overflow:'hidden',width:"100%",height:30}} value={item.Question} onChange={(e)=>handelNewFaqQuestion(index,e.target.value)}></textarea>
                                      {/* </div> */}
                                    </div>
                                  </div>
                                  <div class="form_field__d31bq"><div class="">
                                    {/* <div contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" > */}
                                    <textarea contenteditable="true" translate="no" class="tiptap ProseMirror form_value__oIwpW form_tiptap__s0JN1" tabindex="0" style={{resize:'none',overflow:'hidden',width:"100%"}} value={item.Answer} onChange={(e)=>handelNewFaqAnswer(index,e.target.value)}></textarea>
                                     
                                    {/* </div> */}
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
      
                          </div>
                        </div>
                  ))}
                    {(((removeFaq1==true&&removeFaq2==true)&&(removeFaq3==true&&removeFaq4==true))&&(newFaq.length==0))?
                  <div className='form_emptyList__KNV3N'>
                    <div>Your list is empty</div>
                    <button class="button1 button--small" onClick={AddnewFaq}>Add Item</button>
                  </div> : <button class="button1 button--small " style={{ alignSelf: 'flex-end' }} onClick={()=> AddnewFaq()}>Add Item</button>}

                </div>

              </div>
            </div>:<FaqDesing/>}

          </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme/></div>}
                        
                    </div>
                    <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
                       <WebPages/>
                    </div>
                </div>
            </div>


    </div>
  )
};

export default FaqEdit