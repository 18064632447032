import React, { useContext, useEffect, useState } from "react";
import vector from '../assets/images/Vector.svg'
import { getCurrentChainIdStableCoinAddress, transferBasicPayment, transferGoldPayment, transferPlatinumPayment } from '../ContractAction/PaymentContractAction'
import { detectCurrentProvider, AdminPaymentAddress, BasicTokenPayAmount, GoldTokenPayAmount, PlatinumTokenPayAmount } from "../ContractAction/ContractDependency.js";
import landingbackground from '../assets/images/backgroundpng.png'
import MyContext from "../context/MyContext";
import { useChainId, useWriteContract, useWaitForTransactionReceipt } from "wagmi";
import { abiUSD } from "../ContractAction/ABI/USD.js";
import { useAccount } from 'wagmi'
import { use } from "i18next";









const SubscriptionsMeme = () => {
    const { randomId, subscriptionStatus, uniqueID } = useContext(MyContext);
    // console.log('subscriptionStatus', randomId)

    const [isBasicLoading, setIsBasicLoading] = useState(false);
    const [isGoldLoading, setIsGoldLoading] = useState(false);
    const [isPlatinumLoading, setIsPlatinumLoading] = useState(false);

let { status, writeContract, data: hash, writeContractAsync, isPending, isLoading, isFetching  } = useWriteContract();
let { waitForTransactionReceipt } = useWaitForTransactionReceipt();
    const chainId = useChainId();
    const { address, isConnected } = useAccount()

    const savesubscription = (account,plantitle,transactionhash,rid) => {
        //console.log("contentdesigndd",randomId); 
            fetch('https://trendifyweb.ai/pyapi/usersubscriptions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  address: account,
                  plan: plantitle,
                  hash: transactionhash,
                  randomid: rid
               })
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to save and edit');
                }
                // Redirect or handle success as needed
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle error
            });
        }; 

    const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
      confirmations: 5,
    })
    console.log("isConfirming", isConfirming, isConfirmed, hash);

    const handleTransfer = async (setLoadingState, planAmount) => {
        console.log("isSuccessStart", isConfirmed);
        setLoadingState(true) // Start loading
        let usdAddress = await getCurrentChainIdStableCoinAddress(chainId);
        console.log("wagmiusdAddress", usdAddress);
        try {
          const hash = await writeContractAsync({
            abi: abiUSD,
            address: usdAddress,
            functionName: "transfer",
            args: [AdminPaymentAddress, planAmount *  10 ** 6],
        });
        console.log("wagmi_response", hash);
        console.log("wagmi_status", status);
        console.log("isSuccess", isConfirmed);
        } catch (error) {
            if (error.name === "ContractFunctionExecutionError") {
                alert("BEP20: transfer amount exceeds balance.")
            }
            else if(error.name === "TransactionExecutionError") {
                alert("User rejected the request.")
            }
          console.error("Transfer failed:", error);
          setLoadingState(false); // Stop loading
        }         
      };

      useEffect(() => {
          if (isConfirmed && isBasicLoading) {
            console.log("useEffectcalled");
            setIsBasicLoading(false); // Stop 
            console.log("DATABASE", address,"Basic",hash,uniqueID);
            savesubscription(address,"basic",hash,uniqueID);
            window.history.back();
          }
          else if (isConfirmed && isGoldLoading) {
            console.log("useEffectcalled");
            setIsGoldLoading(false); // Stop loading
            console.log("DATABASE", address,"Gold",hash,uniqueID);
            savesubscription(address,"gold",hash,uniqueID);
            window.history.back();
          }
          else if (isConfirmed && isPlatinumLoading) {
            console.log("useEffectcalled");
            setIsPlatinumLoading(false); // Stop loading
            console.log("DATABASE", address,"Platinum",hash,uniqueID);
            savesubscription(address,"platinum",hash,uniqueID);
            window.history.back();
          }
      }, [isConfirmed])

    const handleBuyNowClick = async (paymentFunction, setLoadingState, randomId) => {
        setLoadingState(true); // Start loading
        try {
            const result = await paymentFunction(randomId);
            // Handle the result if needed
        } catch (error) {
            console.error("Transaction failed:", error);
        } finally {
            setLoadingState(false); // Stop loading
        }
    };

    return (
        <>
            <section className='_cc2950fb ' id='Pricing' style={{
                backgroundImage: `url(${landingbackground})`,
                width: '100%',
                position: 'relative',
                maxWidth: '100%',
                overflow: 'hidden',
                height: '100%',
                objectFit: 'cover',
                minHeight: '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <div className='container' >
                    <h1 className='core-features'>How much does it cost?</h1>
                    <p className='subscription-plan'>Subscription Plan</p>
                </div>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-4 col-md-6'>
                            <div className='cardedit p-3 d-flex flex-column justify-content-between ' >
                                <div>
                                    <div className='platinum'>Basic</div>
                                    <div className="usdt-parent">
                                        <div className="usdt">12 USDC</div>
                                        <div className="yr">/website</div>
                                    </div>
                                    <div className='d-flex flex-column' style={{ gap: '16px 0px' }}>
                                        {/* <div style={{ textAlign: 'left' }}>
                                                <img src={maticlogo} style={{ height: '32px', width: 'auto' }} alt='' />
                                            </div> */}
                                        <br />
                                        <div className='d-flex flex-column mb-4 h-100' style={{ gap: '12px 0px' }}>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Create a Website at no cost.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Download HTML Code.</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {isBasicLoading  ? (
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                        <div>Loading...</div>
                                    </div>
                                ) : (
                                    <button disabled={isGoldLoading || isPlatinumLoading} className='buy-now-button' onClick={() => handleTransfer(setIsBasicLoading, BasicTokenPayAmount)}>
                                        <span className="buy-now">Buy Now</span>
                                    </button>
                                )}

                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div className='cardedit p-3 d-flex flex-column justify-content-between ' >
                                <div>
                                    <div className='platinum'>Gold</div>
                                    <div className="usdt-parent">
                                        <div className="usdt">120 USDC</div>
                                        <div className="yr">/6mo</div>
                                    </div>
                                    <div className='d-flex flex-column' style={{ gap: '16px 0px' }}>
                                        <div style={{ textAlign: 'left' }}>
                                            {/* <img src={maticlogo} style={{ height: '32px', width: 'auto' }} alt='' /> */}
                                        </div>
                                        <br />
                                        <div className='d-flex flex-column mb-4 h-100' style={{ gap: '12px 0px' }}>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Create a Website at no cost.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Host with your custom domain.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Host with Trendifyweb.ai subdomain.</div>
                                            </div>
                                            {/* <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Make unlimited changes and re-host as needed.</div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                                {isGoldLoading  ? (
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                        <div>Loading...</div>
                                    </div>
                                ) : (
                                    <button disabled={isBasicLoading || isPlatinumLoading} className='buy-now-button' onClick={() => handleTransfer(setIsGoldLoading, GoldTokenPayAmount)}>
                                        <span className="buy-now">Buy Now</span>
                                    </button>
                                )}

                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='cardedit p-3 d-flex flex-column justify-content-between ' >
                                <div>
                                    <div className='platinum'>Platinum</div>
                                    <div className="usdt-parent">
                                        <div className="usdt">215 USDC</div>
                                        <div className="yr">/yr</div>
                                    </div>
                                    <div className='d-flex flex-column' style={{ gap: '16px 0px' }}>
                                        {/* <div style={{ textAlign: 'left' }}>
                                                <img src={maticlogo} style={{ height: '32px', width: 'auto' }} alt='' />
                                            </div> */}
                                        <br />
                                        <div className='d-flex flex-column mb-4 h-100' style={{ gap: '12px 0px' }}>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Create a Website at no cost.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Host with your custom domain.</div>
                                            </div>
                                            <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Host with Trendifyweb.ai subdomain.</div>
                                            </div>
                                            {/* <div className="vector-parent">
                                                <img className="vector-icon" alt="" src={vector} />
                                                <div className="create-a-website">Make unlimited changes and re-host as often as you like.</div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                                {isPlatinumLoading  ? (
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                        <div>Loading...</div>
                                    </div>
                                ) : (
                                    <button disabled={isBasicLoading || isGoldLoading} className='buy-now-button' onClick={() => handleTransfer(setIsPlatinumLoading, PlatinumTokenPayAmount)}>
                                        <span className="buy-now">Buy Now</span>
                                    </button>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default SubscriptionsMeme;